import {DefaultService, Login} from "app/lib/api";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export class AccountCloud {
  constructor(private apiClient: DefaultService) {

  }

  password(old_password: string, new_password: string) {
    return this.apiClient.accountEndpointV1Password(old_password, new_password)
  }

  get(): Observable<Login> {
    return this.apiClient.accountEndpointV1Get()
  }

  login(username, password) {
    return this.apiClient.accountEndpointV1Login(username, password)
  }

  register(login) {
    return this.apiClient.accountEndpointV1Register(login, 5956907458101248)
  }

  save(login) {
    return this.apiClient.accountEndpointV1Save(login, 5956907458101248)
  }

  recovery(username) {
    return this.apiClient.accountEndpointV1Recovery(username)
  }

  saveProfile(profile) {
    return this.apiClient.accountEndpointV1Profile(profile)
  }

  loadProfile(profileId) {
    return this.apiClient.profileEndpointV1Get(profileId)
  }
}
