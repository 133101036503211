import {Component, OnInit} from '@angular/core';
import {MatDialog, MatSnackBar} from "@angular/material";
import {AppService} from "app/infrastructure/services/app.service";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {DialogBlockKnowMoreComponent} from "./dialog-block-know-more/dialog-block-know-more.component";
import {Device} from "app/lib/api";
import {YesNoDialogComponent} from "app/view/custom/dialog/yes-no-dialog/yes-no-dialog.component";
import {CommandApi} from "app/data/source/cloud/command-api";

@Component({
  selector: 'app-block-command',
  templateUrl: './block-command.component.html',
  styleUrls: ['./block-command.component.scss']
})
export class BlockCommandComponent implements OnInit {
  loading: boolean = false;
  device: Device;
  private;
  private error: boolean = false;

  constructor(private appService: AppService, private dialog: MatDialog, private commandApi: CommandApi, private snackBar: MatSnackBar) {
    this.appService.setNavSubtitle("Bloqueio e Desbloqueio")
  }

  ngOnInit() {

  }

  checkDevice(): boolean {
    if (this.device == null) {
      this.error = true;
      return false;
    }
    return true;
  }

  lock() {
    if (!this.checkDevice()) {
      return;
    }

    YesNoDialogComponent
      .create(this.dialog, "Bloqueio", `Deseja enviar o comando para bloquear ${this.device.name}?`)
      .subscribe(value => {
        if (value == true) {
          this.lockConfirm()
        }
      })
  }

  lockConfirm() {
    this.loading = true;
    this.commandApi.block(this.device.id)
      .then(value => {
        this.loading = false;
        this.commandSuccess();
      }, reason => {
        this.loading = false;
        new ErrorManager(reason, this.dialog);
      })
  }

  unlock() {
    if (!this.checkDevice()) {
      return;
    }

    YesNoDialogComponent
      .create(this.dialog, "Desbloqueio", `Deseja enviar o comando para desbloquear ${this.device.name}?`)
      .subscribe(value => {
        if (value == true) {
          this.unlockConfirm()
        }
      })
  }

  selectDevice(device: Device) {
    this.device = device;

    this.error = false;
  }

  knowMore() {
    this.dialog.open(DialogBlockKnowMoreComponent)
  }

  unlockConfirm() {
    this.loading = true;

    this.commandApi.unblock(this.device.id)
      .then(value => {
        this.loading = false;
        this.commandSuccess();
      }, reason => {
        this.loading = false;
        new ErrorManager(reason, this.dialog);
      })
  }

  commandSuccess() {
    this.snackBar.open("Comando enviado com sucesso", null, {
      duration: 5000, horizontalPosition: "end"
    })
  }
}
