/**
 * apureh-web.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Permission { 
    assigned?: Array<Permission.AssignedEnum>;
    available?: Array<Permission.AvailableEnum>;
    id?: number;
    loginId?: number;
}
export namespace Permission {
    export type AssignedEnum = 'CONTRACT_READ' | 'CONTRACT_WRITE' | 'DEVICE_WRITE' | 'DEVICE_READ' | 'USER_PERMISSION' | 'USER_READ' | 'USER_TOKEN' | 'USER_WRITE' | 'PAYMENT_READ' | 'PAYMENT_WRITE' | 'SETTINGS_READ' | 'SETTINGS_WRITE';
    export const AssignedEnum = {
        CONTRACTREAD: 'CONTRACT_READ' as AssignedEnum,
        CONTRACTWRITE: 'CONTRACT_WRITE' as AssignedEnum,
        DEVICEWRITE: 'DEVICE_WRITE' as AssignedEnum,
        DEVICEREAD: 'DEVICE_READ' as AssignedEnum,
        USERPERMISSION: 'USER_PERMISSION' as AssignedEnum,
        USERREAD: 'USER_READ' as AssignedEnum,
        USERTOKEN: 'USER_TOKEN' as AssignedEnum,
        USERWRITE: 'USER_WRITE' as AssignedEnum,
        PAYMENTREAD: 'PAYMENT_READ' as AssignedEnum,
        PAYMENTWRITE: 'PAYMENT_WRITE' as AssignedEnum,
        SETTINGSREAD: 'SETTINGS_READ' as AssignedEnum,
        SETTINGSWRITE: 'SETTINGS_WRITE' as AssignedEnum
    };
    export type AvailableEnum = 'CONTRACT_READ' | 'CONTRACT_WRITE' | 'DEVICE_WRITE' | 'DEVICE_READ' | 'USER_PERMISSION' | 'USER_READ' | 'USER_TOKEN' | 'USER_WRITE' | 'PAYMENT_READ' | 'PAYMENT_WRITE' | 'SETTINGS_READ' | 'SETTINGS_WRITE';
    export const AvailableEnum = {
        CONTRACTREAD: 'CONTRACT_READ' as AvailableEnum,
        CONTRACTWRITE: 'CONTRACT_WRITE' as AvailableEnum,
        DEVICEWRITE: 'DEVICE_WRITE' as AvailableEnum,
        DEVICEREAD: 'DEVICE_READ' as AvailableEnum,
        USERPERMISSION: 'USER_PERMISSION' as AvailableEnum,
        USERREAD: 'USER_READ' as AvailableEnum,
        USERTOKEN: 'USER_TOKEN' as AvailableEnum,
        USERWRITE: 'USER_WRITE' as AvailableEnum,
        PAYMENTREAD: 'PAYMENT_READ' as AvailableEnum,
        PAYMENTWRITE: 'PAYMENT_WRITE' as AvailableEnum,
        SETTINGSREAD: 'SETTINGS_READ' as AvailableEnum,
        SETTINGSWRITE: 'SETTINGS_WRITE' as AvailableEnum
    };
}
