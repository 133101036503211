import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {Router} from "@angular/router";

@Component({
  selector: 'app-dialog-block-know-more',
  templateUrl: './dialog-block-know-more.component.html',
  styleUrls: ['./dialog-block-know-more.component.scss']
})
export class DialogBlockKnowMoreComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogBlockKnowMoreComponent>,
    private route: Router) {
  }

  ngOnInit() {
  }

  help() {
    this.close();
    this.route.navigateByUrl("/help")
  }

  close() {
    this.dialogRef.close();
  }
}
