import {Injectable} from "@angular/core";
import {BrandCloud} from "app/data/source/cloud/brand-cloud";
import {LocalData} from "app/data/source/local/local-data";
import {Observable} from "rxjs";
import {Brand} from "app/lib/api";

@Injectable()
export class BrandRepository {
  static BRAND_KEY = "brand";

  constructor(
    private brandCloud: BrandCloud,
    private localData: LocalData
  ) {
  }

  list(cache = true) {
    return new Observable<Brand[]>(subscriber => {
        if (cache) {
          let brands = this.localData.load(BrandRepository.BRAND_KEY);

          if (brands != undefined) {
            subscriber.next(brands);
            subscriber.complete();
            return
          }
        }

        this.brandCloud.list()
          .subscribe(
            value => {
              this.localData.save(BrandRepository.BRAND_KEY, value.items);
              subscriber.next(value.items);
              subscriber.complete();
            },
            error => subscriber.error(error)
          )
      }
    )
  }

  load(paramId) {
    return this.brandCloud.load(paramId)
  }

  save(brand) {
    return this.brandCloud.save(brand)
  }
}
