import {Component, OnInit} from '@angular/core';
import {DeviceIcon} from "app/lib/api";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {MatDialog, MatSnackBar} from "@angular/material";
import {FipeService} from "app/view/custom/fipe/fipe.service";
import {FipeTipos} from "app/data/data/fipe/fipe";
import {DeviceApi} from "app/data/source/cloud/device-api";

@Component({
  selector: 'app-icon-list', templateUrl: './icon-list.component.html', styleUrls: ['./icon-list.component.scss']
})
export class IconListComponent implements OnInit {
  icons: Array<DeviceIcon>;
  loading: boolean;
  keys = IconListComponent.initializeKeys();

  constructor(private deviceApi: DeviceApi,
              private dialog: MatDialog,
              private snack: MatSnackBar,
              private fipe: FipeService) {
  }

  static initializeKeys(): string[] {
    let types: string[] = Object.keys(FipeTipos).filter(k => typeof FipeTipos[k as any] === "string");
    types.push("Máquinas", "Drones", "Portátil", "Marítmo", "Carga");
    return types;
  }

  ngOnInit() {
    this.loadDevices();
  }

  filterList(type: string) {
    return this.icons.filter(value => {
      return value.type == type
    })
  }

  filterManufacturer(group: String): Set<string> {
    let manufacturer: Set<string> = new Set();

    this.icons
      .filter(value => value.type == group)
      .forEach(value => manufacturer.add(value.manufacturer));

    return manufacturer;
  }

  loadDevices() {
    this.loading = true;

    this.deviceApi.listDeviceIcons(1000, null)
      .then(value => {
        this.loading = false;
        this.icons = value.items;

        this.sortDevices(this.icons);

      }, reason => {
        new ErrorManager(reason, this.dialog);
      })
  }

  sortDevices(devices: Array<DeviceIcon>): Array<DeviceIcon> {
    devices.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    return devices;
  }

  filterGroups(): Set<string> {
    let iconsGroup: Set<string> = new Set();

    this.icons.sort((a, b) => {
      if (a.type < b.type) return -1;
      if (a.type > b.type) return 1;
      return 0;
    });

    this.icons.forEach(value1 => {
      iconsGroup.add(value1.type);
    });

    return iconsGroup
  }

  filterItems(manufacturer: string, type: string) {
    return this.sortDevices(this.icons.filter(value => {
      return value.type == type && value.manufacturer == manufacturer
    }))
  }

  save(item: DeviceIcon) {
    this.deviceApi.updateDeviceIcon(item)
      .subscribe(
        value => {
          this.snack.open(`${item.name} salvo`, "", {
            horizontalPosition: "end", verticalPosition: "bottom", duration: 1000
          })
        })
  }
}
