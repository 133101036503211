import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Contract} from "app/lib/api";
import {DashCloud} from "../cloud/dash-cloud";

@Injectable()
export class DashRepository {
  constructor(private dashCloud: DashCloud) {
  }

  contractUpgrade(contractId: number,
                  plainId?: number,
                  m2mId?: number,
                  deviceId?: number,
                  discountExpireAt?: Date,
                  discountAmount?) {
    return new Observable<Contract>(subscriber => {
      this.dashCloud.contractUpgrade(contractId, plainId, m2mId, deviceId, discountExpireAt, discountAmount)
        .subscribe(value => {
          subscriber.next(value);
          subscriber.complete();
        }, error => subscriber.error(error))
    });
  }

  contractCancel(contractId: number) {
    return new Observable<Contract>(subscriber => {
      this.dashCloud.contractCancel(contractId)
        .subscribe(value => {
          subscriber.next(value);
          subscriber.complete();
        }, error => subscriber.error(error))
    })
  }

  chipCancel(chipId: number) {
    return new Observable<Contract>(subscriber => {
      this.dashCloud.chipCancel(chipId)
        .subscribe(value => {
          subscriber.next(value);
          subscriber.complete();
        }, error => subscriber.error(error))
    })
  }
}
