import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";
import {Subscription} from "rxjs";
import {Device} from "app/lib/api";
import {DeviceRepository} from "app/data/source/repository/device-repository";

@Component({
  selector: 'device-autocomplete',
  templateUrl: './device-autocomplete.component.html',
  styleUrls: ['./device-autocomplete.component.scss']
})
export class DeviceAutocompleteComponent implements OnInit, OnDestroy {
  devices: Device[] = [] as Device[];
  devicesFiltered: Device[] = [] as Device[];

  @Output() device: EventEmitter<Device> = new EventEmitter<Device>();

  @Input() showIcon: boolean = false;

  _device: Device;
  deviceName: string;
  private _subscription: Subscription;

  constructor(private appService: AppService, private deviceRepository: DeviceRepository) {
    this._subscription = this.deviceRepository.list()
      .subscribe(devices => {
        this.devices = devices;
        this.devicesFiltered = this.devices;

        let lastDeviceSelected = appService.lastDeviceSelected;

        if (lastDeviceSelected == undefined || lastDeviceSelected == 0) {
          this.selectDevice = this.devices[0];
        } else {
          this.devices.forEach(value2 => {
            if (value2.id == lastDeviceSelected) {
              this.selectDevice = value2;
            }
          })
        }
      });
  }

  get selectDevice() {
    return this._device;
  }

  set selectDevice(device) {
    if (device != null) {
      this._device = device;
      this.appService.lastDeviceSelected = device.id;
      this.device.emit(device)
    }
  }

  clearDeviceSelected() {
    this.deviceName = "";
    this.filterChoices(this.deviceName)
  }

  ngOnDestroy(): void {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.devices.forEach(value => {
      if (value.id == this.appService.lastDeviceSelected) {
        this.selectDevice = value;
        this.deviceName = this.selectDevice.name;
      }
    })
  }

  filterChoices(deviceName: string) {
    this.devicesFiltered = this.devices.filter(value => {
      return value.name.toLowerCase().indexOf(deviceName.toLowerCase()) >= 0;
    });
  }

  changeDevice(device: Device) {
    this.devices.forEach(value => {
      if (device.id == value.id) {
        this.selectDevice = value;
        this.deviceName = value.name;
      }
    });

    this.filterChoices(this.deviceName);
  }
}
