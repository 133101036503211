import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AppService} from "app/infrastructure/services/app.service";
import {Observable} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {Injectable} from "@angular/core";
import {DefaultService} from "app/lib/api";
import {UploadImage} from "app/domain/model/upload-image";

@Injectable()
export class KodakRepository {
  url = this.appService.baseUrl + "/kodak";

  constructor(private http: HttpClient,
              private defaultService: DefaultService,
              private appService: AppService,
              private dialog: MatDialog) {
  }

  deleteImage(id) {
    let httpParams = new HttpParams();
    httpParams.append("image_id", id);

    return this.http.delete(this.url, {
      params: httpParams
    })
  }

  uploadImage(file) {
    return new Observable<UploadImage>(
      subscriber => {
        let files: File[] = file.target.files;

        let formData: FormData = new FormData();
        formData.append('file', files[0], files[0].name);

        this.defaultService.kodakEndpointV1Upload()
          .subscribe(
            urlToUpload => this.http.post(urlToUpload.value, formData)
              .subscribe(
                (url: UploadImage) => subscriber.next(url),
                reason => new ErrorManager(reason, this.dialog)
              ),
            reason => new ErrorManager(reason, this.dialog)
          );
      }
    );
  }
}
