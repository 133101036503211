import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {KodakRepository} from "app/data/source/repository/kodak-repository";
import {CategoryRepository} from "app/data/source/repository/category-repository";
import {Category} from "app/lib/api";

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss']
})
export class CategoryFormComponent implements OnInit {
  category: Category = {};
  loading = true;

  constructor(
    private categoryRepository: CategoryRepository,
    private kodakRepository: KodakRepository,
    private activate: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.activate.params.subscribe(
      value => {
        let paramId = value['id'];

        if (paramId != undefined) {
          this.categoryRepository.load(paramId)
            .subscribe(
              value1 => {
                this.category = value1;
                this.loading = false;
              }
            )
        } else {
          this.loading = false;
        }
      }
    )
  }

  save() {
    this.loading = true;

    this.categoryRepository.save(this.category)
      .subscribe(
        value => this.loading = false
      )
  }
}
