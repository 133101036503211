import {Component, OnInit} from '@angular/core';
import {Invoice} from "app/lib/api";
import {AppService} from "app/infrastructure/services/app.service";
import {InvoiceRepository} from "app/data/source/repository/invoice-repository";

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  loading = true;
  invoices: Array<Invoice>;

  constructor(private appService: AppService, private invoiceRepository: InvoiceRepository) {
    appService.setNavSubtitle("Histórico")
  }

  ngOnInit() {
    this.loadInvoice()
  }

  private loadInvoice() {
    this.loading = true;
    this.invoiceRepository.list(false)
      .subscribe(invoices => {
        this.loading = false;

        if (invoices != null) {
          this.invoices = invoices;
        }
      })
  }
}
