import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {AppService} from "app/infrastructure/services/app.service";
import {Device} from "app/lib/api";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {MatDialog} from "@angular/material";
import {DeviceRepository} from "app/data/source/repository/device-repository";

@Component({
  selector: 'app-devices', templateUrl: './device-list.component.html', styleUrls: ['./device-list.component.scss']
})
export class DeviceListComponent implements OnInit, OnDestroy {
  loading = true;
  devices: Device[];
  subscriptionDevices: Subscription;

  constructor(
    private appService: AppService,
    private dialog: MatDialog,
    private deviceRepository: DeviceRepository
  ) {
    this.appService.setNavSubtitle("Lista")
  }

  ngOnDestroy(): void {
    if (this.subscriptionDevices != undefined) {
      this.subscriptionDevices.unsubscribe();
    }
  }

  ngOnInit() {
    this.subscriptionDevices =
      this.deviceRepository.list(false)
        .subscribe(value => {
            this.devices = value;

            if (this.devices != null) {
              this.loading = false;
            }
          },
          reason => {
            new ErrorManager(reason, this.dialog)
          });
  }
}
