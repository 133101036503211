import {DefaultService} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class PaymentCloud {
  constructor(private apiClient: DefaultService) {

  }

  list(limit: number, deviceId: number, cursor: null) {
    return this.apiClient.paymentEndpointV1List(limit, deviceId, cursor)
  }

  create(deviceId: number, plainId: number) {
    return this.apiClient.paymentEndpointV1Create(null, deviceId, plainId).toPromise()
  }

  createInvoice(id) {
    return this.apiClient.paymentEndpointV1CreateInvoice(id)
  }
}
