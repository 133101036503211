import {Component, OnInit} from '@angular/core';
import {Plain, Signature} from "app/lib/api";
import {PlainRepository} from "app/data/source/repository/plain-repository";
import {SignatureRepository} from "app/data/source/repository/signature-repository.service";

@Component({
  selector: 'app-plain-list', templateUrl: './plain-list.component.html', styleUrls: ['./plain-list.component.scss']
})
export class PlainListComponent implements OnInit {
  loading: boolean = true;
  plains: Plain[] = [];
  signatures: Signature[] = [];

  constructor(private plainRepository: PlainRepository, private signatureRepository: SignatureRepository) {
  }

  ngOnInit() {
    this.load()
  }

  private load(cache = true) {
    this.loading = true;
    this.plainRepository.list(null, 100, null, cache)
      .subscribe(value => {
        this.loading = false;
        return this.plains = value;
      })
    this.signatureRepository.list(100, null).subscribe(value => this.signatures = value)
  }

  getSignature(signatureId: number) {
    return this.signatures.find(value => value.id == signatureId)
  }
}
