import {Injectable} from "@angular/core";
import {AddressProvider, LatLng} from "app/view/custom/provider_address/address_provider";
import {AddressModelProvider} from "app/view/custom/provider_address/address_model_provider";
import {AppService} from "app/infrastructure/services/app.service";
import {HttpClient} from "@angular/common/http";
import {LocalData} from "app/data/source/local/local-data";
import RootObject = MaprequestResponse.RootObject;

@Injectable()
export class MapRequestAddressProvider extends AddressProvider {
  mapRequestKey: string;
  language: string;

  constructor(
    public appService: AppService,
    private http: HttpClient,
    localData: LocalData,
  ) {
    super(localData);
    this.mapRequestKey = this.appService.map_request_key;
    this.language = this.appService.language;
  }

  resolveAddress(lat: number, lng: number):Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let key = this.mapRequestKey;
      this.http.get(`https://www.mapquestapi.com/geocoding/v1/reverse?key=${key}&json&outFormat=json&location=${lat},${lng}&includeRoadMetadata=true&includeNearestIntersection=true`)
        .subscribe(
          value => {
            let rootObject = (value as RootObject);
            let addressModel: AddressModelProvider = new AddressModelProvider();

            if (rootObject.results.length > 0 && rootObject.results[0].locations.length > 0) {
              let location1 = rootObject.results[0].locations[0];
              addressModel.formatedAddress = `${location1.street} - ${location1.adminArea5} - ${location1.adminArea3} - ${location1.adminArea1}`
            } else {
              addressModel.formatedAddress = "Endereço desconhecido";
            }
            resolve(addressModel.formatedAddress)
          }, error => {
            reject(error);
            console.log(error);
          })
    } );
  }
}
