import {Component, Input, OnInit} from '@angular/core';
import {ContractRepository} from "app/data/source/repository/contract-repository";
import {ChipContract} from "app/domain/model/chip-contract";
import {Contract} from "app/lib/api";

@Component({
  selector: 'app-chip-item', templateUrl: './chip-item.component.html', styleUrls: ['./chip-item.component.scss']
})
export class ChipItemComponent implements OnInit {

  @Input("index") index: number;
  @Input("chipContract") chipContract: ChipContract;

  contract: Contract;

  constructor(private contractRepository: ContractRepository) {
  }

  ngOnInit() {
    this.initContract();
  }

  chipStatus() {
    if (this.chipContract.chip.canceled) {
      return "Chip cancelado";
    } else {
      return "Chip ativo";
    }
  }

  contractStatus() {
    if (this.chipContract.chip.contractId != null) {
      return "Contrato V2";
    } else if (this.chipContract.contract != null) {
      return "Contrato V1"
    } else if (this.chipContract.notFound == null) {
      return "Não utilizado";
    } else {
      return "Sem contrato"
    }
  }

  initContract() {
    if (this.chipContract.chip.contractId) {
      this.contractRepository.get(this.chipContract.chip.contractId).subscribe(value => {
        this.contract = value;
      })
    } else {
      this.contract = this.chipContract.contract
    }
  }

  alert() {

  }
}
