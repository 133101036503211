/**
 * apureh-web.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs/Observable';

import {AccountPayload} from '../model/accountPayload';
import {Alarm} from '../model/alarm';
import {AlarmPayload} from '../model/alarmPayload';
import {AlarmRequest} from '../model/alarmRequest';
import {AndroidMessage} from '../model/androidMessage';
import {Apn} from '../model/apn';
import {Brand} from '../model/brand';
import {Category} from '../model/category';
import {CategoryFeaturesPayloadCollection} from '../model/categoryFeaturesPayloadCollection';
import {ChipArray} from '../model/chipArray';
import {ChipM2M} from '../model/chipM2M';
import {ChipM2MFindPayload} from '../model/chipM2MFindPayload';
import {ChipM2MSave} from '../model/chipM2MSave';
import {CollectionResponseAlarm} from '../model/collectionResponseAlarm';
import {CollectionResponseAlarmPayload} from '../model/collectionResponseAlarmPayload';
import {CollectionResponseAndroidMessage} from '../model/collectionResponseAndroidMessage';
import {CollectionResponseApn} from '../model/collectionResponseApn';
import {CollectionResponseBrand} from '../model/collectionResponseBrand';
import {CollectionResponseCategory} from '../model/collectionResponseCategory';
import {CollectionResponseChipM2M} from '../model/collectionResponseChipM2M';
import {CollectionResponseCommand} from '../model/collectionResponseCommand';
import {CollectionResponseContract} from '../model/collectionResponseContract';
import {CollectionResponseDevice} from '../model/collectionResponseDevice';
import {CollectionResponseDeviceIcon} from '../model/collectionResponseDeviceIcon';
import {CollectionResponseEquipment} from '../model/collectionResponseEquipment';
import {CollectionResponseFeature} from '../model/collectionResponseFeature';
import {CollectionResponseGPS} from '../model/collectionResponseGPS';
import {CollectionResponseHost} from '../model/collectionResponseHost';
import {CollectionResponseInterfaceValues} from '../model/collectionResponseInterfaceValues';
import {CollectionResponseInvoice} from '../model/collectionResponseInvoice';
import {CollectionResponseLog} from '../model/collectionResponseLog';
import {CollectionResponseOverDueDevice} from '../model/collectionResponseOverDueDevice';
import {CollectionResponsePayment} from '../model/collectionResponsePayment';
import {CollectionResponsePlain} from '../model/collectionResponsePlain';
import {CollectionResponseProduct} from '../model/collectionResponseProduct';
import {CollectionResponseProfile} from '../model/collectionResponseProfile';
import {CollectionResponseProvider} from '../model/collectionResponseProvider';
import {CollectionResponseSMSDevice} from '../model/collectionResponseSMSDevice';
import {CollectionResponseSignature} from '../model/collectionResponseSignature';
import {CollectionResponseSignaturePlainsPayload} from '../model/collectionResponseSignaturePlainsPayload';
import {CommandStatusPayload} from '../model/commandStatusPayload';
import {Contract} from '../model/contract';
import {ContractDuplicatedPayloadCollection} from '../model/contractDuplicatedPayloadCollection';
import {ContractReviewPayload} from '../model/contractReviewPayload';
import {Device} from '../model/device';
import {DeviceIcon} from '../model/deviceIcon';
import {DistancePayload} from '../model/distancePayload';
import {Equipment} from '../model/equipment';
import {Feature} from '../model/feature';
import {FeatureCategoryHostsPayload} from '../model/featureCategoryHostsPayload';
import {FeaturePlainsChipM2M} from '../model/featurePlainsChipM2M';
import {FeaturePlainsDevice} from '../model/featurePlainsDevice';
import {GPS} from '../model/gPS';
import {GPSPayloadCollection} from '../model/gPSPayloadCollection';
import {HomePayload} from '../model/homePayload';
import {Host} from '../model/host';
import {HostCollection} from '../model/hostCollection';
import {IMEIStatus} from '../model/iMEIStatus';
import {Invoice} from '../model/invoice';
import {InvoiceDetailsPayload} from '../model/invoiceDetailsPayload';
import {Login} from '../model/login';
import {Manager} from '../model/manager';
import {NotifyTest} from '../model/notifyTest';
import {Payment} from '../model/payment';
import {Permission} from '../model/permission';
import {Plain} from '../model/plain';
import {Product} from '../model/product';
import {Profile} from '../model/profile';
import {ProfilePayload} from '../model/profilePayload';
import {Provider} from '../model/provider';
import {RegisterDeviceRequest} from '../model/registerDeviceRequest';
import {SMSDevice} from '../model/sMSDevice';
import {SMSMessageStatusPayloadCollection} from '../model/sMSMessageStatusPayloadCollection';
import {SMSSettingsRequest} from '../model/sMSSettingsRequest';
import {ScreenValidation} from '../model/screenValidation';
import {Signature} from '../model/signature';
import {StateContract} from '../model/stateContract';
import {Status} from '../model/status';
import {StringPayload} from '../model/stringPayload';
import {TimeZonePayload} from '../model/timeZonePayload';
import {TokenPayload} from '../model/tokenPayload';
import {UserStatus} from '../model/userStatus';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = 'https://apureh-web.appspot.com/_ah/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     *
     * @param body
     * @param managerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEndpointV1Create(body?: Login, managerId?: number, observe?: 'body', reportProgress?: boolean): Observable<TokenPayload>;
    public accountEndpointV1Create(body?: Login, managerId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TokenPayload>>;
    public accountEndpointV1Create(body?: Login, managerId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TokenPayload>>;
    public accountEndpointV1Create(body?: Login, managerId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (managerId !== undefined && managerId !== null) {
            queryParameters = queryParameters.set('manager_id', <any>managerId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TokenPayload>(`${this.basePath}/accountEndpoint/v1/create`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEndpointV1Get(observe?: 'body', reportProgress?: boolean): Observable<AccountPayload>;
    public accountEndpointV1Get(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountPayload>>;
    public accountEndpointV1Get(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountPayload>>;
    public accountEndpointV1Get(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AccountPayload>(`${this.basePath}/accountEndpoint/v1/get`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEndpointV1GetManager(observe?: 'body', reportProgress?: boolean): Observable<Manager>;
    public accountEndpointV1GetManager(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Manager>>;
    public accountEndpointV1GetManager(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Manager>>;
    public accountEndpointV1GetManager(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Manager>(`${this.basePath}/accountEndpoint/v1/manager`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEndpointV1GetToken(observe?: 'body', reportProgress?: boolean): Observable<TokenPayload>;
    public accountEndpointV1GetToken(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TokenPayload>>;
    public accountEndpointV1GetToken(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TokenPayload>>;
    public accountEndpointV1GetToken(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TokenPayload>(`${this.basePath}/accountEndpoint/v1/getToken`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEndpointV1LoadProfile(observe?: 'body', reportProgress?: boolean): Observable<Profile>;
    public accountEndpointV1LoadProfile(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Profile>>;
    public accountEndpointV1LoadProfile(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Profile>>;
    public accountEndpointV1LoadProfile(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Profile>(`${this.basePath}/accountEndpoint/v1/loadProfile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param username
     * @param password
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEndpointV1Login(username: string, password: string, observe?: 'body', reportProgress?: boolean): Observable<TokenPayload>;
    public accountEndpointV1Login(username: string, password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TokenPayload>>;
    public accountEndpointV1Login(username: string, password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TokenPayload>>;
    public accountEndpointV1Login(username: string, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling accountEndpointV1Login.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling accountEndpointV1Login.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<TokenPayload>(`${this.basePath}/accountEndpoint/v1/login`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param oldPass
     * @param newPass
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEndpointV1Password(oldPass: string, newPass: string, observe?: 'body', reportProgress?: boolean): Observable<AccountPayload>;
    public accountEndpointV1Password(oldPass: string, newPass: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccountPayload>>;
    public accountEndpointV1Password(oldPass: string, newPass: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccountPayload>>;
    public accountEndpointV1Password(oldPass: string, newPass: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (oldPass === null || oldPass === undefined) {
            throw new Error('Required parameter oldPass was null or undefined when calling accountEndpointV1Password.');
        }

        if (newPass === null || newPass === undefined) {
            throw new Error('Required parameter newPass was null or undefined when calling accountEndpointV1Password.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (oldPass !== undefined && oldPass !== null) {
            queryParameters = queryParameters.set('old_pass', <any>oldPass);
        }
        if (newPass !== undefined && newPass !== null) {
            queryParameters = queryParameters.set('new_pass', <any>newPass);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<AccountPayload>(`${this.basePath}/accountEndpoint/v1/password`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEndpointV1Profile(body?: Profile, observe?: 'body', reportProgress?: boolean): Observable<Profile>;
    public accountEndpointV1Profile(body?: Profile, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Profile>>;
    public accountEndpointV1Profile(body?: Profile, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Profile>>;
    public accountEndpointV1Profile(body?: Profile, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Profile>(`${this.basePath}/accountEndpoint/v1/profile`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param username
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEndpointV1Recovery(username: string, observe?: 'body', reportProgress?: boolean): Observable<StringPayload>;
    public accountEndpointV1Recovery(username: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StringPayload>>;
    public accountEndpointV1Recovery(username: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StringPayload>>;
    public accountEndpointV1Recovery(username: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling accountEndpointV1Recovery.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<StringPayload>(`${this.basePath}/accountEndpoint/v1/recovery`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param managerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEndpointV1Register(body?: Login, managerId?: number, observe?: 'body', reportProgress?: boolean): Observable<Login>;
    public accountEndpointV1Register(body?: Login, managerId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Login>>;
    public accountEndpointV1Register(body?: Login, managerId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Login>>;
    public accountEndpointV1Register(body?: Login, managerId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (managerId !== undefined && managerId !== null) {
            queryParameters = queryParameters.set('manager_id', <any>managerId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Login>(`${this.basePath}/accountEndpoint/v1/register`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param managerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accountEndpointV1Save(body?: Login, managerId?: number, observe?: 'body', reportProgress?: boolean): Observable<Login>;
    public accountEndpointV1Save(body?: Login, managerId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Login>>;
    public accountEndpointV1Save(body?: Login, managerId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Login>>;
    public accountEndpointV1Save(body?: Login, managerId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (managerId !== undefined && managerId !== null) {
            queryParameters = queryParameters.set('manager_id', <any>managerId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Login>(`${this.basePath}/accountEndpoint/v1/save`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Alarm>;
    public alarmEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Alarm>>;
    public alarmEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Alarm>>;
    public alarmEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling alarmEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Alarm>(`${this.basePath}/alarmEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1GetType(deviceId: number, type: number, observe?: 'body', reportProgress?: boolean): Observable<Alarm>;
    public alarmEndpointV1GetType(deviceId: number, type: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Alarm>>;
    public alarmEndpointV1GetType(deviceId: number, type: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Alarm>>;
    public alarmEndpointV1GetType(deviceId: number, type: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling alarmEndpointV1GetType.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling alarmEndpointV1GetType.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Alarm>(`${this.basePath}/alarmEndpoint/v1/getType`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param deviceId
     * @param type
     * @param enable
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1List(limit: number, deviceId?: number, type?: number, enable?: boolean, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseAlarm>;
    public alarmEndpointV1List(limit: number, deviceId?: number, type?: number, enable?: boolean, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseAlarm>>;
    public alarmEndpointV1List(limit: number, deviceId?: number, type?: number, enable?: boolean, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseAlarm>>;
    public alarmEndpointV1List(limit: number, deviceId?: number, type?: number, enable?: boolean, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling alarmEndpointV1List.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (enable !== undefined && enable !== null) {
            queryParameters = queryParameters.set('enable', <any>enable);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseAlarm>(`${this.basePath}/alarmEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1ListDevice(deviceId: number, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseAlarmPayload>;
    public alarmEndpointV1ListDevice(deviceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseAlarmPayload>>;
    public alarmEndpointV1ListDevice(deviceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseAlarmPayload>>;
    public alarmEndpointV1ListDevice(deviceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling alarmEndpointV1ListDevice.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseAlarmPayload>(`${this.basePath}/alarmEndpoint/v1/list/device`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1ListType(type: number, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseAlarm>;
    public alarmEndpointV1ListType(type: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseAlarm>>;
    public alarmEndpointV1ListType(type: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseAlarm>>;
    public alarmEndpointV1ListType(type: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling alarmEndpointV1ListType.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseAlarm>(`${this.basePath}/alarmEndpoint/v1/listType`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1Reminder(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public alarmEndpointV1Reminder(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public alarmEndpointV1Reminder(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public alarmEndpointV1Reminder(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/alarmEndpoint/v1/reminder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1RemoveId(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public alarmEndpointV1RemoveId(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public alarmEndpointV1RemoveId(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public alarmEndpointV1RemoveId(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling alarmEndpointV1RemoveId.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/alarmEndpoint/v1/removeId`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1RemoveType(deviceId: number, type: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public alarmEndpointV1RemoveType(deviceId: number, type: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public alarmEndpointV1RemoveType(deviceId: number, type: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public alarmEndpointV1RemoveType(deviceId: number, type: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling alarmEndpointV1RemoveType.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling alarmEndpointV1RemoveType.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/alarmEndpoint/v1/removeType`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1Save(body?: Alarm, observe?: 'body', reportProgress?: boolean): Observable<Alarm>;
    public alarmEndpointV1Save(body?: Alarm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Alarm>>;
    public alarmEndpointV1Save(body?: Alarm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Alarm>>;
    public alarmEndpointV1Save(body?: Alarm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Alarm>(`${this.basePath}/alarmEndpoint/v1/save`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1SaveAlarm(body?: AlarmRequest, observe?: 'body', reportProgress?: boolean): Observable<AlarmPayload>;
    public alarmEndpointV1SaveAlarm(body?: AlarmRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AlarmPayload>>;
    public alarmEndpointV1SaveAlarm(body?: AlarmRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AlarmPayload>>;
    public alarmEndpointV1SaveAlarm(body?: AlarmRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AlarmPayload>(`${this.basePath}/alarmEndpoint/v1/save/alarm`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param lat
     * @param lng
     * @param radius
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1SaveAnchor(deviceId: number, lat: number, lng: number, radius: number, observe?: 'body', reportProgress?: boolean): Observable<Alarm>;
    public alarmEndpointV1SaveAnchor(deviceId: number, lat: number, lng: number, radius: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Alarm>>;
    public alarmEndpointV1SaveAnchor(deviceId: number, lat: number, lng: number, radius: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Alarm>>;
    public alarmEndpointV1SaveAnchor(deviceId: number, lat: number, lng: number, radius: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling alarmEndpointV1SaveAnchor.');
        }

        if (lat === null || lat === undefined) {
            throw new Error('Required parameter lat was null or undefined when calling alarmEndpointV1SaveAnchor.');
        }

        if (lng === null || lng === undefined) {
            throw new Error('Required parameter lng was null or undefined when calling alarmEndpointV1SaveAnchor.');
        }

        if (radius === null || radius === undefined) {
            throw new Error('Required parameter radius was null or undefined when calling alarmEndpointV1SaveAnchor.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (lat !== undefined && lat !== null) {
            queryParameters = queryParameters.set('lat', <any>lat);
        }
        if (lng !== undefined && lng !== null) {
            queryParameters = queryParameters.set('lng', <any>lng);
        }
        if (radius !== undefined && radius !== null) {
            queryParameters = queryParameters.set('radius', <any>radius);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Alarm>(`${this.basePath}/alarmEndpoint/v1/saveAnchor`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public alarmEndpointV1Update(body?: Alarm, observe?: 'body', reportProgress?: boolean): Observable<Alarm>;
    public alarmEndpointV1Update(body?: Alarm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Alarm>>;
    public alarmEndpointV1Update(body?: Alarm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Alarm>>;
    public alarmEndpointV1Update(body?: Alarm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Alarm>(`${this.basePath}/alarmEndpoint/v1/update`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param gcm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public androidmessageEndpointV1Get(gcm: string, observe?: 'body', reportProgress?: boolean): Observable<AndroidMessage>;
    public androidmessageEndpointV1Get(gcm: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AndroidMessage>>;
    public androidmessageEndpointV1Get(gcm: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AndroidMessage>>;
    public androidmessageEndpointV1Get(gcm: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (gcm === null || gcm === undefined) {
            throw new Error('Required parameter gcm was null or undefined when calling androidmessageEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gcm !== undefined && gcm !== null) {
            queryParameters = queryParameters.set('gcm', <any>gcm);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AndroidMessage>(`${this.basePath}/androidmessageEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public androidmessageEndpointV1GetTestPush(observe?: 'body', reportProgress?: boolean): Observable<NotifyTest>;
    public androidmessageEndpointV1GetTestPush(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NotifyTest>>;
    public androidmessageEndpointV1GetTestPush(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NotifyTest>>;
    public androidmessageEndpointV1GetTestPush(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<NotifyTest>(`${this.basePath}/androidmessageEndpoint/v1/getTestPush`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public androidmessageEndpointV1ListDevices(observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseAndroidMessage>;
    public androidmessageEndpointV1ListDevices(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseAndroidMessage>>;
    public androidmessageEndpointV1ListDevices(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseAndroidMessage>>;
    public androidmessageEndpointV1ListDevices(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseAndroidMessage>(`${this.basePath}/androidmessageEndpoint/v1/listDevices`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param login
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public androidmessageEndpointV1ListLoginId(login: number, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseAndroidMessage>;
    public androidmessageEndpointV1ListLoginId(login: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseAndroidMessage>>;
    public androidmessageEndpointV1ListLoginId(login: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseAndroidMessage>>;
    public androidmessageEndpointV1ListLoginId(login: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (login === null || login === undefined) {
            throw new Error('Required parameter login was null or undefined when calling androidmessageEndpointV1ListLoginId.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (login !== undefined && login !== null) {
            queryParameters = queryParameters.set('login', <any>login);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseAndroidMessage>(`${this.basePath}/androidmessageEndpoint/v1/listLoginId`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public androidmessageEndpointV1Register(body?: AndroidMessage, observe?: 'body', reportProgress?: boolean): Observable<AndroidMessage>;
    public androidmessageEndpointV1Register(body?: AndroidMessage, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AndroidMessage>>;
    public androidmessageEndpointV1Register(body?: AndroidMessage, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AndroidMessage>>;
    public androidmessageEndpointV1Register(body?: AndroidMessage, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AndroidMessage>(`${this.basePath}/androidmessageEndpoint/v1/register`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param gcm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public androidmessageEndpointV1Remove(gcm: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public androidmessageEndpointV1Remove(gcm: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public androidmessageEndpointV1Remove(gcm: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public androidmessageEndpointV1Remove(gcm: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (gcm === null || gcm === undefined) {
            throw new Error('Required parameter gcm was null or undefined when calling androidmessageEndpointV1Remove.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (gcm !== undefined && gcm !== null) {
            queryParameters = queryParameters.set('gcm', <any>gcm);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/androidmessageEndpoint/v1/remove`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public androidmessageEndpointV1RemoveId(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public androidmessageEndpointV1RemoveId(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public androidmessageEndpointV1RemoveId(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public androidmessageEndpointV1RemoveId(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling androidmessageEndpointV1RemoveId.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/androidmessageEndpoint/v1/removeId`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param login
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public androidmessageEndpointV1RemoveLoginId(login: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public androidmessageEndpointV1RemoveLoginId(login: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public androidmessageEndpointV1RemoveLoginId(login: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public androidmessageEndpointV1RemoveLoginId(login: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (login === null || login === undefined) {
            throw new Error('Required parameter login was null or undefined when calling androidmessageEndpointV1RemoveLoginId.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (login !== undefined && login !== null) {
            queryParameters = queryParameters.set('login', <any>login);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/androidmessageEndpoint/v1/removeLoginId`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param email
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public androidmessageEndpointV1TestPush(email: string, body?: NotifyTest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public androidmessageEndpointV1TestPush(email: string, body?: NotifyTest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public androidmessageEndpointV1TestPush(email: string, body?: NotifyTest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public androidmessageEndpointV1TestPush(email: string, body?: NotifyTest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling androidmessageEndpointV1TestPush.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/androidmessageEndpoint/v1/testPush`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public androidmessageEndpointV1Update(body?: AndroidMessage, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public androidmessageEndpointV1Update(body?: AndroidMessage, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public androidmessageEndpointV1Update(body?: AndroidMessage, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public androidmessageEndpointV1Update(body?: AndroidMessage, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/androidmessageEndpoint/v1/update`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public brandEndpointV1Delete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public brandEndpointV1Delete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public brandEndpointV1Delete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public brandEndpointV1Delete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling brandEndpointV1Delete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/brandEndpoint/v1/delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public brandEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Brand>;
    public brandEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Brand>>;
    public brandEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Brand>>;
    public brandEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling brandEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Brand>(`${this.basePath}/brandEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public brandEndpointV1List(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseBrand>;
    public brandEndpointV1List(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseBrand>>;
    public brandEndpointV1List(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseBrand>>;
    public brandEndpointV1List(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling brandEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseBrand>(`${this.basePath}/brandEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public brandEndpointV1Save(body?: Brand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public brandEndpointV1Save(body?: Brand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public brandEndpointV1Save(body?: Brand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public brandEndpointV1Save(body?: Brand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/brandEndpoint/v1/create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoryEndpointV1Delete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public categoryEndpointV1Delete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public categoryEndpointV1Delete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public categoryEndpointV1Delete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling categoryEndpointV1Delete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/categoryEndpoint/v1/delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoryEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Category>;
    public categoryEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Category>>;
    public categoryEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Category>>;
    public categoryEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling categoryEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Category>(`${this.basePath}/categoryEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoryEndpointV1List(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseCategory>;
    public categoryEndpointV1List(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseCategory>>;
    public categoryEndpointV1List(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseCategory>>;
    public categoryEndpointV1List(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling categoryEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseCategory>(`${this.basePath}/categoryEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public categoryEndpointV1Save(body?: Category, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public categoryEndpointV1Save(body?: Category, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public categoryEndpointV1Save(body?: Category, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public categoryEndpointV1Save(body?: Category, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/categoryEndpoint/v1/create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param chipId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chipm2mEndpointV1Delete(chipId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public chipm2mEndpointV1Delete(chipId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public chipm2mEndpointV1Delete(chipId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public chipm2mEndpointV1Delete(chipId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chipId === null || chipId === undefined) {
            throw new Error('Required parameter chipId was null or undefined when calling chipm2mEndpointV1Delete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chipId !== undefined && chipId !== null) {
            queryParameters = queryParameters.set('chip_id', <any>chipId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/chipm2mEndpoint/v1/delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param serialNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chipm2mEndpointV1Feature(serialNumber: string, observe?: 'body', reportProgress?: boolean): Observable<FeaturePlainsChipM2M>;
    public chipm2mEndpointV1Feature(serialNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FeaturePlainsChipM2M>>;
    public chipm2mEndpointV1Feature(serialNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FeaturePlainsChipM2M>>;
    public chipm2mEndpointV1Feature(serialNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serialNumber === null || serialNumber === undefined) {
            throw new Error('Required parameter serialNumber was null or undefined when calling chipm2mEndpointV1Feature.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (serialNumber !== undefined && serialNumber !== null) {
            queryParameters = queryParameters.set('serial_number', <any>serialNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<FeaturePlainsChipM2M>(`${this.basePath}/chipm2mEndpoint/v1/feature`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param serialNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chipm2mEndpointV1Find(serialNumber: string, observe?: 'body', reportProgress?: boolean): Observable<ChipM2MFindPayload>;
    public chipm2mEndpointV1Find(serialNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChipM2MFindPayload>>;
    public chipm2mEndpointV1Find(serialNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChipM2MFindPayload>>;
    public chipm2mEndpointV1Find(serialNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serialNumber === null || serialNumber === undefined) {
            throw new Error('Required parameter serialNumber was null or undefined when calling chipm2mEndpointV1Find.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (serialNumber !== undefined && serialNumber !== null) {
            queryParameters = queryParameters.set('serial_number', <any>serialNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ChipM2MFindPayload>(`${this.basePath}/chipm2mEndpoint/v1/find`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param chipId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chipm2mEndpointV1Get(chipId: number, observe?: 'body', reportProgress?: boolean): Observable<ChipM2M>;
    public chipm2mEndpointV1Get(chipId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChipM2M>>;
    public chipm2mEndpointV1Get(chipId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChipM2M>>;
    public chipm2mEndpointV1Get(chipId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chipId === null || chipId === undefined) {
            throw new Error('Required parameter chipId was null or undefined when calling chipm2mEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chipId !== undefined && chipId !== null) {
            queryParameters = queryParameters.set('chip_id', <any>chipId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ChipM2M>(`${this.basePath}/chipm2mEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chipm2mEndpointV1List(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseChipM2M>;
    public chipm2mEndpointV1List(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseChipM2M>>;
    public chipm2mEndpointV1List(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseChipM2M>>;
    public chipm2mEndpointV1List(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling chipm2mEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseChipM2M>(`${this.basePath}/chipm2mEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chipm2mEndpointV1Save(body?: ChipM2M, observe?: 'body', reportProgress?: boolean): Observable<ChipM2M>;
    public chipm2mEndpointV1Save(body?: ChipM2M, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChipM2M>>;
    public chipm2mEndpointV1Save(body?: ChipM2M, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChipM2M>>;
    public chipm2mEndpointV1Save(body?: ChipM2M, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ChipM2M>(`${this.basePath}/chipm2mEndpoint/v1/save`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param featureId
     * @param providerId
     * @param preview
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chipm2mEndpointV1SaveList(featureId: number, providerId: number, preview?: boolean, body?: ChipArray, observe?: 'body', reportProgress?: boolean): Observable<ChipM2MSave>;
    public chipm2mEndpointV1SaveList(featureId: number, providerId: number, preview?: boolean, body?: ChipArray, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChipM2MSave>>;
    public chipm2mEndpointV1SaveList(featureId: number, providerId: number, preview?: boolean, body?: ChipArray, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChipM2MSave>>;
    public chipm2mEndpointV1SaveList(featureId: number, providerId: number, preview?: boolean, body?: ChipArray, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (featureId === null || featureId === undefined) {
            throw new Error('Required parameter featureId was null or undefined when calling chipm2mEndpointV1SaveList.');
        }

        if (providerId === null || providerId === undefined) {
            throw new Error('Required parameter providerId was null or undefined when calling chipm2mEndpointV1SaveList.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (preview !== undefined && preview !== null) {
            queryParameters = queryParameters.set('preview', <any>preview);
        }
        if (featureId !== undefined && featureId !== null) {
            queryParameters = queryParameters.set('feature_id', <any>featureId);
        }
        if (providerId !== undefined && providerId !== null) {
            queryParameters = queryParameters.set('provider_id', <any>providerId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ChipM2MSave>(`${this.basePath}/chipm2mEndpoint/v1/list`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param serialNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chipm2mEndpointV1Serial(serialNumber: string, observe?: 'body', reportProgress?: boolean): Observable<ChipM2M>;
    public chipm2mEndpointV1Serial(serialNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChipM2M>>;
    public chipm2mEndpointV1Serial(serialNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChipM2M>>;
    public chipm2mEndpointV1Serial(serialNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serialNumber === null || serialNumber === undefined) {
            throw new Error('Required parameter serialNumber was null or undefined when calling chipm2mEndpointV1Serial.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (serialNumber !== undefined && serialNumber !== null) {
            queryParameters = queryParameters.set('serial_number', <any>serialNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ChipM2M>(`${this.basePath}/chipm2mEndpoint/v1/serial`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param featureId
     * @param productId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chipm2mEndpointV1TempReplaceFeatureProduct(featureId: number, productId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public chipm2mEndpointV1TempReplaceFeatureProduct(featureId: number, productId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public chipm2mEndpointV1TempReplaceFeatureProduct(featureId: number, productId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public chipm2mEndpointV1TempReplaceFeatureProduct(featureId: number, productId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (featureId === null || featureId === undefined) {
            throw new Error('Required parameter featureId was null or undefined when calling chipm2mEndpointV1TempReplaceFeatureProduct.');
        }

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling chipm2mEndpointV1TempReplaceFeatureProduct.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (featureId !== undefined && featureId !== null) {
            queryParameters = queryParameters.set('feature_id', <any>featureId);
        }
        if (productId !== undefined && productId !== null) {
            queryParameters = queryParameters.set('product_id', <any>productId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/chipm2mEndpoint/v1/temp/replaceFeature`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chipm2mEndpointV1Update(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public chipm2mEndpointV1Update(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public chipm2mEndpointV1Update(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public chipm2mEndpointV1Update(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/chipm2mEndpoint/v1/update`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param serial
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chipm2mEndpointV1ValidateSerial(serial: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public chipm2mEndpointV1ValidateSerial(serial: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public chipm2mEndpointV1ValidateSerial(serial: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public chipm2mEndpointV1ValidateSerial(serial: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serial === null || serial === undefined) {
            throw new Error('Required parameter serial was null or undefined when calling chipm2mEndpointV1ValidateSerial.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (serial !== undefined && serial !== null) {
            queryParameters = queryParameters.set('serial', <any>serial);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/chipm2mEndpoint/v1/validate/serial`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param hostId
     * @param deviceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandEndpointV1Block(hostId?: number, deviceId?: number, observe?: 'body', reportProgress?: boolean): Observable<CommandStatusPayload>;
    public commandEndpointV1Block(hostId?: number, deviceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandStatusPayload>>;
    public commandEndpointV1Block(hostId?: number, deviceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandStatusPayload>>;
    public commandEndpointV1Block(hostId?: number, deviceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('host_id', <any>hostId);
        }
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<CommandStatusPayload>(`${this.basePath}/commandEndpoint/v1/block`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param commandId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandEndpointV1Cancel(commandId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commandEndpointV1Cancel(commandId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commandEndpointV1Cancel(commandId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commandEndpointV1Cancel(commandId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commandId === null || commandId === undefined) {
            throw new Error('Required parameter commandId was null or undefined when calling commandEndpointV1Cancel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (commandId !== undefined && commandId !== null) {
            queryParameters = queryParameters.set('command_id', <any>commandId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/commandEndpoint/v1/cancel`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param commandType
     * @param hostId
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandEndpointV1List(commandType: number, hostId: number, limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseCommand>;
    public commandEndpointV1List(commandType: number, hostId: number, limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseCommand>>;
    public commandEndpointV1List(commandType: number, hostId: number, limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseCommand>>;
    public commandEndpointV1List(commandType: number, hostId: number, limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commandType === null || commandType === undefined) {
            throw new Error('Required parameter commandType was null or undefined when calling commandEndpointV1List.');
        }

        if (hostId === null || hostId === undefined) {
            throw new Error('Required parameter hostId was null or undefined when calling commandEndpointV1List.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling commandEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (commandType !== undefined && commandType !== null) {
            queryParameters = queryParameters.set('command_type', <any>commandType);
        }
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('host_id', <any>hostId);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseCommand>(`${this.basePath}/commandEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param equipmentId
     * @param commandType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandEndpointV1NotifyCommand(equipmentId: number, commandType: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commandEndpointV1NotifyCommand(equipmentId: number, commandType: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commandEndpointV1NotifyCommand(equipmentId: number, commandType: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commandEndpointV1NotifyCommand(equipmentId: number, commandType: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (equipmentId === null || equipmentId === undefined) {
            throw new Error('Required parameter equipmentId was null or undefined when calling commandEndpointV1NotifyCommand.');
        }

        if (commandType === null || commandType === undefined) {
            throw new Error('Required parameter commandType was null or undefined when calling commandEndpointV1NotifyCommand.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (equipmentId !== undefined && equipmentId !== null) {
            queryParameters = queryParameters.set('equipmentId', <any>equipmentId);
        }
        if (commandType !== undefined && commandType !== null) {
            queryParameters = queryParameters.set('commandType', <any>commandType);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/commandEndpoint/v1/notifyCommand`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param hostId
     * @param deviceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commandEndpointV1Unblock(hostId?: number, deviceId?: number, observe?: 'body', reportProgress?: boolean): Observable<CommandStatusPayload>;
    public commandEndpointV1Unblock(hostId?: number, deviceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandStatusPayload>>;
    public commandEndpointV1Unblock(hostId?: number, deviceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandStatusPayload>>;
    public commandEndpointV1Unblock(hostId?: number, deviceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('host_id', <any>hostId);
        }
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<CommandStatusPayload>(`${this.basePath}/commandEndpoint/v1/unblock`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param contractId
     * @param invoiceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractEndpointV1AddOrder(contractId: number, invoiceId?: number, observe?: 'body', reportProgress?: boolean): Observable<Contract>;
    public contractEndpointV1AddOrder(contractId: number, invoiceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Contract>>;
    public contractEndpointV1AddOrder(contractId: number, invoiceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Contract>>;
    public contractEndpointV1AddOrder(contractId: number, invoiceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling contractEndpointV1AddOrder.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contractId !== undefined && contractId !== null) {
            queryParameters = queryParameters.set('contract_id', <any>contractId);
        }
        if (invoiceId !== undefined && invoiceId !== null) {
            queryParameters = queryParameters.set('invoice_id', <any>invoiceId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Contract>(`${this.basePath}/contractEndpoint/v1/addOrder`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractEndpointV1DueDate(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseContract>;
    public contractEndpointV1DueDate(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseContract>>;
    public contractEndpointV1DueDate(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseContract>>;
    public contractEndpointV1DueDate(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling contractEndpointV1DueDate.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<CollectionResponseContract>(`${this.basePath}/contractEndpoint/v1/dueDate`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractEndpointV1FindDuplicated(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<ContractDuplicatedPayloadCollection>;
    public contractEndpointV1FindDuplicated(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContractDuplicatedPayloadCollection>>;
    public contractEndpointV1FindDuplicated(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContractDuplicatedPayloadCollection>>;
    public contractEndpointV1FindDuplicated(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling contractEndpointV1FindDuplicated.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ContractDuplicatedPayloadCollection>(`${this.basePath}/contractEndpoint/v1/findDuplicated`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Contract>;
    public contractEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Contract>>;
    public contractEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Contract>>;
    public contractEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contractEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Contract>(`${this.basePath}/contractEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param loginId
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractEndpointV1List(limit: number, loginId?: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseContract>;
    public contractEndpointV1List(limit: number, loginId?: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseContract>>;
    public contractEndpointV1List(limit: number, loginId?: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseContract>>;
    public contractEndpointV1List(limit: number, loginId?: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling contractEndpointV1List.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loginId !== undefined && loginId !== null) {
            queryParameters = queryParameters.set('login_id', <any>loginId);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseContract>(`${this.basePath}/contractEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractEndpointV1ListAll(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseContract>;
    public contractEndpointV1ListAll(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseContract>>;
    public contractEndpointV1ListAll(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseContract>>;
    public contractEndpointV1ListAll(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling contractEndpointV1ListAll.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseContract>(`${this.basePath}/contractEndpoint/v1/listAll`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param contractId
     * @param hostId
     * @param plainId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractEndpointV1Migrate(contractId: number, hostId?: number, plainId?: number, observe?: 'body', reportProgress?: boolean): Observable<Contract>;
    public contractEndpointV1Migrate(contractId: number, hostId?: number, plainId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Contract>>;
    public contractEndpointV1Migrate(contractId: number, hostId?: number, plainId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Contract>>;
    public contractEndpointV1Migrate(contractId: number, hostId?: number, plainId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling contractEndpointV1Migrate.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contractId !== undefined && contractId !== null) {
            queryParameters = queryParameters.set('contract_id', <any>contractId);
        }
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('host_id', <any>hostId);
        }
        if (plainId !== undefined && plainId !== null) {
            queryParameters = queryParameters.set('plain_id', <any>plainId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Contract>(`${this.basePath}/contractEndpoint/v1/migrate`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param contractId
     * @param nextDueDate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractEndpointV1NextDueDate(contractId: number, nextDueDate: Date, observe?: 'body', reportProgress?: boolean): Observable<Contract>;
    public contractEndpointV1NextDueDate(contractId: number, nextDueDate: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Contract>>;
    public contractEndpointV1NextDueDate(contractId: number, nextDueDate: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Contract>>;
    public contractEndpointV1NextDueDate(contractId: number, nextDueDate: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling contractEndpointV1NextDueDate.');
        }

        if (nextDueDate === null || nextDueDate === undefined) {
            throw new Error('Required parameter nextDueDate was null or undefined when calling contractEndpointV1NextDueDate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contractId !== undefined && contractId !== null) {
            queryParameters = queryParameters.set('contract_id', <any>contractId);
        }
        if (nextDueDate !== undefined && nextDueDate !== null) {
            queryParameters = queryParameters.set('nextDueDate', <any>nextDueDate.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Contract>(`${this.basePath}/contractEndpoint/v1/nextDueDate`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param hostId
     * @param plainId
     * @param createDate
     * @param dueDate
     * @param loginId
     * @param nextDueDate
     * @param m2mId
     * @param deviceId
     * @param discountExpireAt
     * @param discountAmount
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractEndpointV1Register(hostId: number, plainId: number, createDate: Date, dueDate: Date, loginId?: number, nextDueDate?: Date, m2mId?: number, deviceId?: number, discountExpireAt?: Date, discountAmount?: number,discountDescription?: string, observe?: 'body', reportProgress?: boolean): Observable<StateContract>;
    public contractEndpointV1Register(hostId: number, plainId: number, createDate: Date, dueDate: Date, loginId?: number, nextDueDate?: Date, m2mId?: number, deviceId?: number, discountExpireAt?: Date, discountAmount?: number,discountDescription?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StateContract>>;
    public contractEndpointV1Register(hostId: number, plainId: number, createDate: Date, dueDate: Date, loginId?: number, nextDueDate?: Date, m2mId?: number, deviceId?: number, discountExpireAt?: Date, discountAmount?: number,discountDescription?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StateContract>>;
    public contractEndpointV1Register(hostId: number, plainId: number, createDate: Date, dueDate: Date, loginId?: number, nextDueDate?: Date, m2mId?: number, deviceId?: number, discountExpireAt?: Date, discountAmount?: number,discountDescription?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hostId === null || hostId === undefined) {
            throw new Error('Required parameter hostId was null or undefined when calling contractEndpointV1Register.');
        }

        if (plainId === null || plainId === undefined) {
            throw new Error('Required parameter plainId was null or undefined when calling contractEndpointV1Register.');
        }

        if (createDate === null || createDate === undefined) {
            throw new Error('Required parameter createDate was null or undefined when calling contractEndpointV1Register.');
        }

        if (dueDate === null || dueDate === undefined) {
            throw new Error('Required parameter dueDate was null or undefined when calling contractEndpointV1Register.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loginId !== undefined && loginId !== null) {
            queryParameters = queryParameters.set('login_id', <any>loginId);
        }
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('host_id', <any>hostId);
        }
        if (plainId !== undefined && plainId !== null) {
            queryParameters = queryParameters.set('plain_id', <any>plainId);
        }
        if (createDate !== undefined && createDate !== null) {
            queryParameters = queryParameters.set('create_date', <any>createDate.toISOString());
        }
        if (dueDate !== undefined && dueDate !== null) {
            queryParameters = queryParameters.set('due_date', <any>dueDate.toISOString());
        }
        if (nextDueDate !== undefined && nextDueDate !== null) {
            queryParameters = queryParameters.set('next_due_date', <any>nextDueDate.toISOString());
        }
        if (m2mId !== undefined && m2mId !== null) {
            queryParameters = queryParameters.set('m2m_id', <any>m2mId);
        }
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (discountExpireAt !== undefined && discountExpireAt !== null) {
            queryParameters = queryParameters.set('discount_expire_at', <any>discountExpireAt.toISOString());
        }
        if (discountAmount !== undefined && discountAmount !== null) {
            queryParameters = queryParameters.set('discount_amount', <any>discountAmount);
        }
        if (discountDescription !== undefined && discountDescription !== null) {
           queryParameters = queryParameters.set('discount_description', <any>discountDescription);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<StateContract>(`${this.basePath}/contractEndpoint/v1/register`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param hostId
     * @param plainId
     * @param contractDate
     * @param dueDate
     * @param loginId
     * @param nextDueDate
     * @param discountExpireAt
     * @param discountAmount
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractEndpointV1Review(hostId: number, plainId: number, contractDate: Date, dueDate: Date, loginId?: number, nextDueDate?: Date, discountExpireAt?: Date, discountAmount?: number, observe?: 'body', reportProgress?: boolean): Observable<ContractReviewPayload>;
    public contractEndpointV1Review(hostId: number, plainId: number, contractDate: Date, dueDate: Date, loginId?: number, nextDueDate?: Date, discountExpireAt?: Date, discountAmount?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContractReviewPayload>>;
    public contractEndpointV1Review(hostId: number, plainId: number, contractDate: Date, dueDate: Date, loginId?: number, nextDueDate?: Date, discountExpireAt?: Date, discountAmount?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContractReviewPayload>>;
    public contractEndpointV1Review(hostId: number, plainId: number, contractDate: Date, dueDate: Date, loginId?: number, nextDueDate?: Date, discountExpireAt?: Date, discountAmount?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hostId === null || hostId === undefined) {
            throw new Error('Required parameter hostId was null or undefined when calling contractEndpointV1Review.');
        }

        if (plainId === null || plainId === undefined) {
            throw new Error('Required parameter plainId was null or undefined when calling contractEndpointV1Review.');
        }

        if (contractDate === null || contractDate === undefined) {
            throw new Error('Required parameter contractDate was null or undefined when calling contractEndpointV1Review.');
        }

        if (dueDate === null || dueDate === undefined) {
            throw new Error('Required parameter dueDate was null or undefined when calling contractEndpointV1Review.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loginId !== undefined && loginId !== null) {
            queryParameters = queryParameters.set('login_id', <any>loginId);
        }
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('host_id', <any>hostId);
        }
        if (plainId !== undefined && plainId !== null) {
            queryParameters = queryParameters.set('plain_id', <any>plainId);
        }
        if (contractDate !== undefined && contractDate !== null) {
            queryParameters = queryParameters.set('contract_date', <any>contractDate.toISOString());
        }
        if (dueDate !== undefined && dueDate !== null) {
            queryParameters = queryParameters.set('due_date', <any>dueDate.toISOString());
        }
        if (nextDueDate !== undefined && nextDueDate !== null) {
            queryParameters = queryParameters.set('next_due_date', <any>nextDueDate.toISOString());
        }
        if (discountExpireAt !== undefined && discountExpireAt !== null) {
            queryParameters = queryParameters.set('discount_expire_at', <any>discountExpireAt.toISOString());
        }
        if (discountAmount !== undefined && discountAmount !== null) {
            queryParameters = queryParameters.set('discount_amount', <any>discountAmount);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ContractReviewPayload>(`${this.basePath}/contractEndpoint/v1/review`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param productId
     * @param featureId
     * @param status
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractEndpointV1Search(limit: number, productId?: number, featureId?: number, status?: 'ACTIVE' | 'CHANGE' | 'PENDING' | 'CANCELED', cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseContract>;
    public contractEndpointV1Search(limit: number, productId?: number, featureId?: number, status?: 'ACTIVE' | 'CHANGE' | 'PENDING' | 'CANCELED', cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseContract>>;
    public contractEndpointV1Search(limit: number, productId?: number, featureId?: number, status?: 'ACTIVE' | 'CHANGE' | 'PENDING' | 'CANCELED', cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseContract>>;
    public contractEndpointV1Search(limit: number, productId?: number, featureId?: number, status?: 'ACTIVE' | 'CHANGE' | 'PENDING' | 'CANCELED', cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling contractEndpointV1Search.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productId !== undefined && productId !== null) {
            queryParameters = queryParameters.set('product_id', <any>productId);
        }
        if (featureId !== undefined && featureId !== null) {
            queryParameters = queryParameters.set('feature_id', <any>featureId);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<CollectionResponseContract>(`${this.basePath}/contractEndpoint/v1/search`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param loginId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1AccountHostList(loginId: number, observe?: 'body', reportProgress?: boolean): Observable<HostCollection>;
    public dashEndpointV1AccountHostList(loginId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HostCollection>>;
    public dashEndpointV1AccountHostList(loginId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HostCollection>>;
    public dashEndpointV1AccountHostList(loginId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginId === null || loginId === undefined) {
            throw new Error('Required parameter loginId was null or undefined when calling dashEndpointV1AccountHostList.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loginId !== undefined && loginId !== null) {
            queryParameters = queryParameters.set('login_id', <any>loginId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<HostCollection>(`${this.basePath}/dashEndpoint/v1/account/host/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param loginId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1AccountPermissionGet(loginId: number, observe?: 'body', reportProgress?: boolean): Observable<Permission>;
    public dashEndpointV1AccountPermissionGet(loginId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Permission>>;
    public dashEndpointV1AccountPermissionGet(loginId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Permission>>;
    public dashEndpointV1AccountPermissionGet(loginId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginId === null || loginId === undefined) {
            throw new Error('Required parameter loginId was null or undefined when calling dashEndpointV1AccountPermissionGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loginId !== undefined && loginId !== null) {
            queryParameters = queryParameters.set('loginId', <any>loginId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Permission>(`${this.basePath}/dashEndpoint/v1/account/permission`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1AccountPermissionSave(body?: Permission, observe?: 'body', reportProgress?: boolean): Observable<Permission>;
    public dashEndpointV1AccountPermissionSave(body?: Permission, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Permission>>;
    public dashEndpointV1AccountPermissionSave(body?: Permission, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Permission>>;
    public dashEndpointV1AccountPermissionSave(body?: Permission, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Permission>(`${this.basePath}/dashEndpoint/v1/account/permission`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param loginId
     * @param email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1AccountStatus(loginId?: number, email?: string, observe?: 'body', reportProgress?: boolean): Observable<UserStatus>;
    public dashEndpointV1AccountStatus(loginId?: number, email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserStatus>>;
    public dashEndpointV1AccountStatus(loginId?: number, email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserStatus>>;
    public dashEndpointV1AccountStatus(loginId?: number, email?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loginId !== undefined && loginId !== null) {
            queryParameters = queryParameters.set('login_id', <any>loginId);
        }
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<UserStatus>(`${this.basePath}/dashEndpoint/v1/account/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param chipId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1ChipCancel(chipId: number, observe?: 'body', reportProgress?: boolean): Observable<ChipM2M>;
    public dashEndpointV1ChipCancel(chipId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChipM2M>>;
    public dashEndpointV1ChipCancel(chipId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChipM2M>>;
    public dashEndpointV1ChipCancel(chipId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (chipId === null || chipId === undefined) {
            throw new Error('Required parameter chipId was null or undefined when calling dashEndpointV1ChipCancel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (chipId !== undefined && chipId !== null) {
            queryParameters = queryParameters.set('chip_id', <any>chipId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ChipM2M>(`${this.basePath}/dashEndpoint/v1/chip/cancel`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param contractId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1ContractCancel(contractId: number, observe?: 'body', reportProgress?: boolean): Observable<Contract>;
    public dashEndpointV1ContractCancel(contractId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Contract>>;
    public dashEndpointV1ContractCancel(contractId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Contract>>;
    public dashEndpointV1ContractCancel(contractId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling dashEndpointV1ContractCancel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contractId !== undefined && contractId !== null) {
            queryParameters = queryParameters.set('contract_id', <any>contractId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Contract>(`${this.basePath}/dashEndpoint/v1/contract/cancel`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param contractId
     * @param plainId
     * @param m2mId
     * @param deviceId
     * @param discountExpireAt
     * @param discountAmount
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1ContractUpgradeV2(contractId: number, plainId?: number, m2mId?: number, deviceId?: number, discountExpireAt?: Date, discountAmount?: number, observe?: 'body', reportProgress?: boolean): Observable<Contract>;
    public dashEndpointV1ContractUpgradeV2(contractId: number, plainId?: number, m2mId?: number, deviceId?: number, discountExpireAt?: Date, discountAmount?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Contract>>;
    public dashEndpointV1ContractUpgradeV2(contractId: number, plainId?: number, m2mId?: number, deviceId?: number, discountExpireAt?: Date, discountAmount?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Contract>>;
    public dashEndpointV1ContractUpgradeV2(contractId: number, plainId?: number, m2mId?: number, deviceId?: number, discountExpireAt?: Date, discountAmount?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling dashEndpointV1ContractUpgradeV2.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (contractId !== undefined && contractId !== null) {
            queryParameters = queryParameters.set('contract_id', <any>contractId);
        }
        if (plainId !== undefined && plainId !== null) {
            queryParameters = queryParameters.set('plain_id', <any>plainId);
        }
        if (m2mId !== undefined && m2mId !== null) {
            queryParameters = queryParameters.set('m2m_id', <any>m2mId);
        }
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (discountExpireAt !== undefined && discountExpireAt !== null) {
            queryParameters = queryParameters.set('discount_expire_at', <any>discountExpireAt.toISOString());
        }
        if (discountAmount !== undefined && discountAmount !== null) {
            queryParameters = queryParameters.set('discount_amount', <any>discountAmount);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Contract>(`${this.basePath}/dashEndpoint/v1/contract/upgrade/v2`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param imei
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1DeviceStatus(imei: string, observe?: 'body', reportProgress?: boolean): Observable<IMEIStatus>;
    public dashEndpointV1DeviceStatus(imei: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IMEIStatus>>;
    public dashEndpointV1DeviceStatus(imei: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IMEIStatus>>;
    public dashEndpointV1DeviceStatus(imei: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling dashEndpointV1DeviceStatus.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (imei !== undefined && imei !== null) {
            queryParameters = queryParameters.set('imei', <any>imei);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<IMEIStatus>(`${this.basePath}/dashEndpoint/v1/device/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param hostId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1HostDelete(hostId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dashEndpointV1HostDelete(hostId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dashEndpointV1HostDelete(hostId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dashEndpointV1HostDelete(hostId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hostId === null || hostId === undefined) {
            throw new Error('Required parameter hostId was null or undefined when calling dashEndpointV1HostDelete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('host_id', <any>hostId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/dashEndpoint/v1/host/delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param hostId
     * @param fromLogin
     * @param toLogin
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1HostMigrateAccount(hostId: number, fromLogin: number, toLogin: number, observe?: 'body', reportProgress?: boolean): Observable<Host>;
    public dashEndpointV1HostMigrateAccount(hostId: number, fromLogin: number, toLogin: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Host>>;
    public dashEndpointV1HostMigrateAccount(hostId: number, fromLogin: number, toLogin: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Host>>;
    public dashEndpointV1HostMigrateAccount(hostId: number, fromLogin: number, toLogin: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hostId === null || hostId === undefined) {
            throw new Error('Required parameter hostId was null or undefined when calling dashEndpointV1HostMigrateAccount.');
        }

        if (fromLogin === null || fromLogin === undefined) {
            throw new Error('Required parameter fromLogin was null or undefined when calling dashEndpointV1HostMigrateAccount.');
        }

        if (toLogin === null || toLogin === undefined) {
            throw new Error('Required parameter toLogin was null or undefined when calling dashEndpointV1HostMigrateAccount.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('host_id', <any>hostId);
        }
        if (fromLogin !== undefined && fromLogin !== null) {
            queryParameters = queryParameters.set('from_login', <any>fromLogin);
        }
        if (toLogin !== undefined && toLogin !== null) {
            queryParameters = queryParameters.set('to_login', <any>toLogin);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Host>(`${this.basePath}/dashEndpoint/v1/host/migrate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param paymentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1PaymentConfirm(paymentId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dashEndpointV1PaymentConfirm(paymentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dashEndpointV1PaymentConfirm(paymentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dashEndpointV1PaymentConfirm(paymentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentId === null || paymentId === undefined) {
            throw new Error('Required parameter paymentId was null or undefined when calling dashEndpointV1PaymentConfirm.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (paymentId !== undefined && paymentId !== null) {
            queryParameters = queryParameters.set('paymentId', <any>paymentId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/dashEndpoint/v1/payment/confirm`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param imei
     * @param days
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashEndpointV1PaymentCreate(imei: string, days: number, observe?: 'body', reportProgress?: boolean): Observable<Payment>;
    public dashEndpointV1PaymentCreate(imei: string, days: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Payment>>;
    public dashEndpointV1PaymentCreate(imei: string, days: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Payment>>;
    public dashEndpointV1PaymentCreate(imei: string, days: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling dashEndpointV1PaymentCreate.');
        }

        if (days === null || days === undefined) {
            throw new Error('Required parameter days was null or undefined when calling dashEndpointV1PaymentCreate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (imei !== undefined && imei !== null) {
            queryParameters = queryParameters.set('imei', <any>imei);
        }
        if (days !== undefined && days !== null) {
            queryParameters = queryParameters.set('days', <any>days);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Payment>(`${this.basePath}/dashEndpoint/v1/payment/create`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceIconId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1DeleteDeviceIcon(deviceIconId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deviceEndpointV1DeleteDeviceIcon(deviceIconId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deviceEndpointV1DeleteDeviceIcon(deviceIconId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deviceEndpointV1DeleteDeviceIcon(deviceIconId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceIconId === null || deviceIconId === undefined) {
            throw new Error('Required parameter deviceIconId was null or undefined when calling deviceEndpointV1DeleteDeviceIcon.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceIconId !== undefined && deviceIconId !== null) {
            queryParameters = queryParameters.set('deviceIconId', <any>deviceIconId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/deviceEndpoint/v1/deleteDeviceIcon`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param imei
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1Feature(imei: string, observe?: 'body', reportProgress?: boolean): Observable<FeaturePlainsDevice>;
    public deviceEndpointV1Feature(imei: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FeaturePlainsDevice>>;
    public deviceEndpointV1Feature(imei: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FeaturePlainsDevice>>;
    public deviceEndpointV1Feature(imei: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling deviceEndpointV1Feature.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (imei !== undefined && imei !== null) {
            queryParameters = queryParameters.set('imei', <any>imei);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<FeaturePlainsDevice>(`${this.basePath}/deviceEndpoint/v1/feature`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1Get(deviceId: number, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public deviceEndpointV1Get(deviceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public deviceEndpointV1Get(deviceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public deviceEndpointV1Get(deviceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling deviceEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Device>(`${this.basePath}/deviceEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceIconId
     * @param name
     * @param type
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1GetDeviceIcon(deviceIconId?: number, name?: string, type?: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceIcon>;
    public deviceEndpointV1GetDeviceIcon(deviceIconId?: number, name?: string, type?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceIcon>>;
    public deviceEndpointV1GetDeviceIcon(deviceIconId?: number, name?: string, type?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceIcon>>;
    public deviceEndpointV1GetDeviceIcon(deviceIconId?: number, name?: string, type?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceIconId !== undefined && deviceIconId !== null) {
            queryParameters = queryParameters.set('deviceIconId', <any>deviceIconId);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<DeviceIcon>(`${this.basePath}/deviceEndpoint/v1/getDeviceIcon`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param imei
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1GetIMEI(imei: string, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public deviceEndpointV1GetIMEI(imei: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public deviceEndpointV1GetIMEI(imei: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public deviceEndpointV1GetIMEI(imei: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling deviceEndpointV1GetIMEI.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (imei !== undefined && imei !== null) {
            queryParameters = queryParameters.set('imei', <any>imei);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Device>(`${this.basePath}/deviceEndpoint/v1/getIMEI`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param chipId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1LinkDeviceChipContract(deviceId: number, chipId: number, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public deviceEndpointV1LinkDeviceChipContract(deviceId: number, chipId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public deviceEndpointV1LinkDeviceChipContract(deviceId: number, chipId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public deviceEndpointV1LinkDeviceChipContract(deviceId: number, chipId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling deviceEndpointV1LinkDeviceChipContract.');
        }

        if (chipId === null || chipId === undefined) {
            throw new Error('Required parameter chipId was null or undefined when calling deviceEndpointV1LinkDeviceChipContract.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (chipId !== undefined && chipId !== null) {
            queryParameters = queryParameters.set('chip_id', <any>chipId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Device>(`${this.basePath}/deviceEndpoint/v1/temp/linkDeviceChipContract`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1List(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseDevice>;
    public deviceEndpointV1List(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseDevice>>;
    public deviceEndpointV1List(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseDevice>>;
    public deviceEndpointV1List(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling deviceEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseDevice>(`${this.basePath}/deviceEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param equipmentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1ListByEquipment(equipmentId: number, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseDevice>;
    public deviceEndpointV1ListByEquipment(equipmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseDevice>>;
    public deviceEndpointV1ListByEquipment(equipmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseDevice>>;
    public deviceEndpointV1ListByEquipment(equipmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (equipmentId === null || equipmentId === undefined) {
            throw new Error('Required parameter equipmentId was null or undefined when calling deviceEndpointV1ListByEquipment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (equipmentId !== undefined && equipmentId !== null) {
            queryParameters = queryParameters.set('equipmentId', <any>equipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseDevice>(`${this.basePath}/deviceEndpoint/v1/listByEquipment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param imei
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1ListByIMEI(imei: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseDevice>;
    public deviceEndpointV1ListByIMEI(imei: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseDevice>>;
    public deviceEndpointV1ListByIMEI(imei: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseDevice>>;
    public deviceEndpointV1ListByIMEI(imei: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling deviceEndpointV1ListByIMEI.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (imei !== undefined && imei !== null) {
            queryParameters = queryParameters.set('imei', <any>imei);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseDevice>(`${this.basePath}/deviceEndpoint/v1/listByIMEI`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1ListCarTypes(observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseInterfaceValues>;
    public deviceEndpointV1ListCarTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseInterfaceValues>>;
    public deviceEndpointV1ListCarTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseInterfaceValues>>;
    public deviceEndpointV1ListCarTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseInterfaceValues>(`${this.basePath}/deviceEndpoint/v1/listCarTypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1ListDeviceIcons(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseDeviceIcon>;
    public deviceEndpointV1ListDeviceIcons(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseDeviceIcon>>;
    public deviceEndpointV1ListDeviceIcons(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseDeviceIcon>>;
    public deviceEndpointV1ListDeviceIcons(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling deviceEndpointV1ListDeviceIcons.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseDeviceIcon>(`${this.basePath}/deviceEndpoint/v1/listDeviceIcons`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1Register(body?: RegisterDeviceRequest, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public deviceEndpointV1Register(body?: RegisterDeviceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public deviceEndpointV1Register(body?: RegisterDeviceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public deviceEndpointV1Register(body?: RegisterDeviceRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Device>(`${this.basePath}/deviceEndpoint/v1/register`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1Remove(deviceId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deviceEndpointV1Remove(deviceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deviceEndpointV1Remove(deviceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deviceEndpointV1Remove(deviceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling deviceEndpointV1Remove.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/deviceEndpoint/v1/remove`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param loginId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1RemoveLoginId(loginId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deviceEndpointV1RemoveLoginId(loginId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deviceEndpointV1RemoveLoginId(loginId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deviceEndpointV1RemoveLoginId(loginId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginId === null || loginId === undefined) {
            throw new Error('Required parameter loginId was null or undefined when calling deviceEndpointV1RemoveLoginId.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loginId !== undefined && loginId !== null) {
            queryParameters = queryParameters.set('login_id', <any>loginId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/deviceEndpoint/v1/removeLoginId`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param hostId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1Save(hostId?: number, body?: Device, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public deviceEndpointV1Save(hostId?: number, body?: Device, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public deviceEndpointV1Save(hostId?: number, body?: Device, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public deviceEndpointV1Save(hostId?: number, body?: Device, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('host_id', <any>hostId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Device>(`${this.basePath}/deviceEndpoint/v1/save`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1SaveDeviceIcon(body?: DeviceIcon, observe?: 'body', reportProgress?: boolean): Observable<DeviceIcon>;
    public deviceEndpointV1SaveDeviceIcon(body?: DeviceIcon, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceIcon>>;
    public deviceEndpointV1SaveDeviceIcon(body?: DeviceIcon, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceIcon>>;
    public deviceEndpointV1SaveDeviceIcon(body?: DeviceIcon, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DeviceIcon>(`${this.basePath}/deviceEndpoint/v1/saveDeviceIcon`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param imei
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceEndpointV1ValidateIMEI(imei: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deviceEndpointV1ValidateIMEI(imei: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deviceEndpointV1ValidateIMEI(imei: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deviceEndpointV1ValidateIMEI(imei: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling deviceEndpointV1ValidateIMEI.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (imei !== undefined && imei !== null) {
            queryParameters = queryParameters.set('imei', <any>imei);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/deviceEndpoint/v1/validate/imei`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentEndpointV1CheckDueDate(body?: Device, observe?: 'body', reportProgress?: boolean): Observable<Device>;
    public equipmentEndpointV1CheckDueDate(body?: Device, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Device>>;
    public equipmentEndpointV1CheckDueDate(body?: Device, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Device>>;
    public equipmentEndpointV1CheckDueDate(body?: Device, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<Device>(`${this.basePath}/equipmentEndpoint/v1/checkDueDate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param imei
     * @param protocol
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentEndpointV1Create(body?: Equipment, imei?: string, protocol?: string, observe?: 'body', reportProgress?: boolean): Observable<Equipment>;
    public equipmentEndpointV1Create(body?: Equipment, imei?: string, protocol?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Equipment>>;
    public equipmentEndpointV1Create(body?: Equipment, imei?: string, protocol?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Equipment>>;
    public equipmentEndpointV1Create(body?: Equipment, imei?: string, protocol?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (imei !== undefined && imei !== null) {
            queryParameters = queryParameters.set('imei', <any>imei);
        }
        if (protocol !== undefined && protocol !== null) {
            queryParameters = queryParameters.set('protocol', <any>protocol);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Equipment>(`${this.basePath}/equipmentEndpoint/v1/create`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param imei
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentEndpointV1FindDuplicate(imei: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public equipmentEndpointV1FindDuplicate(imei: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public equipmentEndpointV1FindDuplicate(imei: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public equipmentEndpointV1FindDuplicate(imei: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling equipmentEndpointV1FindDuplicate.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (imei !== undefined && imei !== null) {
            queryParameters = queryParameters.set('imei', <any>imei);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/equipmentEndpoint/v1/findDuplicate`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param imei
     * @param protocol
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentEndpointV1Get(id?: number, imei?: string, protocol?: string, observe?: 'body', reportProgress?: boolean): Observable<Equipment>;
    public equipmentEndpointV1Get(id?: number, imei?: string, protocol?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Equipment>>;
    public equipmentEndpointV1Get(id?: number, imei?: string, protocol?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Equipment>>;
    public equipmentEndpointV1Get(id?: number, imei?: string, protocol?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (imei !== undefined && imei !== null) {
            queryParameters = queryParameters.set('imei', <any>imei);
        }
        if (protocol !== undefined && protocol !== null) {
            queryParameters = queryParameters.set('protocol', <any>protocol);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Equipment>(`${this.basePath}/equipmentEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentEndpointV1List(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseEquipment>;
    public equipmentEndpointV1List(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseEquipment>>;
    public equipmentEndpointV1List(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseEquipment>>;
    public equipmentEndpointV1List(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling equipmentEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseEquipment>(`${this.basePath}/equipmentEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param equipmentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentEndpointV1Remove(equipmentId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public equipmentEndpointV1Remove(equipmentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public equipmentEndpointV1Remove(equipmentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public equipmentEndpointV1Remove(equipmentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (equipmentId === null || equipmentId === undefined) {
            throw new Error('Required parameter equipmentId was null or undefined when calling equipmentEndpointV1Remove.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (equipmentId !== undefined && equipmentId !== null) {
            queryParameters = queryParameters.set('equipmentId', <any>equipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/equipmentEndpoint/v1/remove`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public equipmentEndpointV1Update(body?: Equipment, observe?: 'body', reportProgress?: boolean): Observable<Equipment>;
    public equipmentEndpointV1Update(body?: Equipment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Equipment>>;
    public equipmentEndpointV1Update(body?: Equipment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Equipment>>;
    public equipmentEndpointV1Update(body?: Equipment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Equipment>(`${this.basePath}/equipmentEndpoint/v1/update`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public featureEndpointV1Delete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public featureEndpointV1Delete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public featureEndpointV1Delete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public featureEndpointV1Delete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling featureEndpointV1Delete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/featureEndpoint/v1/delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public featureEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Feature>;
    public featureEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Feature>>;
    public featureEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Feature>>;
    public featureEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling featureEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Feature>(`${this.basePath}/featureEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public featureEndpointV1List(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CategoryFeaturesPayloadCollection>;
    public featureEndpointV1List(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoryFeaturesPayloadCollection>>;
    public featureEndpointV1List(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoryFeaturesPayloadCollection>>;
    public featureEndpointV1List(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling featureEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CategoryFeaturesPayloadCollection>(`${this.basePath}/featureEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public featureEndpointV1ListAll(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseFeature>;
    public featureEndpointV1ListAll(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseFeature>>;
    public featureEndpointV1ListAll(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseFeature>>;
    public featureEndpointV1ListAll(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling featureEndpointV1ListAll.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseFeature>(`${this.basePath}/featureEndpoint/v1/listAll`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public featureEndpointV1Save(body?: Feature, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public featureEndpointV1Save(body?: Feature, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public featureEndpointV1Save(body?: Feature, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public featureEndpointV1Save(body?: Feature, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/featureEndpoint/v1/create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param hostId
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public featureEndpointV1SearchFeatures(hostId: number, limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CategoryFeaturesPayloadCollection>;
    public featureEndpointV1SearchFeatures(hostId: number, limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CategoryFeaturesPayloadCollection>>;
    public featureEndpointV1SearchFeatures(hostId: number, limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CategoryFeaturesPayloadCollection>>;
    public featureEndpointV1SearchFeatures(hostId: number, limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (hostId === null || hostId === undefined) {
            throw new Error('Required parameter hostId was null or undefined when calling featureEndpointV1SearchFeatures.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling featureEndpointV1SearchFeatures.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('hostId', <any>hostId);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CategoryFeaturesPayloadCollection>(`${this.basePath}/featureEndpoint/v1/searchFeatures`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param featureId
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public featureEndpointV1SearchHosts(featureId: number, limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<FeatureCategoryHostsPayload>;
    public featureEndpointV1SearchHosts(featureId: number, limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FeatureCategoryHostsPayload>>;
    public featureEndpointV1SearchHosts(featureId: number, limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FeatureCategoryHostsPayload>>;
    public featureEndpointV1SearchHosts(featureId: number, limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (featureId === null || featureId === undefined) {
            throw new Error('Required parameter featureId was null or undefined when calling featureEndpointV1SearchHosts.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling featureEndpointV1SearchHosts.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (featureId !== undefined && featureId !== null) {
            queryParameters = queryParameters.set('featureId', <any>featureId);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<FeatureCategoryHostsPayload>(`${this.basePath}/featureEndpoint/v1/searchHosts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param equipmentId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1Create(equipmentId: number, body?: GPS, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public gpsEndpointV1Create(equipmentId: number, body?: GPS, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public gpsEndpointV1Create(equipmentId: number, body?: GPS, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public gpsEndpointV1Create(equipmentId: number, body?: GPS, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (equipmentId === null || equipmentId === undefined) {
            throw new Error('Required parameter equipmentId was null or undefined when calling gpsEndpointV1Create.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (equipmentId !== undefined && equipmentId !== null) {
            queryParameters = queryParameters.set('equipment_id', <any>equipmentId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/gpsEndpoint/v1/create`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param dataStart
     * @param dataEnd
     * @param limit
     * @param alarmType
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1GetAlert(deviceId: number, dataStart: Date, dataEnd: Date, limit: number, alarmType?: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseGPS>;
    public gpsEndpointV1GetAlert(deviceId: number, dataStart: Date, dataEnd: Date, limit: number, alarmType?: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseGPS>>;
    public gpsEndpointV1GetAlert(deviceId: number, dataStart: Date, dataEnd: Date, limit: number, alarmType?: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseGPS>>;
    public gpsEndpointV1GetAlert(deviceId: number, dataStart: Date, dataEnd: Date, limit: number, alarmType?: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling gpsEndpointV1GetAlert.');
        }

        if (dataStart === null || dataStart === undefined) {
            throw new Error('Required parameter dataStart was null or undefined when calling gpsEndpointV1GetAlert.');
        }

        if (dataEnd === null || dataEnd === undefined) {
            throw new Error('Required parameter dataEnd was null or undefined when calling gpsEndpointV1GetAlert.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling gpsEndpointV1GetAlert.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (alarmType !== undefined && alarmType !== null) {
            queryParameters = queryParameters.set('alarm_type', <any>alarmType);
        }
        if (dataStart !== undefined && dataStart !== null) {
            queryParameters = queryParameters.set('data_start', <any>dataStart.toISOString());
        }
        if (dataEnd !== undefined && dataEnd !== null) {
            queryParameters = queryParameters.set('data_end', <any>dataEnd.toISOString());
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseGPS>(`${this.basePath}/gpsEndpoint/v1/getAlert`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1GetAll(observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseGPS>;
    public gpsEndpointV1GetAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseGPS>>;
    public gpsEndpointV1GetAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseGPS>>;
    public gpsEndpointV1GetAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseGPS>(`${this.basePath}/gpsEndpoint/v1/getAll`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param dateStart
     * @param dateEnd
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1GetDistance(deviceId: number, dateStart: Date, dateEnd: Date, observe?: 'body', reportProgress?: boolean): Observable<DistancePayload>;
    public gpsEndpointV1GetDistance(deviceId: number, dateStart: Date, dateEnd: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DistancePayload>>;
    public gpsEndpointV1GetDistance(deviceId: number, dateStart: Date, dateEnd: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DistancePayload>>;
    public gpsEndpointV1GetDistance(deviceId: number, dateStart: Date, dateEnd: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling gpsEndpointV1GetDistance.');
        }

        if (dateStart === null || dateStart === undefined) {
            throw new Error('Required parameter dateStart was null or undefined when calling gpsEndpointV1GetDistance.');
        }

        if (dateEnd === null || dateEnd === undefined) {
            throw new Error('Required parameter dateEnd was null or undefined when calling gpsEndpointV1GetDistance.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (dateStart !== undefined && dateStart !== null) {
            queryParameters = queryParameters.set('date_start', <any>dateStart.toISOString());
        }
        if (dateEnd !== undefined && dateEnd !== null) {
            queryParameters = queryParameters.set('date_end', <any>dateEnd.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<DistancePayload>(`${this.basePath}/gpsEndpoint/v1/getDistance`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1GetGPS(deviceId: number, observe?: 'body', reportProgress?: boolean): Observable<GPS>;
    public gpsEndpointV1GetGPS(deviceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GPS>>;
    public gpsEndpointV1GetGPS(deviceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GPS>>;
    public gpsEndpointV1GetGPS(deviceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling gpsEndpointV1GetGPS.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GPS>(`${this.basePath}/gpsEndpoint/v1/getGPS`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param dateStart
     * @param dateEnd
     * @param limit
     * @param valid
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1GetHistory(deviceId: number, dateStart: Date, dateEnd: Date, limit: number, valid?: boolean, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseGPS>;
    public gpsEndpointV1GetHistory(deviceId: number, dateStart: Date, dateEnd: Date, limit: number, valid?: boolean, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseGPS>>;
    public gpsEndpointV1GetHistory(deviceId: number, dateStart: Date, dateEnd: Date, limit: number, valid?: boolean, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseGPS>>;
    public gpsEndpointV1GetHistory(deviceId: number, dateStart: Date, dateEnd: Date, limit: number, valid?: boolean, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling gpsEndpointV1GetHistory.');
        }

        if (dateStart === null || dateStart === undefined) {
            throw new Error('Required parameter dateStart was null or undefined when calling gpsEndpointV1GetHistory.');
        }

        if (dateEnd === null || dateEnd === undefined) {
            throw new Error('Required parameter dateEnd was null or undefined when calling gpsEndpointV1GetHistory.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling gpsEndpointV1GetHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (dateStart !== undefined && dateStart !== null) {
            queryParameters = queryParameters.set('date_start', <any>dateStart.toISOString());
        }
        if (dateEnd !== undefined && dateEnd !== null) {
            queryParameters = queryParameters.set('date_end', <any>dateEnd.toISOString());
        }
        if (valid !== undefined && valid !== null) {
            queryParameters = queryParameters.set('valid', <any>valid);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseGPS>(`${this.basePath}/gpsEndpoint/v1/getHistory`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param dateStart
     * @param dateEnd
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1GetHistoryAcc(deviceId: number, dateStart: Date, dateEnd: Date, limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseGPS>;
    public gpsEndpointV1GetHistoryAcc(deviceId: number, dateStart: Date, dateEnd: Date, limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseGPS>>;
    public gpsEndpointV1GetHistoryAcc(deviceId: number, dateStart: Date, dateEnd: Date, limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseGPS>>;
    public gpsEndpointV1GetHistoryAcc(deviceId: number, dateStart: Date, dateEnd: Date, limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling gpsEndpointV1GetHistoryAcc.');
        }

        if (dateStart === null || dateStart === undefined) {
            throw new Error('Required parameter dateStart was null or undefined when calling gpsEndpointV1GetHistoryAcc.');
        }

        if (dateEnd === null || dateEnd === undefined) {
            throw new Error('Required parameter dateEnd was null or undefined when calling gpsEndpointV1GetHistoryAcc.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling gpsEndpointV1GetHistoryAcc.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (dateStart !== undefined && dateStart !== null) {
            queryParameters = queryParameters.set('date_start', <any>dateStart.toISOString());
        }
        if (dateEnd !== undefined && dateEnd !== null) {
            queryParameters = queryParameters.set('date_end', <any>dateEnd.toISOString());
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseGPS>(`${this.basePath}/gpsEndpoint/v1/getHistoryACC`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param dateStart
     * @param dateEnd
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1GetProfile(deviceId: number, dateStart: Date, dateEnd: Date, observe?: 'body', reportProgress?: boolean): Observable<ProfilePayload>;
    public gpsEndpointV1GetProfile(deviceId: number, dateStart: Date, dateEnd: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfilePayload>>;
    public gpsEndpointV1GetProfile(deviceId: number, dateStart: Date, dateEnd: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfilePayload>>;
    public gpsEndpointV1GetProfile(deviceId: number, dateStart: Date, dateEnd: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling gpsEndpointV1GetProfile.');
        }

        if (dateStart === null || dateStart === undefined) {
            throw new Error('Required parameter dateStart was null or undefined when calling gpsEndpointV1GetProfile.');
        }

        if (dateEnd === null || dateEnd === undefined) {
            throw new Error('Required parameter dateEnd was null or undefined when calling gpsEndpointV1GetProfile.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (dateStart !== undefined && dateStart !== null) {
            queryParameters = queryParameters.set('date_start', <any>dateStart.toISOString());
        }
        if (dateEnd !== undefined && dateEnd !== null) {
            queryParameters = queryParameters.set('date_end', <any>dateEnd.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ProfilePayload>(`${this.basePath}/gpsEndpoint/v1/getProfile`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1ListAll(observe?: 'body', reportProgress?: boolean): Observable<GPSPayloadCollection>;
    public gpsEndpointV1ListAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GPSPayloadCollection>>;
    public gpsEndpointV1ListAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GPSPayloadCollection>>;
    public gpsEndpointV1ListAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<GPSPayloadCollection>(`${this.basePath}/gpsEndpoint/v1/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param days
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1RemoveBefore(days: number, limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<StringPayload>;
    public gpsEndpointV1RemoveBefore(days: number, limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StringPayload>>;
    public gpsEndpointV1RemoveBefore(days: number, limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StringPayload>>;
    public gpsEndpointV1RemoveBefore(days: number, limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (days === null || days === undefined) {
            throw new Error('Required parameter days was null or undefined when calling gpsEndpointV1RemoveBefore.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling gpsEndpointV1RemoveBefore.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (days !== undefined && days !== null) {
            queryParameters = queryParameters.set('days', <any>days);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<StringPayload>(`${this.basePath}/gpsEndpoint/v1/removeBefore`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param days
     * @param imei
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1RemoveBeforeIMEI(days: number, imei: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public gpsEndpointV1RemoveBeforeIMEI(days: number, imei: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public gpsEndpointV1RemoveBeforeIMEI(days: number, imei: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public gpsEndpointV1RemoveBeforeIMEI(days: number, imei: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (days === null || days === undefined) {
            throw new Error('Required parameter days was null or undefined when calling gpsEndpointV1RemoveBeforeIMEI.');
        }

        if (imei === null || imei === undefined) {
            throw new Error('Required parameter imei was null or undefined when calling gpsEndpointV1RemoveBeforeIMEI.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (days !== undefined && days !== null) {
            queryParameters = queryParameters.set('days', <any>days);
        }
        if (imei !== undefined && imei !== null) {
            queryParameters = queryParameters.set('imei', <any>imei);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/gpsEndpoint/v1/removeBeforeIMEI`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param dateStart
     * @param dateEnd
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public gpsEndpointV1RemovePeriod(deviceId: number, dateStart: Date, dateEnd: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public gpsEndpointV1RemovePeriod(deviceId: number, dateStart: Date, dateEnd: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public gpsEndpointV1RemovePeriod(deviceId: number, dateStart: Date, dateEnd: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public gpsEndpointV1RemovePeriod(deviceId: number, dateStart: Date, dateEnd: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling gpsEndpointV1RemovePeriod.');
        }

        if (dateStart === null || dateStart === undefined) {
            throw new Error('Required parameter dateStart was null or undefined when calling gpsEndpointV1RemovePeriod.');
        }

        if (dateEnd === null || dateEnd === undefined) {
            throw new Error('Required parameter dateEnd was null or undefined when calling gpsEndpointV1RemovePeriod.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (dateStart !== undefined && dateStart !== null) {
            queryParameters = queryParameters.set('date_start', <any>dateStart.toISOString());
        }
        if (dateEnd !== undefined && dateEnd !== null) {
            queryParameters = queryParameters.set('date_end', <any>dateEnd.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/gpsEndpoint/v1/removePeriod`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public homeEndpointV1List(observe?: 'body', reportProgress?: boolean): Observable<HomePayload>;
    public homeEndpointV1List(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HomePayload>>;
    public homeEndpointV1List(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HomePayload>>;
    public homeEndpointV1List(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<HomePayload>(`${this.basePath}/homeEndpoint/v1/load`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hostEndpointV1Create(body?: Host, observe?: 'body', reportProgress?: boolean): Observable<Host>;
    public hostEndpointV1Create(body?: Host, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Host>>;
    public hostEndpointV1Create(body?: Host, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Host>>;
    public hostEndpointV1Create(body?: Host, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Host>(`${this.basePath}/hostEndpoint/v1/create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hostEndpointV1Device(deviceId: number, observe?: 'body', reportProgress?: boolean): Observable<Host>;
    public hostEndpointV1Device(deviceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Host>>;
    public hostEndpointV1Device(deviceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Host>>;
    public hostEndpointV1Device(deviceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling hostEndpointV1Device.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Host>(`${this.basePath}/hostEndpoint/v1/device`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hostEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Host>;
    public hostEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Host>>;
    public hostEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Host>>;
    public hostEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling hostEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Host>(`${this.basePath}/hostEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hostEndpointV1List(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseHost>;
    public hostEndpointV1List(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseHost>>;
    public hostEndpointV1List(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseHost>>;
    public hostEndpointV1List(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling hostEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseHost>(`${this.basePath}/hostEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hostEndpointV1ListAll(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseHost>;
    public hostEndpointV1ListAll(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseHost>>;
    public hostEndpointV1ListAll(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseHost>>;
    public hostEndpointV1ListAll(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling hostEndpointV1ListAll.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseHost>(`${this.basePath}/hostEndpoint/v1/listAll`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hostEndpointV1MakeForm(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<ScreenValidation>;
    public hostEndpointV1MakeForm(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ScreenValidation>>;
    public hostEndpointV1MakeForm(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ScreenValidation>>;
    public hostEndpointV1MakeForm(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling hostEndpointV1MakeForm.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ScreenValidation>(`${this.basePath}/hostEndpoint/v1/makeForm`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hostEndpointV1Update(body?: Host, observe?: 'body', reportProgress?: boolean): Observable<Host>;
    public hostEndpointV1Update(body?: Host, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Host>>;
    public hostEndpointV1Update(body?: Host, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Host>>;
    public hostEndpointV1Update(body?: Host, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Host>(`${this.basePath}/hostEndpoint/v1/update`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoiceEndpointV1Current(observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public invoiceEndpointV1Current(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public invoiceEndpointV1Current(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public invoiceEndpointV1Current(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Invoice>(`${this.basePath}/invoiceEndpoint/v1/current`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param invoiceId
     * @param orderId
     * @param contractId
     * @param before
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoiceEndpointV1DeleteOrder(invoiceId: number, orderId?: number, contractId?: number, before?: Date, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public invoiceEndpointV1DeleteOrder(invoiceId: number, orderId?: number, contractId?: number, before?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public invoiceEndpointV1DeleteOrder(invoiceId: number, orderId?: number, contractId?: number, before?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public invoiceEndpointV1DeleteOrder(invoiceId: number, orderId?: number, contractId?: number, before?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling invoiceEndpointV1DeleteOrder.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoiceId !== undefined && invoiceId !== null) {
            queryParameters = queryParameters.set('invoiceId', <any>invoiceId);
        }
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (contractId !== undefined && contractId !== null) {
            queryParameters = queryParameters.set('contractId', <any>contractId);
        }
        if (before !== undefined && before !== null) {
            queryParameters = queryParameters.set('before', <any>before.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<Invoice>(`${this.basePath}/invoiceEndpoint/v1/deleteOrder`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoiceEndpointV1Details(id: number, observe?: 'body', reportProgress?: boolean): Observable<InvoiceDetailsPayload>;
    public invoiceEndpointV1Details(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InvoiceDetailsPayload>>;
    public invoiceEndpointV1Details(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InvoiceDetailsPayload>>;
    public invoiceEndpointV1Details(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling invoiceEndpointV1Details.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<InvoiceDetailsPayload>(`${this.basePath}/invoiceEndpoint/v1/details`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param loginId
     * @param hostId
     * @param title
     * @param description
     * @param amount
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoiceEndpointV1Discount(loginId: number, hostId: number, title: string, description: string, amount: number, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public invoiceEndpointV1Discount(loginId: number, hostId: number, title: string, description: string, amount: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public invoiceEndpointV1Discount(loginId: number, hostId: number, title: string, description: string, amount: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public invoiceEndpointV1Discount(loginId: number, hostId: number, title: string, description: string, amount: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (loginId === null || loginId === undefined) {
            throw new Error('Required parameter loginId was null or undefined when calling invoiceEndpointV1Discount.');
        }

        if (hostId === null || hostId === undefined) {
            throw new Error('Required parameter hostId was null or undefined when calling invoiceEndpointV1Discount.');
        }

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling invoiceEndpointV1Discount.');
        }

        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling invoiceEndpointV1Discount.');
        }

        if (amount === null || amount === undefined) {
            throw new Error('Required parameter amount was null or undefined when calling invoiceEndpointV1Discount.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loginId !== undefined && loginId !== null) {
            queryParameters = queryParameters.set('loginId', <any>loginId);
        }
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('hostId', <any>hostId);
        }
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (amount !== undefined && amount !== null) {
            queryParameters = queryParameters.set('amount', <any>amount);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Invoice>(`${this.basePath}/invoiceEndpoint/v1/discount`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoiceEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public invoiceEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public invoiceEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public invoiceEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling invoiceEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Invoice>(`${this.basePath}/invoiceEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoiceEndpointV1List(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseInvoice>;
    public invoiceEndpointV1List(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseInvoice>>;
    public invoiceEndpointV1List(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseInvoice>>;
    public invoiceEndpointV1List(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling invoiceEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseInvoice>(`${this.basePath}/invoiceEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoiceEndpointV1ListAll(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseInvoice>;
    public invoiceEndpointV1ListAll(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseInvoice>>;
    public invoiceEndpointV1ListAll(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseInvoice>>;
    public invoiceEndpointV1ListAll(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling invoiceEndpointV1ListAll.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseInvoice>(`${this.basePath}/invoiceEndpoint/v1/listAll`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param invoiceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invoiceEndpointV1Recalc(invoiceId: number, observe?: 'body', reportProgress?: boolean): Observable<Invoice>;
    public invoiceEndpointV1Recalc(invoiceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invoice>>;
    public invoiceEndpointV1Recalc(invoiceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invoice>>;
    public invoiceEndpointV1Recalc(invoiceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling invoiceEndpointV1Recalc.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoiceId !== undefined && invoiceId !== null) {
            queryParameters = queryParameters.set('invoice_id', <any>invoiceId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Invoice>(`${this.basePath}/invoiceEndpoint/v1/recalc`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param imageId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kodakEndpointV1Delete(imageId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public kodakEndpointV1Delete(imageId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public kodakEndpointV1Delete(imageId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public kodakEndpointV1Delete(imageId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (imageId !== undefined && imageId !== null) {
            queryParameters = queryParameters.set('image_id', <any>imageId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/kodakEndpoint/v1/delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param imageId
     * @param size
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kodakEndpointV1GetImageUrl(imageId: string, size?: number, observe?: 'body', reportProgress?: boolean): Observable<StringPayload>;
    public kodakEndpointV1GetImageUrl(imageId: string, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StringPayload>>;
    public kodakEndpointV1GetImageUrl(imageId: string, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StringPayload>>;
    public kodakEndpointV1GetImageUrl(imageId: string, size?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (imageId === null || imageId === undefined) {
            throw new Error('Required parameter imageId was null or undefined when calling kodakEndpointV1GetImageUrl.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (imageId !== undefined && imageId !== null) {
            queryParameters = queryParameters.set('image_id', <any>imageId);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<StringPayload>(`${this.basePath}/kodakEndpoint/v1/imageUrl`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kodakEndpointV1Upload(observe?: 'body', reportProgress?: boolean): Observable<StringPayload>;
    public kodakEndpointV1Upload(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StringPayload>>;
    public kodakEndpointV1Upload(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StringPayload>>;
    public kodakEndpointV1Upload(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<StringPayload>(`${this.basePath}/kodakEndpoint/v1/uploadUrl`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param referenceId
     * @param topic
     * @param action
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logEndpointV1List(limit: number, referenceId?: number, topic?: string, action?: string, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseLog>;
    public logEndpointV1List(limit: number, referenceId?: number, topic?: string, action?: string, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseLog>>;
    public logEndpointV1List(limit: number, referenceId?: number, topic?: string, action?: string, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseLog>>;
    public logEndpointV1List(limit: number, referenceId?: number, topic?: string, action?: string, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling logEndpointV1List.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (referenceId !== undefined && referenceId !== null) {
            queryParameters = queryParameters.set('referenceId', <any>referenceId);
        }
        if (topic !== undefined && topic !== null) {
            queryParameters = queryParameters.set('topic', <any>topic);
        }
        if (action !== undefined && action !== null) {
            queryParameters = queryParameters.set('action', <any>action);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseLog>(`${this.basePath}/logEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1AddStatus(body?: Status, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public paymentEndpointV1AddStatus(body?: Status, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public paymentEndpointV1AddStatus(body?: Status, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public paymentEndpointV1AddStatus(body?: Status, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/paymentEndpoint/v1/status`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1Confirm(body?: Payment, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public paymentEndpointV1Confirm(body?: Payment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public paymentEndpointV1Confirm(body?: Payment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public paymentEndpointV1Confirm(body?: Payment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/paymentEndpoint/v1/confirm`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param invoiceId
     * @param deviceId
     * @param plainId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1Create(invoiceId?: number, deviceId?: number, plainId?: number, observe?: 'body', reportProgress?: boolean): Observable<Payment>;
    public paymentEndpointV1Create(invoiceId?: number, deviceId?: number, plainId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Payment>>;
    public paymentEndpointV1Create(invoiceId?: number, deviceId?: number, plainId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Payment>>;
    public paymentEndpointV1Create(invoiceId?: number, deviceId?: number, plainId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoiceId !== undefined && invoiceId !== null) {
            queryParameters = queryParameters.set('invoice_id', <any>invoiceId);
        }
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (plainId !== undefined && plainId !== null) {
            queryParameters = queryParameters.set('plain_id', <any>plainId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Payment>(`${this.basePath}/paymentEndpoint/v1/create`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param invoiceId
     * @param deviceId
     * @param plainId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1CreateInvoice(invoiceId?: number, deviceId?: number, plainId?: number, observe?: 'body', reportProgress?: boolean): Observable<Payment>;
    public paymentEndpointV1CreateInvoice(invoiceId?: number, deviceId?: number, plainId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Payment>>;
    public paymentEndpointV1CreateInvoice(invoiceId?: number, deviceId?: number, plainId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Payment>>;
    public paymentEndpointV1CreateInvoice(invoiceId?: number, deviceId?: number, plainId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (invoiceId !== undefined && invoiceId !== null) {
            queryParameters = queryParameters.set('invoice_id', <any>invoiceId);
        }
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (plainId !== undefined && plainId !== null) {
            queryParameters = queryParameters.set('plain_id', <any>plainId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Payment>(`${this.basePath}/paymentEndpoint/v1/createInvoice`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1CreateRaw(body?: Payment, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public paymentEndpointV1CreateRaw(body?: Payment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public paymentEndpointV1CreateRaw(body?: Payment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public paymentEndpointV1CreateRaw(body?: Payment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/paymentEndpoint/v1/createRaw`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param deviceId
     * @param category
     * @param confirmed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1GetLast(deviceId: number, category?: number, confirmed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Payment>;
    public paymentEndpointV1GetLast(deviceId: number, category?: number, confirmed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Payment>>;
    public paymentEndpointV1GetLast(deviceId: number, category?: number, confirmed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Payment>>;
    public paymentEndpointV1GetLast(deviceId: number, category?: number, confirmed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling paymentEndpointV1GetLast.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (category !== undefined && category !== null) {
            queryParameters = queryParameters.set('category', <any>category);
        }
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (confirmed !== undefined && confirmed !== null) {
            queryParameters = queryParameters.set('confirmed', <any>confirmed);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Payment>(`${this.basePath}/paymentEndpoint/v1/getLast`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1GetPayment(id: number, observe?: 'body', reportProgress?: boolean): Observable<Payment>;
    public paymentEndpointV1GetPayment(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Payment>>;
    public paymentEndpointV1GetPayment(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Payment>>;
    public paymentEndpointV1GetPayment(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling paymentEndpointV1GetPayment.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Payment>(`${this.basePath}/paymentEndpoint/v1/getPayment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1GetPaymentStatus(observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseInterfaceValues>;
    public paymentEndpointV1GetPaymentStatus(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseInterfaceValues>>;
    public paymentEndpointV1GetPaymentStatus(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseInterfaceValues>>;
    public paymentEndpointV1GetPaymentStatus(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseInterfaceValues>(`${this.basePath}/paymentEndpoint/v1/getPaymentStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param deviceId
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1List(limit: number, deviceId?: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponsePayment>;
    public paymentEndpointV1List(limit: number, deviceId?: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponsePayment>>;
    public paymentEndpointV1List(limit: number, deviceId?: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponsePayment>>;
    public paymentEndpointV1List(limit: number, deviceId?: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling paymentEndpointV1List.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('device_id', <any>deviceId);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponsePayment>(`${this.basePath}/paymentEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1ListOverDue(observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseOverDueDevice>;
    public paymentEndpointV1ListOverDue(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseOverDueDevice>>;
    public paymentEndpointV1ListOverDue(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseOverDueDevice>>;
    public paymentEndpointV1ListOverDue(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseOverDueDevice>(`${this.basePath}/paymentEndpoint/v1/listOverDue`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param paymentId
     * @param invoiceId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1Process(paymentId: number, invoiceId?: number, observe?: 'body', reportProgress?: boolean): Observable<Payment>;
    public paymentEndpointV1Process(paymentId: number, invoiceId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Payment>>;
    public paymentEndpointV1Process(paymentId: number, invoiceId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Payment>>;
    public paymentEndpointV1Process(paymentId: number, invoiceId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentId === null || paymentId === undefined) {
            throw new Error('Required parameter paymentId was null or undefined when calling paymentEndpointV1Process.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (paymentId !== undefined && paymentId !== null) {
            queryParameters = queryParameters.set('payment_id', <any>paymentId);
        }
        if (invoiceId !== undefined && invoiceId !== null) {
            queryParameters = queryParameters.set('invoice_id', <any>invoiceId);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Payment>(`${this.basePath}/paymentEndpoint/v1/process`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentEndpointV1Update(body?: Payment, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public paymentEndpointV1Update(body?: Payment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public paymentEndpointV1Update(body?: Payment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public paymentEndpointV1Update(body?: Payment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/paymentEndpoint/v1/update`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plainEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Plain>;
    public plainEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Plain>>;
    public plainEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Plain>>;
    public plainEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling plainEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Plain>(`${this.basePath}/plainEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param category
     * @param active
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plainEndpointV1List(limit: number, category?: number, active?: boolean, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponsePlain>;
    public plainEndpointV1List(limit: number, category?: number, active?: boolean, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponsePlain>>;
    public plainEndpointV1List(limit: number, category?: number, active?: boolean, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponsePlain>>;
    public plainEndpointV1List(limit: number, category?: number, active?: boolean, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling plainEndpointV1List.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (category !== undefined && category !== null) {
            queryParameters = queryParameters.set('category', <any>category);
        }
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', <any>active);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponsePlain>(`${this.basePath}/plainEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plainEndpointV1ListCategories(observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseInterfaceValues>;
    public plainEndpointV1ListCategories(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseInterfaceValues>>;
    public plainEndpointV1ListCategories(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseInterfaceValues>>;
    public plainEndpointV1ListCategories(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseInterfaceValues>(`${this.basePath}/plainEndpoint/v1/listCategories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plainEndpointV1ListFactors(observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseInterfaceValues>;
    public plainEndpointV1ListFactors(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseInterfaceValues>>;
    public plainEndpointV1ListFactors(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseInterfaceValues>>;
    public plainEndpointV1ListFactors(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseInterfaceValues>(`${this.basePath}/plainEndpoint/v1/listFactors`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param featureId
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plainEndpointV1ListFeature(featureId: number, limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponsePlain>;
    public plainEndpointV1ListFeature(featureId: number, limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponsePlain>>;
    public plainEndpointV1ListFeature(featureId: number, limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponsePlain>>;
    public plainEndpointV1ListFeature(featureId: number, limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (featureId === null || featureId === undefined) {
            throw new Error('Required parameter featureId was null or undefined when calling plainEndpointV1ListFeature.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling plainEndpointV1ListFeature.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (featureId !== undefined && featureId !== null) {
            queryParameters = queryParameters.set('featureId', <any>featureId);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponsePlain>(`${this.basePath}/plainEndpoint/v1/listFeature`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public plainEndpointV1Save(body?: Plain, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public plainEndpointV1Save(body?: Plain, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public plainEndpointV1Save(body?: Plain, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public plainEndpointV1Save(body?: Plain, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/plainEndpoint/v1/create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productEndpointV1Delete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public productEndpointV1Delete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public productEndpointV1Delete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public productEndpointV1Delete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productEndpointV1Delete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/productEndpoint/v1/delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Product>;
    public productEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Product>>;
    public productEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
    public productEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling productEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Product>(`${this.basePath}/productEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productEndpointV1ListAll(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseProduct>;
    public productEndpointV1ListAll(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseProduct>>;
    public productEndpointV1ListAll(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseProduct>>;
    public productEndpointV1ListAll(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling productEndpointV1ListAll.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseProduct>(`${this.basePath}/productEndpoint/v1/listAll`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productEndpointV1Save(body?: Product, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public productEndpointV1Save(body?: Product, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public productEndpointV1Save(body?: Product, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public productEndpointV1Save(body?: Product, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/productEndpoint/v1/create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public profileEndpointV1Delete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public profileEndpointV1Delete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public profileEndpointV1Delete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public profileEndpointV1Delete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling profileEndpointV1Delete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/profileEndpoint/v1/delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public profileEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Profile>;
    public profileEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Profile>>;
    public profileEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Profile>>;
    public profileEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling profileEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Profile>(`${this.basePath}/profileEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public profileEndpointV1List(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseProfile>;
    public profileEndpointV1List(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseProfile>>;
    public profileEndpointV1List(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseProfile>>;
    public profileEndpointV1List(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling profileEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseProfile>(`${this.basePath}/profileEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public profileEndpointV1Save(body?: Profile, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public profileEndpointV1Save(body?: Profile, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public profileEndpointV1Save(body?: Profile, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public profileEndpointV1Save(body?: Profile, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/profileEndpoint/v1/create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public providerEndpointV1Delete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public providerEndpointV1Delete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public providerEndpointV1Delete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public providerEndpointV1Delete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling providerEndpointV1Delete.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/providerEndpoint/v1/delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public providerEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Provider>;
    public providerEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Provider>>;
    public providerEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Provider>>;
    public providerEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling providerEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Provider>(`${this.basePath}/providerEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public providerEndpointV1List(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseProvider>;
    public providerEndpointV1List(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseProvider>>;
    public providerEndpointV1List(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseProvider>>;
    public providerEndpointV1List(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling providerEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseProvider>(`${this.basePath}/providerEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public providerEndpointV1Save(body?: Provider, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public providerEndpointV1Save(body?: Provider, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public providerEndpointV1Save(body?: Provider, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public providerEndpointV1Save(body?: Provider, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/providerEndpoint/v1/create`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsEndpointV1ListSMSApn(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseApn>;
    public settingsEndpointV1ListSMSApn(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseApn>>;
    public settingsEndpointV1ListSMSApn(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseApn>>;
    public settingsEndpointV1ListSMSApn(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling settingsEndpointV1ListSMSApn.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseApn>(`${this.basePath}/settingsEndpoint/v1/apn/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsEndpointV1ListSMSDevice(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseSMSDevice>;
    public settingsEndpointV1ListSMSDevice(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseSMSDevice>>;
    public settingsEndpointV1ListSMSDevice(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseSMSDevice>>;
    public settingsEndpointV1ListSMSDevice(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling settingsEndpointV1ListSMSDevice.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseSMSDevice>(`${this.basePath}/settingsEndpoint/v1/sms/device/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsEndpointV1ListSMSDeviceApn(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseSMSDevice>;
    public settingsEndpointV1ListSMSDeviceApn(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseSMSDevice>>;
    public settingsEndpointV1ListSMSDeviceApn(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseSMSDevice>>;
    public settingsEndpointV1ListSMSDeviceApn(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling settingsEndpointV1ListSMSDeviceApn.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseSMSDevice>(`${this.basePath}/settingsEndpoint/v1/sms/device/listPlusApn`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsEndpointV1PostSendSMS(body?: SMSSettingsRequest, observe?: 'body', reportProgress?: boolean): Observable<SMSMessageStatusPayloadCollection>;
    public settingsEndpointV1PostSendSMS(body?: SMSSettingsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SMSMessageStatusPayloadCollection>>;
    public settingsEndpointV1PostSendSMS(body?: SMSSettingsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SMSMessageStatusPayloadCollection>>;
    public settingsEndpointV1PostSendSMS(body?: SMSSettingsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SMSMessageStatusPayloadCollection>(`${this.basePath}/settingsEndpoint/v1/sms/device/send`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsEndpointV1SaveSMSApn(body?: Apn, observe?: 'body', reportProgress?: boolean): Observable<Apn>;
    public settingsEndpointV1SaveSMSApn(body?: Apn, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Apn>>;
    public settingsEndpointV1SaveSMSApn(body?: Apn, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Apn>>;
    public settingsEndpointV1SaveSMSApn(body?: Apn, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Apn>(`${this.basePath}/settingsEndpoint/v1/apn/save`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsEndpointV1SaveSMSDevice(body?: SMSDevice, observe?: 'body', reportProgress?: boolean): Observable<SMSDevice>;
    public settingsEndpointV1SaveSMSDevice(body?: SMSDevice, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SMSDevice>>;
    public settingsEndpointV1SaveSMSDevice(body?: SMSDevice, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SMSDevice>>;
    public settingsEndpointV1SaveSMSDevice(body?: SMSDevice, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<SMSDevice>(`${this.basePath}/settingsEndpoint/v1/sms/device/save`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public settingsEndpointV1TestTimezone(observe?: 'body', reportProgress?: boolean): Observable<TimeZonePayload>;
    public settingsEndpointV1TestTimezone(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TimeZonePayload>>;
    public settingsEndpointV1TestTimezone(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TimeZonePayload>>;
    public settingsEndpointV1TestTimezone(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<TimeZonePayload>(`${this.basePath}/settingsEndpoint/v1/testTimezone`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signatureEndpointV1Get(id: number, observe?: 'body', reportProgress?: boolean): Observable<Signature>;
    public signatureEndpointV1Get(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Signature>>;
    public signatureEndpointV1Get(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Signature>>;
    public signatureEndpointV1Get(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling signatureEndpointV1Get.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Signature>(`${this.basePath}/signatureEndpoint/v1/get`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signatureEndpointV1List(limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseSignature>;
    public signatureEndpointV1List(limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseSignature>>;
    public signatureEndpointV1List(limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseSignature>>;
    public signatureEndpointV1List(limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling signatureEndpointV1List.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseSignature>(`${this.basePath}/signatureEndpoint/v1/list`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param categoryId
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signatureEndpointV1ListCategory(categoryId: number, limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseSignature>;
    public signatureEndpointV1ListCategory(categoryId: number, limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseSignature>>;
    public signatureEndpointV1ListCategory(categoryId: number, limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseSignature>>;
    public signatureEndpointV1ListCategory(categoryId: number, limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling signatureEndpointV1ListCategory.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling signatureEndpointV1ListCategory.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (categoryId !== undefined && categoryId !== null) {
            queryParameters = queryParameters.set('category_id', <any>categoryId);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseSignature>(`${this.basePath}/signatureEndpoint/v1/list/category`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param categoryId
     * @param hostId
     * @param limit
     * @param cursor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signatureEndpointV1ListPlains(categoryId: number, hostId: number, limit: number, cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<CollectionResponseSignaturePlainsPayload>;
    public signatureEndpointV1ListPlains(categoryId: number, hostId: number, limit: number, cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CollectionResponseSignaturePlainsPayload>>;
    public signatureEndpointV1ListPlains(categoryId: number, hostId: number, limit: number, cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CollectionResponseSignaturePlainsPayload>>;
    public signatureEndpointV1ListPlains(categoryId: number, hostId: number, limit: number, cursor?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling signatureEndpointV1ListPlains.');
        }

        if (hostId === null || hostId === undefined) {
            throw new Error('Required parameter hostId was null or undefined when calling signatureEndpointV1ListPlains.');
        }

        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling signatureEndpointV1ListPlains.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (categoryId !== undefined && categoryId !== null) {
            queryParameters = queryParameters.set('category_id', <any>categoryId);
        }
        if (hostId !== undefined && hostId !== null) {
            queryParameters = queryParameters.set('host_id', <any>hostId);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (cursor !== undefined && cursor !== null) {
            queryParameters = queryParameters.set('cursor', <any>cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CollectionResponseSignaturePlainsPayload>(`${this.basePath}/signatureEndpoint/v1/list/plains`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public signatureEndpointV1Save(body?: Signature, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public signatureEndpointV1Save(body?: Signature, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public signatureEndpointV1Save(body?: Signature, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public signatureEndpointV1Save(body?: Signature, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (google_id_token-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (google_id_token_https-c6e378a1) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/signatureEndpoint/v1/save`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
