import {AccountCloud} from "app/data/source/cloud/account-cloud.service";
import {LocalData} from "app/data/source/local/local-data";
import "rxjs-compat/add/operator/filter";
import "rxjs-compat/add/operator/retry";
import "rxjs-compat/add/operator/do";
import {Injectable} from "@angular/core";
import {DefaultService, Login} from "app/lib/api";
import {Observable} from "rxjs";
import {TokenSaveInteractor} from "app/domain/interactor/TokenSaveInteractor";

@Injectable()
export class AccountRepository {
  constructor(
    private tokenSaveInteractor: TokenSaveInteractor,
    private defaultService: DefaultService,
    private accountCloud: AccountCloud,
    private localData: LocalData) {
  }

  login(username, password) {
    return this.accountCloud.login(username, password)
      .retry(3)
      .map(result => result.token)
      .do(token => {
        this.tokenSaveInteractor.execute(token);
      })
  }

  getUser(): Observable<Login> {
    return new Observable<Login>(subscribeOn => {
      let user = this.localData.user;

      if (user != null) {
        subscribeOn.next(user);
        return
      }

      this.accountCloud.get()
        .do(login => this.localData.user = login)
        .subscribe(
          value => subscribeOn.next(value),
          error => subscribeOn.error(error))
    });
  }

  register(login) {
    return this.accountCloud.register(login)
  }

  save(login) {
    return this.accountCloud.save(login)
  }

  recovery(username) {
    return this.accountCloud.recovery(username)
  }

  password(old_password: string, new_password: string) {
    return this.accountCloud.password(old_password, new_password)
  }
}
