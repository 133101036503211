import {Component, OnInit} from '@angular/core';
import {ProductListComponent} from "app/view/admin/products/product-list/product-list.component";
import {ProductFormComponent} from "app/view/admin/products/product-form/product-form.component";

@Component({
  selector: 'app-product', templateUrl: './product.component.html', styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}

export const productComponents = [
  ProductListComponent, ProductFormComponent, ProductComponent
];

export const productRoutes = {
  path: 'products', component: ProductComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: ProductListComponent
    }, {
      path: 'form', component: ProductFormComponent
    }, {
      path: 'form/:id', component: ProductFormComponent
    },
  ]
};
