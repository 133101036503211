import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatSnackBar} from "@angular/material";
import {AppService} from "app/infrastructure/services/app.service";
import {AlarmDevice} from "app/data/data/alarm-device";
import {AlarmText} from "app/view/alarm/alarm-list/alarm-text";
import {AlarmApi} from "app/data/source/cloud/alarm-api";
import {DeviceRepository} from "app/data/source/repository/device-repository";
import "rxjs-compat/add/operator/find";
import "rxjs-compat/add/operator/mergeMap";
import "rxjs-compat/add/operator/filter";

@Component({
  selector: 'app-alarm-list', templateUrl: './alarm-list.component.html', styleUrls: ['./alarm-list.component.scss']
})
export class AlarmListComponent implements OnInit, OnDestroy {
  alarms: AlarmDevice[] = [] as AlarmDevice[];
  empty: boolean;
  loading: boolean;
  alarmName: string;
  col: number = 2;
  subscription;

  constructor(
    private appService: AppService,
    private alarmApi: AlarmApi,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private deviceRepository: DeviceRepository
  ) {
  }

  ngOnInit() {
    this.subscription = this.activatedRoute.params
      .map(params => {
        this.loading = true;
        this.alarms = [];
        return params["type"];
      })
      .flatMap(type => this.alarmApi.listType(type))
      .filter(alarms => alarms != undefined && alarms.items != undefined)
      .flatMap(alarm => alarm.items)
      .do(alarm => {
        this.alarmName = new AlarmText(alarm.type).title;
        this.appService.setNavSubtitle(this.alarmName);
      })
      .map(alarm => this.deviceRepository.findID(alarm.deviceId)
        .map(device => new AlarmDevice(alarm, device)))
      .flatMap(alarmDevice => alarmDevice)
      .subscribe(alarmDevice => {
        this.loading = false;
        this.alarms.push(alarmDevice);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
