import {Component, OnInit} from '@angular/core';
import {Signature} from "app/lib/api";
import {SignatureRepository} from "app/data/source/repository/signature-repository.service";

@Component({
  selector: 'app-plain-list',
  templateUrl: './signature-list.component.html',
  styleUrls: ['./signature-list.component.scss']
})
export class SignatureListComponent implements OnInit {
  loading: boolean = true;
  signatures: Signature[] = [];

  constructor(private repository: SignatureRepository) {
  }

  ngOnInit() {
    this.load()
  }

  private load(cache = true) {
    this.loading = true;
    this.repository.list(100, null, cache)
      .subscribe(value => {
        this.loading = false;
        return this.signatures = value;
      })
  }
}
