import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {Device, GPS} from "app/lib/api";
import {AppService} from "app/infrastructure/services/app.service";
import {MaterialColorsService} from "app/infrastructure/services/material-colors.service";
import {Utils} from "app/infrastructure/utils/Utils";
import {MatDialog} from "@angular/material";
import {DialogDiagnosticComponent} from "app/view/maps/location/dialog-diagnostic/dialog-diagnostic.component";
import {DeviceGPS} from "app/domain/model/gps-device";

@Component({
  selector: 'adapter-map-list',
  templateUrl: './adapter-map-list.component.html',
  styleUrls: ['./adapter-map-list.component.scss']
})
export class AdapterMapList implements OnInit {
  offline: Boolean = false;

  @Input() device: Device;

  @Input() loading: boolean;

  @Input() gps: GPS;

  @Output() info = new EventEmitter<DeviceGPS>();

  @Output() center = new EventEmitter<DeviceGPS>();

  @Output() centerAll = new EventEmitter();

  constructor(private appService: AppService, private materialColors: MaterialColorsService, private utils: Utils, private dialog: MatDialog, private zone: NgZone) {
  }

  ngOnInit() {
  }

  emitInfo() {
    if (this.device.validDueDate) {
      this.info.emit(new DeviceGPS(this.device, this.gps));
    }
  }

  emitCenter() {
    if (this.gps != undefined) {
      this.center.emit(new DeviceGPS(this.device, this.gps));
    } else {
      this.centerAll.emit();
    }
  }

  getBatteryText() {
    if (this.gps) {
      if (this.gps.power == 0 && this.gps.voltage != undefined) {
        return `${this.gps.voltage}V`;
      } else {
        return `${this.gps.power}%`;
      }
    } else {
      return "";
    }
  }

  getLockIcon() {
    return this.utils.getLockIcon(this.gps)
  }

  getBattery() {
    return this.utils.getBatteryIcon(this.gps);
  }

  getBatteryColor() {
    return this.utils.getBatteryColor(this.gps)
  }

  getAccColor() {
    return this.utils.getAccColor(this.gps);
  }

  getLockColor() {
    return this.utils.getLockColor(this.gps)
  }

  getSpeedColor() {
    return this.utils.getSpeedColor(this.gps)
  }

  getStatusColor(condition: boolean) {
    return this.utils.getStatusColor(condition)
  }

  isOnline() {
    return !this.isOffline() && this.isValidOverdue()
  }

  isInvoiceOverdue() {
    return !this.isValidOverdue() && this.device.contractId != null
  }

  isPrePaidOverdue() {
    return !this.isValidOverdue() && this.device.contractId == null
  }

  noHasConnection() {
    return this.isValidOverdue() && !this.loading && this.gps == null
  }

  isOffline() {
    return this.isValidOverdue() && this.gps && this.gps.dateOutOfDate
  }

  isValidOverdue() {
    return this.device && this.device.validDueDate
  }

  showDiagnostic() {
    this.dialog.open(DialogDiagnosticComponent, {
      data: new DeviceGPS(this.device, this.gps)
    });
  }
}
