import {Component, OnInit} from '@angular/core';
import {InvoiceRepository} from "app/data/source/repository/invoice-repository";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {DeviceRepository} from "app/data/source/repository/device-repository";
import "rxjs-compat/add/operator/mergeMap";
import {CustomizeDialog, ErrorGenericComponent} from "app/view/custom/dialog/error-generic/error-generic.component";
import {PaymentRepository} from "app/data/source/repository/payment-repository";
import {InvoiceDetailsPayload} from "app/lib/api";

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {
  invoiceDetails: InvoiceDetailsPayload;
  loading = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private deviceRepository: DeviceRepository,
              private invoiceRepository: InvoiceRepository,
              private paymentRepository: PaymentRepository,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.loadDetails()
  }

  loadDetails() {
    this.loading = true;
    this.route.params
      .map(params => params["invoiceId"])
      .flatMap(invoiceId => this.invoiceRepository.details(invoiceId))
      .subscribe(invoiceDetails => {
        this.loading = false;
        this.invoiceDetails = invoiceDetails
      }, error => {
        this.loading = false;
        new ErrorManager(error, this.dialog)
      })
  }

  paymentInvoice(invoiceId) {
    this.loading = true;

    this.paymentRepository.createInvoice(invoiceId)
      .subscribe(value => {
        this.loading = false;
        window.open(value.url, "_self")
      }, reason => {
        this.loading = false;
        if (reason.status == 409) {
          let customizeDialog = new CustomizeDialog(0);

          customizeDialog.title = "Informações pessoais";
          customizeDialog.context = "Para efetuar o pagamentos da fatura é necessário preencher os dados pessoais";

          this.dialog.open(ErrorGenericComponent, {
            data: customizeDialog
          }).afterClosed().subscribe(() => {
            this.router.navigateByUrl("/account")
          });
        } else {
          this.dialog.open(ErrorGenericComponent)
        }
      })
  }
}
