import {Component, OnInit} from '@angular/core';
import {MatDialogRef, MatSnackBar} from "@angular/material";
import {HttpErrorResponse} from "@angular/common/http";
import {AccountCloud} from "app/data/source/cloud/account-cloud.service";
import {AccountRepository} from "app/data/source/repository/account-repository";

@Component({
  selector: 'app-change-password', templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  old_password: string;
  new_password: string;
  confirm_password: string;
  error: string;
  loading: boolean;

  constructor(private accountApi: AccountRepository, private dialogRef: MatDialogRef<ChangePasswordComponent>, private snackbar: MatSnackBar) {
  }

  ngOnInit() {
  }

  save() {
    this.error = null;

    if (this.new_password != this.confirm_password) {
      this.error = "A senha e a confirmação devem ser iguais";
      return
    }

    this.loading = true;

    this.accountApi.password(this.old_password, this.new_password)
      .subscribe(value => {
        this.loading = false;
        this.dialogRef.close();
        this.snackbar.open("Senha alterada com sucesso", null, {
          horizontalPosition: "end", duration: 5000
        })
      }, (reason: HttpErrorResponse) => {
        this.loading = false;
        this.error = "Verifique a senha atual e tente novamente";
      });
  }
}
