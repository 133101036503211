import {Component, OnInit} from '@angular/core';
import {Log} from "app/lib/api";
import {AppService} from "app/infrastructure/services/app.service";
import {LogApi} from "app/data/source/cloud/log-api";

@Component({
  selector: 'app-log-list', templateUrl: './log-list.component.html', styleUrls: ['./log-list.component.scss']
})
export class LogListComponent implements OnInit {
  logList: Log[] = [];
  loading: boolean = false;

  constructor(private appService: AppService, private apiCliet: LogApi) {

    appService.setNavTitle("Notificações")
  }

  ngOnInit() {

    this.loadingLogs();
  }

  private loadingLogs() {
    this.loading = true;

    this.apiCliet.list(100, null).then(value => {
      this.loading = false;
      this.logList = value.items
    })
  }
}
