import {Component, OnInit} from '@angular/core';
import {IMEIStatus} from "app/lib/api";
import {MatDialog} from "@angular/material";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {AdminApi} from "app/data/source/cloud/admin-api";

@Component({
  selector: 'app-status', templateUrl: './status.component.html', styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  imei: string;
  status: IMEIStatus;
  loading: boolean;

  constructor(private adminApi: AdminApi, private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  search() {
    this.loading = true;

    this.adminApi.getIMEIStatus(this.imei)
      .then(value => {
        this.loading = false;
        this.status = value;
      }, reason => {
        new ErrorManager(reason, this.dialog);
      })
  }

}
