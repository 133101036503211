import {Component, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {Login} from "app/lib/api";
import {HttpErrorResponse} from "@angular/common/http";
import {AppService} from "app/infrastructure/services/app.service";
import {MatDialog} from "@angular/material";
import {CustomizeDialog, ErrorGenericComponent} from "app/view/custom/dialog/error-generic/error-generic.component";
import {LogService} from "app/infrastructure/services/log.service";
import {Router} from "@angular/router";
import {AccountRepository} from "app/data/source/repository/account-repository";
import {UserSaveInteractor} from "app/domain/interactor/UserSaveInteractor";

@Component({
  selector: 'app-register', templateUrl: './register.component.html', styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  login: Login = {} as Login;
  loading: boolean;
  managerId: number;

  constructor(
    private accountRepository: AccountRepository,
    private appService: AppService,
    private dialog: MatDialog,
    private router: Router,
    private userSaveInteractor: UserSaveInteractor
  ) {
    this.managerId = this.appService.managerId;
  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    this.loading = true;

    let login = {} as Login;

    login.name = form.value.name;
    login.email = form.value.email;
    login.username = form.value.username;
    login.password = form.value.password;

    console.log(login);

    this.accountRepository.register(login)
      .subscribe(value => {
        this.success(value);
      }, (errorResponse: HttpErrorResponse) => {
        this.loading = false;
        LogService.debug(errorResponse);
        this.error(errorResponse.error.error.message);
      })
  }

  private success(login: Login) {
    this.userSaveInteractor.execute(login);
    this.router.navigateByUrl("/register_success");
  }

  private error(message: string) {
    let customizeDialog = new CustomizeDialog(0);
    customizeDialog.context = message;

    this.dialog.open(ErrorGenericComponent, {
      data: customizeDialog
    });

    this.loading = false;

  }
}
