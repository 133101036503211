import {CollectionResponseContract, DefaultService} from "app/lib/api";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export class ContractCloud {
  constructor(private defaultService: DefaultService) {

  }

  listAll(limit, cursor): Observable<CollectionResponseContract> {
    return this.defaultService.contractEndpointV1ListAll(limit, cursor)
  }

  get(contractId: any) {
    return this.defaultService.contractEndpointV1Get(contractId)
  }

  review(loginId, hostId, plainId, contractDate, dueDate, nextDueDate, discountExpiredAt, discountAmount) {
    return this.defaultService.contractEndpointV1Review(hostId,
      plainId,
      contractDate,
      dueDate,
      loginId,
      nextDueDate,
      discountExpiredAt,
      discountAmount)
  }

  register(loginId,
           hostId,
           plainId,
           createDate,
           dueDate,
           nextDueDate,
           m2mId,
           deviceId,
           discountExpiredAt,
           discountAmount,
           discountDescription) {
    return this.defaultService.contractEndpointV1Register(hostId,
      plainId,
      createDate,
      dueDate,
      loginId,
      nextDueDate,
      m2mId,
      deviceId,
      discountExpiredAt,
      discountAmount,
      discountDescription)
  }
}
