import {Component, Input, OnInit} from '@angular/core';
import {Device, Log} from "app/lib/api";
import {AppService} from "app/infrastructure/services/app.service";
import {DeviceRepository} from "app/data/source/repository/device-repository";

@Component({
  selector: 'app-log-adapter', templateUrl: './log-adapter.component.html', styleUrls: ['./log-adapter.component.scss']
})
export class LogAdapterComponent implements OnInit {
  device: Device;

  constructor(private deviceRepository: DeviceRepository) {
  }

  _item: Log;

  @Input() set item(item: Log) {
    this._item = item;

    this.deviceRepository.findID(this._item.deviceId)
      .subscribe(value => {
          this.device = value
        }
      );
  }

  ngOnInit() {
  }

}
