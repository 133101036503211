import {LocalData} from "app/data/source/local/local-data";

export abstract class AddressProvider {
  resolvedAddresses: Map<string, { lat: number, lng: number, address: string }> = new Map();
  failedAddresses: Map<string, { lat: number, lng: number, address: string }> = new Map();
  pendingAddresses: Map<string, { lat: number, lng: number }> = new Map();

  constructor(public localData: LocalData) {
    this.startAddressResolver();
    this.resolvedAddresses = localData.geocode;
  }

  public clearPendingAddresses(): void {
    this.pendingAddresses.clear();
    this.failedAddresses.clear();
  }

  private startAddressResolver() {
    setInterval(() => {
      if (this.pendingAddresses.size > 0) {
        const firstKey = this.pendingAddresses.keys().next().value;
        const pending = this.pendingAddresses.get(firstKey);
        if (pending) {
          this.resolveAddress(pending.lat, pending.lng).then(address => {
            if (address === "") {
              this.failedAddresses.set(firstKey, {lat: pending.lat, lng: pending.lng, address: "Endereço desconhecido"});
            }else{
              this.resolvedAddresses.set(firstKey, {lat: pending.lat, lng: pending.lng, address: address});
              this.localData.geocode = this.resolvedAddresses;
            }
            this.pendingAddresses.delete(firstKey);
          });
        }
      }
    }, 3500);
  }

  abstract resolveAddress(lat: number, lng: number): Promise<string>

  public getAddress(lat?: number, lng?: number): string {
    if (!lat || !lng) return 'Endereço desconhecido';

    lat = this.roundCoordinate(lat);
    lng = this.roundCoordinate(lng);

    const key = `${lat},${lng}`;

    const existing = this.resolvedAddresses.has(key);
    if (existing) {
      return this.resolvedAddresses.get(key).address;
    }

    const failed = this.failedAddresses.has(key);
    if (failed) {
      return this.failedAddresses.get(key).address;
    }

    const pending = this.pendingAddresses.has(key);
    if (!pending) {
      this.pendingAddresses.set(key, {lat: lat, lng: lng});
    }

    return 'Buscando endereço...';
  }

  private roundCoordinate(value: number, precision: number = 4): number {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
  }
}

export class LatLng {
  lat: number;
  lng: number;

  constructor(lat, lng) {
    this.lat = lat;
    this.lng = lng;
  }

  getKey() {
    return `${this.lat} ${this.lng}`;
  }
}
