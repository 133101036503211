import {Component, OnInit} from '@angular/core';
import {Product} from "app/lib/api";
import {Router} from "@angular/router";
import {ProductRepository} from "app/data/source/repository/product-repository";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  products: Array<Product>;
  loading: boolean = false;

  constructor(private productRepository: ProductRepository, private  router: Router) {
  }

  ngOnInit() {
    this.load();
  }

  click() {

  }

  edit(feature: Product) {
    this.router.navigate(["admin/products/edit", feature.id]);
  }

  private load(cache = true) {
    this.loading = true;
    this.productRepository.listAll(100, null, cache)
      .subscribe(value => {
        this.loading = false;
        this.products = value
      })
  }
}
