import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {FindResult} from "app/view/custom/find/find.component";
import {AppService} from "app/infrastructure/services/app.service";
import {MapMarkerComponent} from "../location/map-marker/map-marker.component";
import {MatDialog} from "@angular/material";
import {DialogHistoryDetailsComponent} from "app/view/custom/dialog-history-details/dialog-history-details.component";
import {GpsApi} from "app/data/source/cloud/gps-api";
import {DeviceGPS} from "app/domain/model/gps-device";
import {ErrorManager} from "app/view/custom/error/ErrorManager";

@Component({
  selector: 'app-history-map', templateUrl: './history-map.component.html', styleUrls: ['./history-map.component.scss']
})
export class HistoryMapComponent implements OnInit {

  @ViewChild("maphistory", {static: true}) mapMarker: MapMarkerComponent;

  private result: boolean = false;
  private loading: boolean;
  private gpsList = [];

  constructor(appService: AppService,
              private gpsApi: GpsApi,
              private dialog: MatDialog,
              public zone: NgZone
  ) {
    appService.setNavTitle("Mapa");
    appService.setNavSubtitle("Histórico");
  }

  ngOnInit() {
  }

  onResult(findResult: FindResult) {
    this.loading = true;

    let dateStart = findResult.startDate;
    let dateEnd = findResult.endDate;

    this.gpsApi.getHistory(findResult.device.id, dateStart, dateEnd, 10000, true, null)
      .then(value => {
        this.result = true;
        this.loading = false;
        this.gpsList = value.items || [];
      }, reason => {
        new ErrorManager(reason, this.dialog);
        this.loading = false;
      });
  }

  resetSearch() {
    this.result = false;

    this.loading = false;

    this.gpsList = [];
  }

  showDetails(gpsDevice: DeviceGPS) {
    this.zone.run(() => {
      this.dialog.open(DialogHistoryDetailsComponent, {
        data: gpsDevice
      });
    })
  }

  center() {
    this.mapMarker.center();
  }
}
