import {Component, Input, OnInit} from '@angular/core';
import {Device} from "app/lib/api";

@Component({
  selector: 'app-device-list-adapter',
  templateUrl: './device-list-adapter.component.html',
  styleUrls: ['./device-list-adapter.component.scss']
})
export class DeviceListAdapterComponent implements OnInit {

  @Input() device: Device;

  constructor() {
  }

  ngOnInit() {

  }
}
