import {Component, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {AppService} from "app/infrastructure/services/app.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material";
import {InvalidLoginDialogComponent} from "app/view/login/invalid-login-dialog/invalid-login-dialog.component";
import {environment} from "environments/environment";
import {LogService} from "app/infrastructure/services/log.service";
import {AccountRepository} from "app/data/source/repository/account-repository";
import {AppRoute} from "app/infrastructure/services/app_route";

@Component({
  selector: 'app-login', templateUrl: './login.component.html', styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  production = environment.production;
  token = environment.token;

  constructor(
    private accountRepository: AccountRepository,
    private appService: AppService,
    private appRoute: AppRoute,
    private router: Router,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.appService.clearAll();
  }

  onSubmit(form: NgForm) {
    this.loading = true;

    let formValue = form.value;

    this.accountRepository.login(formValue.username, formValue.password)
      .subscribe(
        success => this.successLogin(),
        error => this.invalidLogin(error)
      );
  }

  successLogin() {
    this.appRoute.routeSetup();
  }

  invalidLogin(error) {
    LogService.debug(error);
    this.loading = false;
    this.dialog.open(InvalidLoginDialogComponent);
  }
}
