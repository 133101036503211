import {Injectable} from '@angular/core';
import {AddressProvider, LatLng} from "../address_provider";
import {AppService} from "app/infrastructure/services/app.service";
import {HttpClient} from "@angular/common/http";
import {AddressModelProvider} from "../address_model_provider";
import {LocalData} from "app/data/source/local/local-data";
import RootObject = GoogleResponse.RootObject;

@Injectable()
export class GoogleAddressProvider extends AddressProvider {
  googleApiKey: string;
  language: string;

  constructor(
    public appService: AppService,
    private http: HttpClient,
    localData: LocalData,
  ) {
    super(localData);
    this.googleApiKey = this.appService.google_api_key;
    this.language = this.appService.language;

  }

  resolveAddress(lat: number, lng: number): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.googleApiKey}&language=${this.language}`)
        .subscribe(
          value => {
            let rootObject = (value as RootObject);
            let addressModel: AddressModelProvider = new AddressModelProvider();

            if (rootObject.results.length > 0) {
              addressModel.formatedAddress = rootObject.results[0].formatted_address;
            } else {
              addressModel.formatedAddress = "";
            }
            resolve(addressModel.formatedAddress);
          }, error => {
            reject(error);
            console.log(error);
          }
        )
    });
  }
}
