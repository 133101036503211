import {Injectable} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {DefaultService, Device, Login} from "app/lib/api";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {DeviceApi} from "app/data/source/cloud/device-api";
import {GpsApi} from "app/data/source/cloud/gps-api";
import {LocalData} from "app/data/source/local/local-data";
import {TokenLoadInteractor} from "app/domain/interactor/TokenLoadInteractor";
import {AppRoute} from "app/infrastructure/services/app_route";

@Injectable()
export class AppService {
  debugMode = false;
  baseUrl = "https://v5-dot-apureh-web.appspot.com";
  navTitleObserve = new Subject<string>();
  navSubtitleObserve = new Subject<string>();
  google_api_key = "AIzaSyDM1k4NIcFzG6R_c1n2Mo5pkaPecJYCRtk";
  map_request_key = "kX6S9hGgpynKt0nVuIvrmTEj02eOb8uB";
  language = "pt-BR";
  managerId: number;

  constructor(
    private appRoute: AppRoute,
    private cookieService: CookieService,
    private tokenLoadInteractor: TokenLoadInteractor,
    public deviceApi: DeviceApi,
    public gpsApi: GpsApi,
    private defaultService: DefaultService) {
    this.setupAPI();
    this.setupManagerId();
  }

  get lastDeviceSelected(): number {
    return +localStorage.getItem("lastDeviceSelected");
  }

  set lastDeviceSelected(deviceId: number) {
    localStorage.setItem("lastDeviceSelected", deviceId.toString());
  }

  get toolbar() {
    return localStorage.getItem("toolbar") != "false";
  }

  set toolbar(toolbar: boolean) {
    let saveToolbar;

    if (toolbar == false) {
      saveToolbar = "false";
    } else {
      saveToolbar = "true";
    }

    localStorage.setItem("toolbar", saveToolbar);
  }

  public static setTitle(title: string) {
    document.title = title;
  }

  public setNavTitle(title: string) {
    this.navTitleObserve.next(title);
    this.setNavSubtitle(null);
  }

  public setNavSubtitle(subtitle: string) {
    this.navSubtitleObserve.next(subtitle);
  }

  logout(redirect) {
    this.clearAll();
    this.appRoute.routeLogout(redirect);
  }

  clearAll() {
    this.cookieService.deleteAll();
    this.setupAPI();
    localStorage.clear();
  }

  setupAPI() {
    this.defaultService.configuration.basePath = this.baseUrl;
    this.defaultService.configuration.accessToken = this.tokenLoadInteractor.execute();
  }

  private setupManagerId() {
    this.managerId = +"5956907458101248";
  }
}
