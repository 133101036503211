import {Component, OnInit} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";
import {MatSnackBar} from "@angular/material";
import {TokenSaveInteractor} from "app/domain/interactor/TokenSaveInteractor";

@Component({
  selector: 'app-credentials', templateUrl: './credentials.component.html', styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {
  token: string;

  constructor(
    private appService: AppService,
    private tokenSaveInteractor: TokenSaveInteractor,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
  }

  save() {
    this.tokenSaveInteractor.execute(this.token);
    this.appService.setupAPI();

    this.snackBar.open("Token salvo", null, {
      horizontalPosition: "end", duration: 2000
    })
  }
}
