import {Component, OnInit} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";

@Component({
  selector: 'app-payment', templateUrl: './payment.component.html', styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  constructor(appService: AppService) {
    appService.setNavTitle("Pagamento");
  }

  ngOnInit() {
  }

}
