import {Observable} from "rxjs";
import {Plain} from "app/lib/api";
import {LocalData} from "app/data/source/local/local-data";
import {Injectable} from "@angular/core";
import {PlainCloud} from "app/data/source/cloud/plain-cloud";

@Injectable()
export class PlainRepository {
  static PLAINS = "plains";

  constructor(private plainCloud: PlainCloud, private localData: LocalData) {

  }

  listFeatures(featureId, limit, cursor) {
    return this.plainCloud.listFeatures(featureId, limit, cursor)
  }

  list(category, limit, cursor, cache = true) {
    return new Observable<Plain[]>(subscribe => {
      if (cursor == null && cache) {
        let categories = this.localData.load(PlainRepository.PLAINS);

        if (categories != undefined) {
          subscribe.next(categories);
          return
        }
      }

      this.plainCloud.list(category, limit, cursor)
        .do(x => this.localData.save(PlainRepository.PLAINS, x.items))
        .subscribe(value => subscribe.next(value.items), error => subscribe.error(error))
    })
  }

  save(category: Plain) {
    return this.plainCloud.save(category)
  }

  load(categoryId) {
    return this.plainCloud.get(categoryId)
  }
}
