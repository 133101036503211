import {Component, OnInit} from '@angular/core';
import {Brand, Category, Feature} from "app/lib/api";
import {ActivatedRoute, Router} from "@angular/router";
import {FeatureCloud} from "app/data/source/cloud/feature-cloud.service";
import {CategoryRepository} from "app/data/source/repository/category-repository";
import {BrandRepository} from "app/data/source/repository/brand-repository";

@Component({
  selector: 'app-features-form',
  templateUrl: './feature-form.component.html',
  styleUrls: ['./feature-form.component.scss']
})
export class FeatureFormComponent implements OnInit {
  categories: Category[] = [];
  brands: Brand[] = [];
  feature: Feature = {} as Feature;
  loading: boolean = false;

  constructor(private featureApi: FeatureCloud, private categoryRepository: CategoryRepository, private brandRepository: BrandRepository, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.params
      .subscribe(value => {
        let id = value["id"];

        if (id != null) {
          this.loadFeature(id);
        }

        this.loadCategories();
        this.loadBrands();
      })
  }

  save() {
    this.loading = true;

    this.featureApi
      .save(this.feature)
      .subscribe(() => this.router.navigateByUrl("admin/features"))
  }

  loadCategories() {
    this.categoryRepository
      .list(100, null)
      .subscribe(value => this.categories = value)
  }

  loadBrands() {
    this.brandRepository
      .list()
      .subscribe(value => this.brands = value)
  }

  private loadFeature(id) {
    this.loading = true;
    this.featureApi
      .get(id)
      .subscribe(value1 => {
        this.loading = false;
        this.feature = value1;
      })
  }
}
