export interface FipeMarcas {
  name: string;
  fipe_name: string;
  order: number;
  key: string;
  id: number;
}

export interface FipeModels {
  key: string;
  name: string;
  id: string;
  fipe_name: string;
}

export enum FipeTipos {
  Carro = 'carros', Moto = 'motos', Caminhão = 'caminhoes'
}
