import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Category, Chip, ChipM2M, Feature, Plain, Provider} from "app/lib/api";
import {PlainRepository} from "app/data/source/repository/plain-repository";
import {CategoryRepository} from "app/data/source/repository/category-repository";
import {FeatureRepository} from "app/data/source/repository/feature-repository";
import {ChipRepository} from "app/data/source/repository/chip-repository";
import {ProviderRepository} from "app/data/source/repository/provider-repository";

@Component({
  selector: 'app-chip-form',
  templateUrl: './chip-form.component.html',
  styleUrls: ['./chip-form.component.scss']
})
export class ChipFormComponent implements OnInit {
  chip: ChipM2M = {};
  providers: Provider[] = [];
  features: Feature[] = [];
  loading = true;

  constructor(
    private chipRepository: ChipRepository,
    private providerRepository: ProviderRepository,
    private featureRepository: FeatureRepository,
    private activate: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.activate.params.subscribe(
      value => {
        let paramId = value['id'];

        if (paramId != undefined) {
          this.chipRepository.load(paramId)
            .subscribe(
              value1 => {
                this.chip = value1;
                this.loading = false;
              }
            )
        } else {
          this.loading = false;
        }
      }
    );

    this.loadCategories();
    this.loadFeatures();
  }

  save() {
    this.loading = true;

    this.chipRepository.save(this.chip)
      .subscribe(
        value => this.loading = false
      )
  }

  private loadFeatures() {
    this.featureRepository.listAll(100, null)
      .subscribe(
        value => {
          this.features = value
        }
      )
  }

  private loadCategories() {
    this.providerRepository.list(100, null)
      .subscribe(
        value => {
          this.providers = value
        }
      )
  }
}
