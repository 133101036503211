import {LocalData} from "app/data/source/local/local-data";
import {Injectable} from "@angular/core";
import {DefaultService} from "app/lib/api";

@Injectable()
export class TokenSaveInteractor {
  constructor(
    private defaultService: DefaultService,
    private localData: LocalData
  ) {
  }

  execute(token) {
    this.localData.token = token;
    this.defaultService.configuration.accessToken = token;
  }
}
