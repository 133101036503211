import {Component, OnInit} from '@angular/core';
import {PaymentComponent} from "app/view/payments/payment.component";
import {PaymentListComponent} from "app/view/payments/list/payment-list.component";
import {PaymentCreateComponent} from "app/view/payments/create/payment-create.component";
import {InvoiceListAdapterComponent} from "app/view/invoice/invoice-list/invoice-list-adapter/invoice-list-adapter.component";
import {InvoiceListComponent} from "app/view/invoice/invoice-list/invoice-list.component";
import {InvoiceDetailComponent} from "app/view/invoice/invoice-detail/invoice-detail.component";
import {AppService} from "app/infrastructure/services/app.service";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  constructor(private appService: AppService) {
    appService.setNavTitle("Faturas")
  }

  ngOnInit() {
  }

}

export const invoiceRoutes = {
  path: 'invoice', component: InvoiceComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: InvoiceListComponent
    }, {
      path: 'details/:invoiceId', component: InvoiceDetailComponent
    }
  ]
};
