import {LocalData} from "app/data/source/local/local-data";
import {Injectable} from "@angular/core";

@Injectable()
export class UserLoadInteractor {
  constructor(private localData: LocalData) {
  }

  execute() {
    return this.localData.user;
  }
}
