import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
  selector: '[confirmPassword]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ConfirmPasswordDirective,
      multi: true
    }
  ]
})
export class ConfirmPasswordDirective implements Validator {
  constructor() {
  }

  registerOnValidatorChange(fn: () => void): void {
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const formGroup = c.parent;

    if (formGroup) {
      let passwordControl = formGroup.get('password');
      let confirmControl = formGroup.get('confirm');

      if (passwordControl && confirmControl) {
        let password = passwordControl.value;
        let confirm = confirmControl.value;

        if (password == confirm) {
          passwordControl.setErrors(null);
          confirmControl.setErrors(null);
          return null;
        } else {
          passwordControl.setErrors({
            confirm: "error"
          });

          confirmControl.setErrors({
            confirm: "error"
          });

          return {
            confirm: false
          };
        }
      }
    }

    return null;
  }


}
