import {Component, OnInit, OnDestroy} from '@angular/core';
import {ReportBase} from "app/view/reports/report-base/report-base";
import {MatDialog} from "@angular/material";
import {AppService} from "app/infrastructure/services/app.service";
import {GpsApi} from "app/data/source/cloud/gps-api";
import {GoogleAddressProvider} from "app/view/custom/provider_address/google-provides-address/google-address-provider";

@Component({
  selector: 'app-report-history',
  templateUrl: './report-history.component.html',
  styleUrls: ['../report-base/report-base.component.css', './report-history.component.css']
})
export class ReportHistoryComponent extends ReportBase implements OnInit, OnDestroy {
  displayedColumns = ['gps_date', 'address', 'acc', 'speed'];

  constructor(
    appService: AppService,
    providerRequest: GoogleAddressProvider,
    apiClient: GpsApi,
    dialog: MatDialog
  ) {
    super(apiClient, providerRequest, dialog);

    appService.setNavSubtitle("Histórico");
  }

  ngOnInit() {
    this.options.headers = [
      "Ignição",
      "Altitude",
      "Cursor",
      "Horario GPS (GMT-0)",
      "Sinal GSM (%)",
      "IMEI",
      "Lat",
      "Lng",
      "Bloqueio",
      "Bateria (%)",
      "Modelo",
      "Recebido (GMT-0)",
      "Velocidade",
    ]
  }

  public loadMore() {
    this.search();
  }

  protected search() {
    this.loading = true;

    let deviceId = this.result.device.id;
    this.dateStart = this.result.startDate;
    this.dateEnd = this.result.endDate;

    this.gpsApi.getHistory(deviceId, this.dateStart, this.dateEnd, 20, true, this.cursor)
      .then(value => {
        this.data = value.items as Array<ReportGPSClass>;
        this.received(this.data, value.nextPageToken);
      }, reason => {
        this.error(reason);
      })
  }
}
