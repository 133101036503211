import {Component, OnInit} from '@angular/core';
import {Brand} from "app/lib/api";
import {BrandRepository} from "app/data/source/repository/brand-repository";

@Component({
  selector: 'app-brand-list', templateUrl: './brand-list.component.html', styleUrls: ['./brand-list.component.scss']
})
export class BrandListComponent implements OnInit {
  loading: boolean = true;
  brands: Brand[] = [];

  constructor(private brandRepository: BrandRepository) {
  }

  ngOnInit() {
    this.loadBrands()
  }

  private loadBrands(cache = true) {
    this.brandRepository.list(cache)
      .subscribe(value => {
        this.loading = false;
        return this.brands = value;
      })
  }
}
