import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Host} from "app/lib/api";
import {LocalData} from "app/data/source/local/local-data";
import {HostCloud} from "app/data/source/cloud/host-cloud";

@Injectable()
export class HostRepository {
  static HOSTS = "hosts";

  constructor(
    private localData: LocalData,
    private hostCloud: HostCloud
  ) {
  }

  get(contractId) {
    return this.hostCloud.get(contractId)
  }

  list(limit, cursor, cache = true) {
    return new Observable<Host[]>(subscribe => {
        if (cursor == null && cache) {
          let categories = this.localData.load(HostRepository.HOSTS);

          if (categories != undefined) {
            subscribe.next(categories || []);
            return
          }
        }

        this.hostCloud.list(limit, cursor)
          .do(x => this.localData.save(HostRepository.HOSTS, x.items))
          .subscribe(
            value => subscribe.next(value.items),
            error => subscribe.error(error)
          )
      }
    )
  }

  createDevice(id: number) {
    return this.hostCloud.createDevice(id)
  }
}
