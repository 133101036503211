import {DefaultService} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class ChipCloud {
  constructor(private defaultService: DefaultService) {

  }

  get(categoryId) {
    return this.defaultService.chipm2mEndpointV1Get(categoryId)
  }

  list(limit, cursor) {
    return this.defaultService.chipm2mEndpointV1List(limit, cursor)
  }

  save(chip) {
    return this.defaultService.chipm2mEndpointV1Save(chip)
  }

  find(serialNumber: string) {
    return this.defaultService.chipm2mEndpointV1Find(serialNumber)
  }
}
