import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatTableDataSource} from "@angular/material";
import {AppService} from "app/infrastructure/services/app.service";
import {Device, Payment} from "app/lib/api";
import {PaymentCloud} from "app/data/source/cloud/payment-cloud.service";
import {DeviceRepository} from "app/data/source/repository/device-repository";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {Observable} from "rxjs";

@Component({
  selector: 'app-payments', styleUrls: ['./payment-list.component.css'], templateUrl: './payment-list.component.html'
})
export class PaymentListComponent implements OnInit, OnDestroy {
  subscription;
  displayedColumns = ['data', 'description', 'status'];
  loading = true;
  private paymentList: DevicePayment[] | null = [] as DevicePayment[];

  constructor(private appService: AppService,
              private paymentApi: PaymentCloud,
              private dialog: MatDialog,
              private deviceRepository: DeviceRepository
  ) {
    this.appService.setNavSubtitle("Histórico")
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.deviceRepository.list()
      .flatMap(devices => devices)
      .map(device => {
        return this.paymentApi.list(100, device.id, null)
          .map(value => {
            let payment = value;
            let devicePayment = new DevicePayment(device);
            devicePayment.dataSource.data = payment.items;
            return devicePayment
          })
      })
      .flatMap(value => value)
      .subscribe((devicePayment) => {
          this.paymentList.push(devicePayment);
        },
        reason => {
          new ErrorManager(reason, this.dialog)
        }, () => {
          this.loading = false
        });
  }

  public getStatus(payment: Payment) {
    if (payment.paymentStatusList != null) {
      let statusDescription = payment.paymentStatusList[0].statusDescription;

      if (statusDescription.length == 0) {
        statusDescription = "Concluído";
      }

      return statusDescription;
    }

    return "Iniciado";
  }
}

export class DevicePayment {
  device: Device;
  dataSource = new MatTableDataSource<Payment>();

  constructor(device) {
    this.device = device;
  }
}
