import {Observable} from "rxjs";
import {Signature, SignaturePlainsPayload} from "app/lib/api";
import {LocalData} from "app/data/source/local/local-data";
import {Injectable} from "@angular/core";
import {SignatureCloud} from "app/data/source/cloud/signature-cloud";

@Injectable()
export class SignatureRepository {
  static SIGNATURES = "signatures";
  static SIGNATURES_PLAINS = "signatures_plains";

  constructor(private cloud: SignatureCloud, private localData: LocalData) {

  }

  list(limit, cursor, cache = true): Observable<Signature[]> {
    return new Observable<Signature[]>(subscribe => {
      if (cursor == null && cache) {
        let signatures = this.localData.load(SignatureRepository.SIGNATURES);

        if (signatures != undefined) {
          subscribe.next(signatures);
          return
        }
      }

      this.cloud.list(limit, cursor)
        .do(x => this.localData.save(SignatureRepository.SIGNATURES, x.items))
        .subscribe(value => subscribe.next(value.items), error => subscribe.error(error))
    })
  }

  listPlain(categoryId, hostId, limit, cursor, cache = true): Observable<SignaturePlainsPayload[]> {
    return new Observable<Signature[]>(subscribe => {
      if (cursor == null && cache) {
        let signatures = this.localData.load(SignatureRepository.SIGNATURES_PLAINS + hostId);

        if (signatures != undefined) {
          subscribe.next(signatures);
          return
        }
      }

      this.cloud.listPlains(categoryId, hostId, limit, cursor)
        .do(x => this.localData.save(SignatureRepository.SIGNATURES_PLAINS + hostId, x.items))
        .subscribe(value => subscribe.next(value.items), error => subscribe.error(error))
    })
  }

  save(signature: Signature) {
    return this.cloud.save(signature)
  }

  get(signatureId) {
    return this.cloud.get(signatureId)
  }
}
