import {Component, OnInit} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";

@Component({
  selector: 'app-device', templateUrl: './device.component.html', styleUrls: ['./device.component.css']
})
export class DeviceComponent implements OnInit {

  constructor(appService: AppService) {
    appService.setNavTitle("Equipamentos")
  }

  ngOnInit() {
  }

}
