import {Injectable} from "@angular/core";
import {DefaultService} from "app/lib/api";

@Injectable()
export class InvoiceCloud {
  constructor(private defaultService: DefaultService) {
  }

  list() {
    return this.defaultService.invoiceEndpointV1List(100, null)
  }

  details(id) {
    return this.defaultService.invoiceEndpointV1Details(id)
  }
}
