import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Device} from "app/lib/api";
import {MatDialog} from "@angular/material";

@Component({
  selector: 'app-pendent-device-icon',
  templateUrl: './pendent-device-icon.component.html',
  styleUrls: ['./pendent-device-icon.component.scss']
})
export class PendentDeviceIconComponent implements OnInit {

  @Input() device: Device;

  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {

  }
}
