import {
  CollectionResponseSignature,
  CollectionResponseSignaturePlainsPayload,
  DefaultService,
  Signature
} from "app/lib/api";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export class SignatureCloud {
  constructor(private apiClient: DefaultService) {

  }

  get(signatureId) {
    return this.apiClient.signatureEndpointV1Get(signatureId)
  }

  list(limit, cursor): Observable<CollectionResponseSignature> {
    return this.apiClient.signatureEndpointV1List(limit, cursor)
  }

  listPlains(categoryId, hostId, limit, cursor): Observable<CollectionResponseSignaturePlainsPayload> {
    return this.apiClient.signatureEndpointV1ListPlains(categoryId, hostId, limit, cursor)
  }

  save(category: Signature) {
    return this.apiClient.signatureEndpointV1Save(category)
  }
}
