import {Injectable} from "@angular/core";
import {LocalData} from "app/data/source/local/local-data";
import {Observable} from "rxjs";
import {Invoice} from "app/lib/api";
import {InvoiceCloud} from "app/data/source/cloud/invoice-cloud";

@Injectable()
export class InvoiceRepository {
  static INVOICE_KEY = "invoice";

  constructor(
    private invoiceCloud: InvoiceCloud,
    private localData: LocalData
  ) {
  }

  list(cache = true) {
    return new Observable<Invoice[]>(subscriber => {
      if (cache) {
        let brands = this.localData.load(InvoiceRepository.INVOICE_KEY);

        if (brands != undefined) {
          subscriber.next(brands);
          subscriber.complete();
          return
        }
      }

      this.invoiceCloud.list()
        .subscribe(
          value => {
            this.localData.save(InvoiceRepository.INVOICE_KEY, value.items);
            subscriber.next(value.items);
            subscriber.complete();
          },
          error => subscriber.error(error)
        )
    })
  }

  details(id) {
    return this.invoiceCloud.details(id)
  }
}
