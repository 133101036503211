import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material";
import {DeviceIcon} from "app/lib/api";
import {DeviceApi} from "app/data/source/cloud/device-api";

@Component({
  selector: 'app-select-icon-dialog',
  templateUrl: './select-icon-dialog.component.html',
  styleUrls: ['./select-icon-dialog.component.scss']
})
export class SelectIconDialogComponent implements OnInit {
  deviceIconList: DeviceIcon[] = [] as DeviceIcon[];
  deviceIconGroup: Set<string> = new Set();
  loading = true;

  constructor(public dialogRef: MatDialogRef<SelectIconDialogComponent>, private apiClient: DeviceApi) {

  }

  ngOnInit() {
    this.apiClient.listDeviceIcons(500, null).then(value => {
      this.loading = false;
      this.deviceIconList = value.items;

      value.items.forEach(device => {
        this.deviceIconGroup.add(device.type)
      });

      this.deviceIconList.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    }, reason => {
      this.dialogRef.close()
    })
  }

  selectImage(deviceIcon: DeviceIcon) {
    this.dialogRef.close(deviceIcon.id);
  }

  filterList(group: string) {
    return this.deviceIconList.filter(value => {
      return value.type == group;
    })
  }
}
