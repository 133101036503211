import {Component, OnInit} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  constructor(appService:AppService) {
    appService.setNavTitle("Relatórios")
  }

  ngOnInit() {

  }

}
