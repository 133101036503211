import {DefaultService} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class AdminApi {
  constructor(private apiClient: DefaultService) {

  }

  getIMEIStatus(imei: string) {
    return this.apiClient.dashEndpointV1DeviceStatus(imei).toPromise()
  }
}
