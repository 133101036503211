import {Observable} from "rxjs";
import {Category} from "app/lib/api";
import {LocalData} from "app/data/source/local/local-data";
import {Injectable} from "@angular/core";
import {CategoryCloud} from "app/data/source/cloud/category-cloud";

@Injectable()
export class CategoryRepository {
  static CATEGORIES = "categories";

  constructor(
    private categoryCloud: CategoryCloud,
    private localData: LocalData
  ) {

  }

  list(limit, cursor, cache = true) {
    return new Observable<Category[]>(subscribe => {
        if (cursor == null && cache) {
          let categories = this.localData.load(CategoryRepository.CATEGORIES);

          if (categories != undefined) {
            subscribe.next(categories);
            return
          }
        }

        this.categoryCloud.list(limit, cursor)
          .do(x => this.localData.save(CategoryRepository.CATEGORIES, x.items))
          .subscribe(
            value => subscribe.next(value.items),
            error => subscribe.error(error)
          )
      }
    )
  }

  save(category: Category) {
    return this.categoryCloud.save(category)
  }

  load(categoryId) {
    return this.categoryCloud.get(categoryId)
  }
}
