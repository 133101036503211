import {Component, OnInit} from '@angular/core';
import {Brand, Category, Product} from "app/lib/api";
import {ActivatedRoute, Router} from "@angular/router";
import {CategoryRepository} from "app/data/source/repository/category-repository";
import {BrandRepository} from "app/data/source/repository/brand-repository";
import {ProductRepository} from "app/data/source/repository/product-repository";

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {
  categories: Category[] = [];
  brands: Brand[] = [];
  product: Product = {} as Product;
  loading: boolean = false;

  constructor(private productRepository: ProductRepository,
              private categoryRepository: CategoryRepository,
              private brandRepository: BrandRepository,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.params
      .subscribe(value => {
        let id = value["id"];

        if (id != null) {
          this.loadFeature(id);
        }

        this.loadCategories();
        this.loadBrands();
      })
  }

  save() {
    this.loading = true;

    this.productRepository.save(this.product)
      .flatMap(() => this.productRepository.listAll(100, null, false))
      .subscribe(() => this.router.navigateByUrl("admin/products"))
  }

  loadCategories() {
    this.categoryRepository
      .list(100, null)
      .subscribe(value => this.categories = value)
  }

  loadBrands() {
    this.brandRepository
      .list()
      .subscribe(value => this.brands = value)
  }

  private loadFeature(id) {
    this.loading = true;
    this.productRepository
      .load(id)
      .subscribe(value1 => {
        this.loading = false;
        this.product = value1;
      })
  }
}
