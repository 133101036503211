import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Category, Feature, Signature} from "app/lib/api";
import {CategoryRepository} from "app/data/source/repository/category-repository";
import {FeatureRepository} from "app/data/source/repository/feature-repository";
import {SignatureRepository} from "app/data/source/repository/signature-repository.service";
import {Details} from "app/lib/api/model/details";

@Component({
  selector: 'app-signature-form',
  templateUrl: './signature-form.component.html',
  styleUrls: ['./signature-form.component.scss']
})
export class SignatureFormComponent implements OnInit {
  signature: Signature = {};
  categories: Category[] = [];
  features: Feature[] = [];
  loading = true;

  constructor(private repository: SignatureRepository, private categoryRepository: CategoryRepository, private featureRepository: FeatureRepository, private activate: ActivatedRoute) {
  }

  ngOnInit() {
    this.activate.params.subscribe(value => {
      let paramId = value['id'];

      if (paramId != undefined) {
        this.repository.get(paramId)
          .subscribe(value1 => {
            this.signature = value1;
            this.loading = false;
          })
      } else {
        this.loading = false;
      }
    });

    this.loadCategories();
    this.loadFeatures();
  }

  save() {
    this.loading = true;

    this.repository.save(this.signature)
      .subscribe(value => this.loading = false)
  }

  private loadFeatures() {
    this.featureRepository.listAll(100, null)
      .subscribe(value => {
        this.features = value
      })
  }

  private loadCategories() {
    this.categoryRepository.list(100, null)
      .subscribe(value => {
        this.categories = value
      })
  }

  newDetail() {
    const details: Details = {};
    if (!this.signature.details) {
      this.signature.details = [];
    }
    this.signature.details.push(details);
  }
}
