import {Component, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {environment} from "environments/environment";
import {CustomizeDialog, ErrorGenericComponent} from "../custom/dialog/error-generic/error-generic.component";
import {MatDialog} from "@angular/material";
import {AccountCloud} from "app/data/source/cloud/account-cloud.service";
import {AccountRepository} from "app/data/source/repository/account-repository";

@Component({
  selector: 'app-forgot', templateUrl: './forgot.component.html', styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  production: boolean = environment.production;

  constructor(private accountRepository: AccountRepository, private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    this.accountRepository.recovery(form.value.username)
      .subscribe(
        value => this.success(),
        (reason: HttpErrorResponse) => this.error()
      )
  }

  error() {
    let customizeDialog = new CustomizeDialog(0);
    customizeDialog.context = "E-mail não encontrado";

    this.dialog.open(ErrorGenericComponent, {
      data: customizeDialog
    });
  }

  private success() {
    let customizeDialog = new CustomizeDialog(0);
    customizeDialog.context = "As instruções foram enviadas para seu e-mail";
    customizeDialog.title = "Instruções enviadas";

    this.dialog.open(ErrorGenericComponent, {
      data: customizeDialog
    });
  }
}
