import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon-detail',
  templateUrl: './icon-detail.component.html',
  styleUrls: ['./icon-detail.component.scss']
})
export class IconDetailComponent implements OnInit {

  @Input()
  icon: string;

  @Input()
  title: string;

  @Input()
  content: string;

  @Input()
  color: string = "#303f9f";

  constructor() {
  }

  ngOnInit() {
  }

}
