import {Component, OnInit} from '@angular/core';
import {FeatureListComponent} from "app/view/admin/features/feature-list/feature-list.component";
import {FeatureFormComponent} from "app/view/admin/features/feature-form/feature-form.component";

@Component({
  selector: 'app-feature', templateUrl: './feature.component.html', styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}

export const featureComponents = [
  FeatureListComponent,
  FeatureFormComponent,
  FeatureComponent
];

export const featureRoutes = {
  path: 'features', component: FeatureComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: FeatureListComponent
    }, {
      path: 'form', component: FeatureFormComponent
    }, {
      path: 'form/:id', component: FeatureFormComponent
    },
  ]
};
