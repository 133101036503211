import {Component, OnInit} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  constructor(appService: AppService) {
    appService.setNavTitle("Ajuda e suporte")
  }

  ngOnInit() {
  }

}
