import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";

@Component({
  selector: 'app-street-view',
  templateUrl: './street-view.component.html',
  styleUrls: ['./street-view.component.scss']
})
export class StreetViewComponent implements OnInit {
  imgSrc: String = "";

  @Input()
  lat: number;

  @Input()
  lng: number;

  @Input()
  angle: number;

  loading: boolean = true;


  constructor(private appService: AppService) {
  }

  ngOnInit() {
    let googleApiKey = this.appService.google_api_key;

    this.imgSrc = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${this.lat},${this.lng}&heading=${this.angle}&pitch=-0.76&key=${googleApiKey}`;

    this.loading = false;
  }
}
