import {Component, OnInit} from '@angular/core';
import {Brand, Category} from "app/lib/api";
import {BrandRepository} from "app/data/source/repository/brand-repository";
import {CategoryRepository} from "app/data/source/repository/category-repository";

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  loading: boolean = true;
  categories: Category[] = [];

  constructor(private categoryRepository: CategoryRepository) {
  }

  ngOnInit() {
    this.load()
  }

  private load(cache = true) {
    this.loading=true;
    
    this.categoryRepository.list(100, null, cache)
      .subscribe(
        value => {
          this.loading = false;
          return this.categories = value;
        }
      )
  }
}
