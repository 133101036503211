import {Component, OnInit} from '@angular/core';
import {ContractListComponent} from "app/view/admin/contracts/category-list/contract-list.component";
import {ContractDetailComponent} from "app/view/admin/contracts/contract-detail/contract-detail.component";

@Component({
  selector: 'app-category',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

export const contractComponents = [
  ContractComponent,
  ContractListComponent
];

export const contractRoutes = {
  path: 'contracts', component: ContractComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: ContractListComponent
    }, {
      path: 'form/:id', component: ContractDetailComponent
    }
  ]
};
