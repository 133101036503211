import {Component, OnInit} from '@angular/core';
import {CategoryListComponent} from "app/view/admin/categories/category-list/category-list.component";
import {CategoryFormComponent} from "app/view/admin/categories/category-form/category-form.component";

@Component({
  selector: 'app-category', templateUrl: './category.component.html', styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

export const categoryComponents = [
  CategoryComponent, CategoryListComponent, CategoryFormComponent
];

export const categoryRoutes = {
  path: 'categories', component: CategoryComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: CategoryListComponent
    }, {
      path: 'form/:id', component: CategoryFormComponent
    }, {
      path: 'form', component: CategoryFormComponent
    }
  ]
};
