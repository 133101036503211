import {Component, OnInit} from '@angular/core';
import {Contract} from "app/lib/api";
import {ContractRepository} from "app/data/source/repository/contract-repository";

@Component({
  selector: 'app-category-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent implements OnInit {
  loading: boolean = true;
  contracts: Contract[] = [];

  constructor(private contractRepository: ContractRepository) {
  }

  ngOnInit() {
    this.loadContracts()
  }

  private loadContracts(cache = true) {
    this.contractRepository.listAll(500, null, cache)
      .subscribe(value => {
        this.loading = false;
        return this.contracts = value;
      })
  }
}
