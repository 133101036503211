import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {
  MAT_CHECKBOX_CLICK_ACTION,
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppComponent} from 'app/app.component';
import {LoginComponent} from 'app/view/login/login.component';
import {AppRoutingModule} from 'app/app-routing.module';
import {RegisterComponent} from 'app/view/register/register.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ForgotComponent} from 'app/view/forgot/forgot.component';
import {HttpClientModule} from "@angular/common/http";
import {AppService} from "app/infrastructure/services/app.service";
import {CookieService} from "ngx-cookie-service";
import {InvalidLoginDialogComponent} from 'app/view/login/invalid-login-dialog/invalid-login-dialog.component';
import {DevComponent} from 'app/dev/dev.component';
import {MaterialColorsService} from "app/infrastructure/services/material-colors.service";
import {GoogleMapsService} from "app/infrastructure/services/google-maps.service";
import {Utils} from "app/infrastructure/utils/Utils";
import {TermsComponent} from 'app/view/terms/terms.component';
import {LogService} from "app/infrastructure/services/log.service";
import {RegisterSuccessComponent} from 'app/view/register/register-success/register-success.component';
import {HomeComponent} from "app/view/home/home.component";
import {DeviceFormComponent} from "app/view/devices/form/device-form.component";
import {PaymentCreateComponent} from "app/view/payments/create/payment-create.component";
import {DeviceImageComponent} from "app/view/custom/device-image/device-image.component";
import {GoogleAddressProvider} from "app/view/custom/provider_address/google-provides-address/google-address-provider";
import {DialogDiagnosticComponent} from "app/view/maps/location/dialog-diagnostic/dialog-diagnostic.component";
import {DialogDetailsComponent} from "app/view/custom/dialog-details/dialog-details.component";
import {YesNoDialogComponent} from "app/view/custom/dialog/yes-no-dialog/yes-no-dialog.component";
import {ChangePasswordComponent} from "app/view/account/change-password/change-password.component";
import {SelectIconDialogComponent} from "app/view/devices/form/select-icon-dialog/select-icon-dialog.component";
import {ErrorGenericComponent} from "app/view/custom/dialog/error-generic/error-generic.component";
import {MapsComponent} from "app/view/maps/maps/maps.component";
import {LocationComponent} from "app/view/maps/location/location.component";
import {StreetViewComponent} from "app/view/custom/street_view/street-view.component";
import {IconDetailComponent} from "app/view/custom/icon-detail/icon-detail.component";
import {ConfirmPasswordDirective} from "app/view/custom/form/confirm-password.directive";
import {AdapterMapList} from "app/view/maps/location/list-devices/device-adapter/adapter-map-list.component";
import {ListDevicesComponent} from "app/view/maps/location/list-devices/list-devices.component";
import {MapMarkerComponent} from "app/view/maps/location/map-marker/map-marker.component";
import {AccountComponent} from "app/view/account/account.component";
import {DeviceAutocompleteComponent} from "app/view/custom/device-autocomplete/device-autocomplete.component";
import {BlockCommandComponent} from "app/view/commands/block-command/block-command.component";
import {CommandsComponent} from "app/view/commands/commands.component";
import {ReportsComponent} from "app/view/reports/reports.component";
import {ReportHistoryComponent} from "app/view/reports/report-history/report-history.component";
import {AlarmListComponent} from "app/view/alarm/alarm-list/alarm-list.component";
import {AlarmComponent} from "app/view/alarm/alarm.component";
import {PaymentComponent} from "app/view/payments/payment.component";
import {InputImeiDirective} from "app/view/custom/form/input-imei.directive";
import {DeviceListComponent} from "app/view/devices/list/device-list.component";
import {DeviceComponent} from "app/view/devices/device.component";
import {DeviceListAdapterComponent} from "app/view/devices/list/adapter/device-list-adapter.component";
import {PaymentListComponent} from "app/view/payments/list/payment-list.component";
import {SetupComponent} from "app/view/setup/setup.component";
import {FindComponent} from "app/view/custom/find/find.component";
import {GraphicsComponent} from 'app/view/graphics/graphics.component';
import {GraphicsResume} from 'app/view/graphics/resume/graphics-resume.component';
import {ChartsModule} from "ng2-charts";
import {AlarmAdapterComponent} from 'app/view/alarm/alarm-list/alarm-adapter/alarm-adapter.component';
import {LoadingComponent} from 'app/view/custom/loading/loading.component';
import {ReportDistanceComponent} from 'app/view/reports/report-distance/report-distance.component';
import {AlarmHeaderBaseComponent} from 'app/view/alarm/alarm-header/alarm-header-base/alarm-header-base.component';
import {ReportAccComponent} from "app/view/reports/report-acc/report-acc.component";
import {PaymentsAdapterComponent} from 'app/view/payments/list/payments-adapter/payments-adapter.component';
import {DeviceImageNameComponent} from 'app/view/custom/device-image-name/device-image-name.component';
import {SearchStatusNullComponent} from 'app/view/custom/search-status-null/search-status-null.component';
import {LottieAnimationViewModule} from "ng-lottie";
import {MapRequestAddressProvider} from "app/view/custom/provider_address/maprequest-provider-address/maprequest-address-provider.service";
import {HistoryMapComponent} from 'app/view/maps/history-map/history-map.component';
import {MapHistoryComponent} from "app/view/maps/history-map/map-history/map-history.component";
import {AnchorComponent} from 'app/view/alarm/anchor/anchor.component';
import {MapAnchorComponent} from 'app/view/alarm/anchor/map-anchor/map-anchor.component';
import {DialogBlockKnowMoreComponent} from 'app/view/commands/block-command/dialog-block-know-more/dialog-block-know-more.component';
import {HelpComponent} from 'app/view/help/help/help.component';
import {LogListComponent} from 'app/view/logs/log-list/log-list.component';
import {LogAdapterComponent} from 'app/view/logs/log-list/log-adapter/log-adapter.component';
import {LayoutModule} from '@angular/cdk/layout';
import {AdminComponent} from 'app/view/admin/admin/admin.component';
import {StatusComponent} from 'app/view/admin/status/status.component';
import {ApiModule, BASE_PATH, Configuration, ConfigurationParameters, DefaultService} from "./lib/api";
import {CredentialsComponent} from 'app/view/admin/credentials/credentials.component';
import {PendentComponent} from 'app/view/pendent/pendent.component';
import {PendentDeviceIconComponent} from 'app/view/pendent/pendent-device-icon/pendent-device-icon.component';
import {iconsComponents} from 'app/view/admin/icons/icons.component';
import {IconAdapterComponent} from 'app/view/admin/icons/list/icon-adapter/icon-adapter.component';
import {MarkdownModule} from "angular2-markdown";
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from "@angular/common";
import {featureComponents} from 'app/view/admin/features/feature.component';
import {FipeManufacturerComponent} from 'app/view/custom/fipe/fipe-manufacturer/fipe-manufacturer.component';
import {FipeModelComponent} from 'app/view/custom/fipe/fipe-model/fipe-model.component';
import {DialogHistoryDetailsComponent} from "app/view/custom/dialog-history-details/dialog-history-details.component";
import {Angulartics2Module} from "angulartics2";
import {DeviceApi} from "app/data/source/cloud/device-api";
import {GpsApi} from "app/data/source/cloud/gps-api";
import {AccountCloud} from "app/data/source/cloud/account-cloud.service";
import {PaymentCloud} from "app/data/source/cloud/payment-cloud.service";
import {CommandApi} from "app/data/source/cloud/command-api";
import {FeatureCloud} from "app/data/source/cloud/feature-cloud.service";
import {LogApi} from "app/data/source/cloud/log-api";
import {AlarmApi} from "app/data/source/cloud/alarm-api";
import {AdminApi} from "app/data/source/cloud/admin-api";
import {PlainApi} from "app/data/source/cloud/plain-api";
import {AccountRepository} from "app/data/source/repository/account-repository";
import {LocalData} from "app/data/source/local/local-data";
import {TokenSaveInteractor} from "app/domain/interactor/TokenSaveInteractor";
import {TokenLoadInteractor} from "app/domain/interactor/TokenLoadInteractor";
import {UserSaveInteractor} from "app/domain/interactor/UserSaveInteractor";
import {UserLoadInteractor} from "app/domain/interactor/UserLoadInteractor";
import {DeviceRepository} from "app/data/source/repository/device-repository";
import {CategoryRepository} from "app/data/source/repository/category-repository";
import {CategoryCloud} from "app/data/source/cloud/category-cloud";
import {BrandCloud} from "app/data/source/cloud/brand-cloud";
import {BrandRepository} from "app/data/source/repository/brand-repository";
import {brandComponents} from 'app/view/admin/brands/brand.component';
import {KodakRepository} from "app/data/source/repository/kodak-repository";
import {categoryComponents} from "app/view/admin/categories/category.component";
import {plainComponents} from "app/view/admin/plains/plain.component";
import {PlainRepository} from "app/data/source/repository/plain-repository";
import {PlainCloud} from "app/data/source/cloud/plain-cloud";
import {FeatureRepository} from "app/data/source/repository/feature-repository";
import {chipsComponents} from "app/view/admin/chips/chip.component";
import {ChipRepository} from "app/data/source/repository/chip-repository";
import {ChipCloud} from "app/data/source/cloud/chip-cloud";
import {ProviderCloud} from "app/data/source/cloud/provider-cloud";
import {ProviderRepository} from "app/data/source/repository/provider-repository";
import {contractComponents} from "app/view/admin/contracts/contract.component";
import {ChipSearchComponent} from 'app/view/admin/chips/chip-search/chip-search.component';
import {ChipItemComponent} from 'app/view/admin/chips/chip-list/chip-item/chip-item.component';
import {ContractRepository} from "app/data/source/repository/contract-repository";
import {ContractCloud} from "app/data/source/cloud/contract-cloud";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {HostCloud} from "app/data/source/cloud/host-cloud";
import {HostRepository} from "app/data/source/repository/host-repository";
import {InvoiceComponent} from 'app/view/invoice/invoice.component';
import {InvoiceListAdapterComponent} from 'app/view/invoice/invoice-list/invoice-list-adapter/invoice-list-adapter.component';
import {InvoiceDetailComponent} from 'app/view/invoice/invoice-detail/invoice-detail.component';
import {InvoiceCloud} from "app/data/source/cloud/invoice-cloud";
import {InvoiceRepository} from "app/data/source/repository/invoice-repository";
import {InvoiceDetailAdapterComponent} from './view/invoice/invoice-detail/invoice-detail-adapter/invoice-detail-adapter.component';
import {ContractListAdapterComponent} from './view/admin/contracts/category-list/contract-list-adapter/contract-list-adapter.component';
import {ContractDetailComponent} from './view/admin/contracts/contract-detail/contract-detail.component';
import {InvoiceDetailOrderAdapterComponent} from './view/invoice/invoice-detail/invoice-detail-adapter/invoice-detail-order-adapter/invoice-detail-order-adapter.component';
import {DividerComponent} from './view/custom/divider/divider.component';
import {PaymentRepository} from "app/data/source/repository/payment-repository";
import {AppRoute} from "app/infrastructure/services/app_route";
import {InvoiceListComponent} from "app/view/invoice/invoice-list/invoice-list.component";
import {signatureComponents} from "app/view/admin/signatures/signature.component";
import {SignatureApi} from "app/data/source/cloud/signature-api";
import {SignatureCloud} from "app/data/source/cloud/signature-cloud";
import {SignatureRepository} from "app/data/source/repository/signature-repository.service";
import {productComponents} from "app/view/admin/products/product.component";
import {ProductCloud} from "app/data/source/cloud/product-cloud.service";
import {ProductRepository} from "app/data/source/repository/product-repository";
import ptBr from '@angular/common/locales/pt';
import {DashCloud} from './data/source/cloud/dash-cloud';
import {DashRepository} from './data/source/repository/dash-repository';
import {InvoiceOverduesAdapterComponent} from './view/invoice/invoice-detail/invoice-overdues-adapter/invoice-overdues-adapter.component';

registerLocaleData(ptBr)

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
  };
  return new Configuration(params);
}

let Cloud = [
  AccountCloud,
  AlarmApi,
  AdminApi,
  BrandCloud,
  CategoryCloud,
  ChipCloud,
  CommandApi,
  ContractCloud,
  FeatureCloud,
  HostCloud,
  InvoiceCloud,
  LogApi,
  PaymentCloud,
  PlainApi,
  PlainCloud,
  ProductCloud,
  ProviderCloud,
  SignatureApi,
  SignatureCloud
];

let Repository = [
  AccountRepository,
  BrandRepository,
  CategoryRepository,
  ChipRepository,
  ContractRepository,
  DashRepository,
  DeviceRepository,
  FeatureRepository,
  HostRepository,
  InvoiceRepository,
  KodakRepository,
  PaymentRepository,
  PlainRepository,
  ProductRepository,
  ProviderRepository,
  SignatureRepository
];

let materialComponents = [
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatCardModule,
  MatInputModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatTableModule,
  MatRadioModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatExpansionModule,
  MatGridListModule,
  MatMenuModule,
  MatButtonToggleModule
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    brandComponents,
    categoryComponents,
    chipsComponents,
    contractComponents,
    featureComponents,
    productComponents,
    iconsComponents,
    plainComponents,
    signatureComponents,
    RegisterComponent,
    HomeComponent,
    ForgotComponent,
    DeviceFormComponent,
    InvalidLoginDialogComponent,
    DevComponent,
    ErrorGenericComponent,
    PaymentListComponent,
    PaymentCreateComponent,
    FindComponent,
    SetupComponent,
    DeviceListAdapterComponent,
    DeviceImageComponent,
    DeviceComponent,
    DeviceListComponent,
    SelectIconDialogComponent,
    InputImeiDirective,
    PaymentComponent,
    AlarmComponent,
    AlarmListComponent,
    ReportHistoryComponent,
    ReportAccComponent,
    ReportsComponent,
    CommandsComponent,
    BlockCommandComponent,
    DeviceAutocompleteComponent,
    AccountComponent,
    ChangePasswordComponent,
    YesNoDialogComponent,
    LocationComponent,
    MapsComponent,
    MapMarkerComponent,
    MapHistoryComponent,
    ListDevicesComponent,
    AdapterMapList,
    IconDetailComponent,
    DialogDetailsComponent,
    DialogHistoryDetailsComponent,
    StreetViewComponent,
    DialogDiagnosticComponent,
    TermsComponent,
    RegisterSuccessComponent,
    ConfirmPasswordDirective,
    GraphicsComponent,
    GraphicsResume,
    AlarmAdapterComponent,
    LoadingComponent,
    ReportDistanceComponent,
    AlarmHeaderBaseComponent,
    PaymentsAdapterComponent,
    DeviceImageNameComponent,
    SearchStatusNullComponent,
    HistoryMapComponent,
    AnchorComponent,
    MapAnchorComponent,
    DialogBlockKnowMoreComponent,
    HelpComponent,
    LogListComponent,
    LogAdapterComponent,
    AdminComponent,
    StatusComponent,
    CredentialsComponent,
    PendentComponent,
    PendentDeviceIconComponent,
    IconAdapterComponent,
    FipeManufacturerComponent,
    FipeModelComponent,
    ChipSearchComponent,
    ChipItemComponent,
    InvoiceComponent,
    InvoiceListComponent,
    InvoiceListAdapterComponent,
    InvoiceDetailComponent,
    InvoiceDetailAdapterComponent,
    ContractListAdapterComponent,
    ContractDetailComponent,
    InvoiceDetailOrderAdapterComponent,
    DividerComponent,
    InvoiceOverduesAdapterComponent
  ], imports: [
    ApiModule.forRoot(apiConfigFactory),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    materialComponents,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    ChartsModule,
    LottieAnimationViewModule,
    LayoutModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot(),
    Angulartics2Module.forRoot({
      pageTracking: {
        autoTrackVirtualPages: true
      }
    })
  ], entryComponents: [
    ErrorGenericComponent,
    InvalidLoginDialogComponent,
    SelectIconDialogComponent,
    ChangePasswordComponent,
    YesNoDialogComponent,
    DialogDetailsComponent,
    DialogHistoryDetailsComponent,
    DialogDiagnosticComponent,
    PendentComponent,
    DialogBlockKnowMoreComponent
  ], providers: [
    {provide: BASE_PATH, useValue: 'https://v5-dot-apureh-web.appspot.com/_ah/api'},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    DefaultService,
    DashCloud,
    DeviceApi,
    GpsApi,
    Cloud,
    Repository,
    LocalData,
    AppService,
    AppRoute,
    CookieService,
    MaterialColorsService,
    GoogleMapsService,
    LogService,
    Utils,
    GoogleAddressProvider,
    MapRequestAddressProvider,
    [TokenSaveInteractor, TokenLoadInteractor],
    [UserSaveInteractor, UserLoadInteractor],
    {
      provide: MAT_DATE_LOCALE, useValue: 'pt-BR'
    },
    {
      provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check'
    },
    {
      provide: LOCALE_ID, useValue: 'pt'
    }
  ], bootstrap: [AppComponent]
})
export class AppModule {

}
