import {Injectable} from "@angular/core";
import {PlainCloud} from "app/data/source/cloud/plain-cloud";

@Injectable()
export class PlainApi {
  constructor(private cloud: PlainCloud) {

  }

  list(limit: number, category: number, active: boolean, cursor: null) {
    return this.cloud.list(category, limit, cursor)
  }
}
