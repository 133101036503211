import {Component, OnInit} from '@angular/core';
import {SignatureListComponent} from "app/view/admin/signatures/signature-list/signature-list.component";
import {SignatureFormComponent} from "app/view/admin/signatures/signature-form/signature-form.component";

@Component({
  selector: 'app-plain', templateUrl: './signature.component.html', styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

export const signatureComponents = [
  SignatureComponent, SignatureListComponent, SignatureFormComponent
];

export const signatureRoutes = {
  path: 'signatures', component: SignatureComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: SignatureListComponent
    }, {
      path: 'form/:id', component: SignatureFormComponent
    }, {
      path: 'form', component: SignatureFormComponent
    }
  ]
};
