import {Component, OnInit} from '@angular/core';
import {Feature} from "app/lib/api";
import {Router} from "@angular/router";
import {FeatureCloud} from "app/data/source/cloud/feature-cloud.service";
import {FeatureRepository} from "app/data/source/repository/feature-repository";

@Component({
  selector: 'app-features-list',
  templateUrl: './feature-list.component.html',
  styleUrls: ['./feature-list.component.scss']
})
export class FeatureListComponent implements OnInit {
  features: Array<Feature>;
  loading: boolean = false;

  constructor(
    private featureRepository: FeatureRepository,
    private  router: Router) {
  }

  ngOnInit() {
    this.load();
  }

  click() {

  }

  edit(feature: Feature) {
    this.router.navigate(["admin/features/edit", feature.id]);
  }

  private load(cache=true) {
    this.loading = true;
    this.featureRepository.listAll(100, null,cache)
      .subscribe(
        value => {
          this.loading = false;
          this.features = value
        }
      )
  }
}
