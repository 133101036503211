import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";
import {Utils} from "app/infrastructure/utils/Utils";
import {Device} from "app/lib/api";

@Component({
  selector: 'find-component',
  templateUrl: './find.component.html',
  styleUrls: ['./find.component.scss']
})
export class FindComponent implements OnInit {
  timeArray: string[] = [];
  startDate: string = new Date().toISOString();
  endDate: string = new Date().toISOString();
  endTime: string = (new Date().getHours()) + ':' + Utils.padLeft(new Date().getMinutes());
  endTimeArray: string[];
  error: string;
  startTimeArray: string[];
  startTime: string = (new Date().getHours() - 2) + ':' + Utils.padLeft(new Date().getMinutes());
  speed: number;

  @Output()
  result = new EventEmitter<FindResult>();

  @Input()
  speedEnable: boolean = false;

  @Input()
  maxInterval: number = 720;

  constructor(private appService: AppService) {
    this.initTimerArray();
  }

  private _device;

  get device() {
    return this._device;
  }

  set device(device) {
    if (device != undefined) {
      this._device = device;
      this.appService.lastDeviceSelected = device.id;
    }
  }

  selectDevice(device: Device) {
    this.device = device;
  }

  ngOnInit() {
    console.log(this.startTime);
  }

  onSubmit() {
    if (this._device == null) {
      this.error = "Selecione um equipamento";
      return
    }

    this.error = null;

    let findResult = new FindResult();

    findResult.startDate = this.convertToDate(this.startDate, this.startTime);
    findResult.endDate = this.convertToDate(this.endDate, this.endTime);

    if (findResult.startDate.getTime() > findResult.endDate.getTime()) {
      this.error = "A data inicial deve ser maior que a data final";

      return;
    }

    let number = findResult.endDate.getTime() - findResult.startDate.getTime();

    let hours = number / 3.6e+6;

    if (this.maxInterval > 0 && hours > this.maxInterval) {
      this.error = `Para essa busca o intervalo de tempo entre a data final e a data inicial deve ser menor que ${this.maxInterval} horas`;

      return;
    }

    findResult.speed = this.speed;

    findResult.device = this.device;

    this.result.emit(findResult);
  }

  filterTimer(stringTime: string): string[] {
    return this.timeArray.filter(value => {
      return value.indexOf(stringTime) === 0;
    })
  }

  private convertToDate(date: string, time: string) {
    let dateResult = new Date(date);
    dateResult.setHours(parseInt(time.substr(0, 2)), parseInt(time.substr(3, 4)));
    return dateResult;
  }

  private initTimerArray() {
    for (let i = 0; i < 24; i++) {
      let hour = i < 10 ? `0${i}` : `${i}`;

      for (let j = 0; j < 60; j += 15) {
        this.timeArray.push(`${hour}:${j < 10 ? `0${j}` : j}`);
      }
    }
  }
}

export class FindResult {
  device: Device;
  startDate: Date;
  endDate: Date;
  speed: number;
}
