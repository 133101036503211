import {Injectable} from "@angular/core";
import {LocalData} from "app/data/source/local/local-data";
import {PaymentCloud} from "app/data/source/cloud/payment-cloud.service";

@Injectable()
export class PaymentRepository {
  static BRAND_KEY = "brand";

  constructor(
    private paymentCloud: PaymentCloud,
    private localData: LocalData
  ) {
  }

  createInvoice(id) {
    return this.paymentCloud.createInvoice(id)
  }

  list(limit: number, deviceId: number, cursor: null) {
    return this.paymentCloud.list(limit, deviceId, cursor)
  }

  create(deviceId: number, plainId: number) {
    return this.paymentCloud.create(deviceId, plainId)
  }
}
