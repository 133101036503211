import {Component, Input, OnInit} from '@angular/core';
import {Contract, DefaultService} from "app/lib/api";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-contract-list-adapter',
  templateUrl: './contract-list-adapter.component.html',
  styleUrls: ['./contract-list-adapter.component.scss']
})
export class ContractListAdapterComponent implements OnInit {

  @Input() contract: Contract;

  constructor(private defaultService: DefaultService, private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  addInvoice() {
    this.defaultService.contractEndpointV1AddOrder(this.contract.id)
      .subscribe(contract => {
        this.contract = contract
      }, error => {
        new ErrorManager(error, this.dialog)
      })
  }
}
