import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MapMarkerComponent} from "./map-marker/map-marker.component";
import {MatDialog} from "@angular/material";
import {DialogDetailsComponent} from "app/view/custom/dialog-details/dialog-details.component";
import {AppService} from "app/infrastructure/services/app.service";
import {Device, GPS} from "app/lib/api";
import {Observable, Subscription} from "rxjs";
import "rxjs-compat/add/observable/timer";
import {DeviceRepository} from "app/data/source/repository/device-repository";
import {DeviceGPS} from "app/domain/model/gps-device";
import {CustomizeDialog, ErrorGenericComponent} from "app/view/custom/dialog/error-generic/error-generic.component";
import {HttpErrorResponse} from "@angular/common/http";
import {InvoiceRepository} from "app/data/source/repository/invoice-repository";

declare var loadedGoogleMaps: any;
let focus: boolean = true;

@Component({
  selector: 'app-location', templateUrl: './location.component.html', styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit, OnDestroy {
  autoCenter: boolean = true;
  deviceList: Device[] = [];
  gpsList: GPS[] = [];
  loading: boolean = true;
  received: number = 0;
  showInvoiceAlert = false;
  timer: Subscription;

  @ViewChild("mapmarker", {static: true}) mapMarker: MapMarkerComponent;

  private _subscription;

  constructor(private appService: AppService,
              private deviceRepository: DeviceRepository,
              private dialog: MatDialog,
              private invoiceRepository: InvoiceRepository,
              public zone: NgZone) {
    this.appService.setNavTitle("Início");
  }

  ngOnDestroy(): void {
    if (this._subscription != undefined) this._subscription.unsubscribe();
    if (this.timer != undefined) this.timer.unsubscribe();
    window.removeEventListener("focus", this.focus);
    window.removeEventListener("blur", this.blur);
  }

  ngOnInit() {
    this.invoiceRepository.list(true)
      .subscribe(invoiceList => {
        let filter = invoiceList.filter(invoice => invoice.status === "charge");
        this.showInvoiceAlert = filter.length > 0
      });

    this._subscription = this.deviceRepository.list()
      .subscribe(value => {
        this.deviceList = value;
      });

    window.addEventListener("focus", this.focus);
    window.addEventListener("blur", this.blur);

    this.startTimer();
  }

  blur() {
    focus = false;
  }

  focus() {
    focus = true;
  }

  getGPSPosition(): void {
    if (!loadedGoogleMaps) return;

    if (this.calc(300000)) {
      this.gpsList = [];
    }

    this.loading = true;
    this.appService.gpsApi.getAll()
      .then(value => {
        this.received = new Date().getTime();
        this.loading = false;
        this.gpsList = value.items;
      }, (reason: HttpErrorResponse) => {
        this.received = new Date().getTime();
        this.loading = false;
        if (reason.status == 401) {
          this.appService.logout(true);
        } else {
          this.dialog.closeAll();
          this.dialog.open(ErrorGenericComponent, {data: new CustomizeDialog(reason.status)})
        }
      })
  }

  center() {
    this.autoCenter = true;
    this.mapMarker.center()
  }

  centerGPS(gpsDevice: DeviceGPS) {
    this.mapMarker.centerGPS(gpsDevice.gps, 16);
  }

  showDetails(gpsDevice: DeviceGPS) {
    if (window.document.body.clientWidth < 959) {
      // this.showList = false;
    }

    this.centerGPS(gpsDevice);

    this.zone.run(() => {
      this.dialog.open(DialogDetailsComponent, {
        data: gpsDevice
      });
    })
  }

  drag() {
    this.autoCenter = false;
  }

  calc(interval) {
    return (new Date().getTime() - this.received) > interval;
  }

  private startTimer() {
    this.getGPSPosition();

    this.timer = Observable.timer(500, 1000)
      .subscribe(() => {
        if (focus && this.calc(30000)) {
          this.getGPSPosition();
        }
      });
  }
}
