import {Component, Input, OnInit} from '@angular/core';
import {OrderPackage} from "app/lib/api";

@Component({
  selector: 'app-invoice-detail-adapter',
  templateUrl: './invoice-detail-adapter.component.html',
  styleUrls: ['./invoice-detail-adapter.component.scss']
})
export class InvoiceDetailAdapterComponent implements OnInit {

  @Input() orderPackage: OrderPackage;

  constructor() {
  }

  ngOnInit() {
  }

}
