import {Component, Input, OnInit} from '@angular/core';
import {MapBase} from "app/view/custom/map-base/map-base";
import {GoogleMapsService} from "app/infrastructure/services/google-maps.service";
import {AppService} from "app/infrastructure/services/app.service";
import {GPS} from "app/lib/api";
import {DeviceGPS} from "app/domain/model/gps-device";
import {DeviceRepository} from "app/data/source/repository/device-repository";

@Component({
  selector: 'app-map-anchor', templateUrl: './map-anchor.component.html', styleUrls: ['./map-anchor.component.scss']
})
export class MapAnchorComponent extends MapBase implements OnInit {

  constructor(
    private appService: AppService,
    private mapService: GoogleMapsService,
    private deviceRepository: DeviceRepository) {
    super()
  }

  @Input() set gps(gps: GPS) {
    if (gps == undefined) return;

    this.checkLoadedMap().then(value => {
      this.deviceRepository.findIMEI(gps.imei)
        .filter(device => device != null)
        .map(device => new DeviceGPS(device, gps))
        .subscribe(deviceGPS => {
          this.mapService.createMarker(this.googleMaps, deviceGPS, () => {
          });
          this.centerGPS(gps, 16);
        });
    })
  }

  ngOnInit() {
    super.ngOnInit()
  }
}
