import {Component, OnInit} from '@angular/core';
import {FindResult} from "app/view/custom/find/find.component";
import {GPS} from "app/lib/api";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {MatDialog} from "@angular/material";
import {LogService} from "app/infrastructure/services/log.service";
import {Utils} from "app/infrastructure/utils/Utils";
import {GpsApi} from "app/data/source/cloud/gps-api";

@Component({
  selector: 'app-resume', templateUrl: './graphics-resume.component.html',
  styleUrls: ['./graphics-resume.component.css']
})
export class GraphicsResume implements OnInit {

  public speedData: Array<any> = [];
  public speedLabel: Array<string> = [];

  public accData: Array<any> = [];
  public accLabel: Array<string> = [];

  searched = false;
  empty = false;

  public screen_width: number = 0;
  public lineChartOptions: {
    responsive: true,

    legend: {
      display: false, labels: {
        fontColor: 'rgb(255, 99, 132)'
      }
    }
  };

  public lineChartColors: Array<any> = [{ // grey
    backgroundColor: 'rgba(148,159,177,0.2)', borderColor: 'rgba(148,159,177,1)',
    pointBackgroundColor: 'rgba(148,159,177,1)', pointBorderColor: '#fff', pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  }, { // dark grey
    backgroundColor: 'rgba(77,83,96,0.2)', borderColor: 'rgba(77,83,96,1)', pointBackgroundColor: 'rgba(77,83,96,1)',
    pointBorderColor: '#fff', pointHoverBackgroundColor: '#fff', pointHoverBorderColor: 'rgba(77,83,96,1)'
  }, { // grey
    backgroundColor: 'rgba(148,159,177,0.2)', borderColor: 'rgba(148,159,177,1)',
    pointBackgroundColor: 'rgba(148,159,177,1)', pointBorderColor: '#fff', pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  }];
  public lineChartType: string = 'line';
  public barChartType: string = 'bar';
  private loading: boolean;
  private gps: GPS[];

  constructor(private gpsApi: GpsApi, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.screen_width = window.screen.width - 48;
  }

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  public reset() {
    this.searched = false;
    this.empty = false;
    this.gps = null;
  }

  public onResult(findResult: FindResult) {
    this.loading = true;
    this.searched = true;

    let dateStart = findResult.startDate;
    let dateEnd = findResult.endDate;

    this.gpsApi.getHistory(findResult.device.id, dateStart, dateEnd, 100, true, null)
      .then(value => {
        this.gps = value.items;

        if (this.gps && this.gps.length > 0) {
          let speedData1 = this.getSpeedData();

          this.speedData = [{data: speedData1.map(value1 => value1.speed), label: "Velocidade"}];
          this.speedLabel = speedData1.map(value1 => value1.date);

          let accData1 = this.getAccData();

          this.accData = [{data: accData1.map(value1 => value1.value), label: "Ignição"}];
          this.accLabel = accData1.map(value1 => value1.date);

          LogService.debug(this.speedData);
        } else {
          this.empty = true
        }

        this.loading = false;
      }, error => {
        new ErrorManager(error, this.dialog);
        this.loading = false;
      })
  }

  public getSpeedData() {
    return this.gps.map((value) => {
      let gpsTime = Utils.getNormalizeDate(value);

      return {speed: value.speed, date: gpsTime.toLocaleString()}
    });
  }

  private getAccData() {
    let x;

    return this.gps
      .filter(value => {
        let result = x != value.acc;

        x = value.acc;

        return result;
      })
      .map(value => {
        let date = new Date(value.gpsTime).toLocaleString();

        if (value.acc == true) {
          return {value: 1, date: date};
        } else {
          return {value: 0, date: date};
        }
      });
  }
}
