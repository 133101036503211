import {DefaultService} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class DashCloud {
  constructor(private apiClient: DefaultService) {

  }

  contractUpgrade(contractId: number,
                  plainId?: number,
                  m2mId?: number,
                  deviceId?: number,
                  discountExpireAt?: Date,
                  discountAmount?: number) {
    return this.apiClient.dashEndpointV1ContractUpgradeV2(contractId,
      plainId,
      m2mId,
      deviceId,
      discountExpireAt,
      discountAmount)
  }

  contractCancel(contractId: number) {
    return this.apiClient.dashEndpointV1ContractCancel(contractId)
  }

  chipCancel(chipId: number) {
    return this.apiClient.dashEndpointV1ChipCancel(chipId)
  }
}
