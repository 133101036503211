import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
  selector: '[inputIMEI][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: InputImeiDirective,
      multi: true
    }
  ]
})
export class InputImeiDirective implements Validator {
  constructor() {
  }

  validate(c: AbstractControl): ValidationErrors | null {
    let value = c.value;

    if (value != null && value.length == 15) {
      return null;
    } else {
      return {
        imei: false
      };
    }
  }
}
