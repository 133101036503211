import {Injectable} from "@angular/core";
import {DefaultService} from "app/lib/api";

@Injectable()
export class BrandCloud {
  constructor(private defaultService: DefaultService) {
  }

  list() {
    return this.defaultService.brandEndpointV1List(100, null)
  }

  load(id) {
    return this.defaultService.brandEndpointV1Get(id)
  }

  save(brand: any) {
    return this.defaultService.brandEndpointV1Save(brand)
  }
}
