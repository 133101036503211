import {Component, OnInit} from '@angular/core';
import {ChipListComponent} from "app/view/admin/chips/chip-list/chip-list.component";
import {ChipFormComponent} from "app/view/admin/chips/chip-form/chip-form.component";
import {ChipSearchComponent} from "app/view/admin/chips/chip-search/chip-search.component";

@Component({
  selector: 'app-chip', templateUrl: './chip.component.html', styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

export const chipsComponents = [
  ChipComponent, ChipListComponent, ChipFormComponent
];

export const chipRoutes = {
  path: 'chips', component: ChipComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: ChipListComponent
    }, {
      path: 'form/:id', component: ChipFormComponent
    }, {
      path: 'form', component: ChipFormComponent
    },{
      path: 'search/:id', component: ChipSearchComponent
    }
  ]
};
