import {Component, Input, OnInit} from '@angular/core';
import {DefaultService, Device} from "app/lib/api";
import {DeviceRepository} from "app/data/source/repository/device-repository";

@Component({
  selector: 'app-device-image-name',
  templateUrl: './device-image-name.component.html',
  styleUrls: ['./device-image-name.component.scss']
})
export class DeviceImageNameComponent implements OnInit {

  @Input() device: Device;
  @Input() imageSize: number = 54;

  constructor(private defaultService: DeviceRepository) {
  }

  @Input() set hostId(hostId: number) {
    this.defaultService.list(true)
      .subscribe(value => {
        this.device = value.filter(value1 => value1.hostId == hostId)[0];
      })
  }

  ngOnInit() {
  }
}
