export class AlarmText {
  title: string;
  description: string;

  constructor(type: number) {
    let number = Number(type);

    switch (number) {
      //Speed
      case 0:
        this.title = "Velocidade";
        break;

      //Acc On
      case 1:
        this.title = "Ligar ignição";
        break;

      //Acc Off
      case 2:
        this.title = "Desligar ignição";
        break;

      //Low Battery
      case 11:
        this.title = "Nível da bateria";
        break;

      //Panic button
      case 7:
        this.title = "Botão de pânico";
        break;

      //Turn Off
      case 10:
        this.title = "Desconectar equipamento";
        break;

      //Chip
      case 18:
        this.title = "Recarga de chip";
        break;
    }
  }
}
