import {Component, OnInit} from '@angular/core';
import {MatDialog, MatSnackBar} from "@angular/material";
import {ChangePasswordComponent} from "app/view/account/change-password/change-password.component";
import {Address, Login, Phone, Profile} from "app/lib/api";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {AppService} from "app/infrastructure/services/app.service";
import {AccountCloud} from "app/data/source/cloud/account-cloud.service";

@Component({
  selector: 'app-account', templateUrl: './account.component.html', styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  login: Login;
  profile: Profile = {} as Profile;
  private loading: boolean;

  constructor(appService: AppService, private accountApi: AccountCloud, private dialog: MatDialog, private snackBar: MatSnackBar) {
    appService.setNavTitle("Editar conta")
  }

  ngOnInit() {
    this.profile.phone = {} as Phone;
    this.profile.addresses = [{} as Address];
    this.loadInfo()
  }

  save() {
    this.accountApi.save(this.login)
      .concat(this.accountApi.saveProfile(this.profile))
      .subscribe(
        () => {
          return this.snackBar.open("Alterações salvas com sucesso", null, {
            horizontalPosition: "end", duration: 5000
          });
        },
        error => new ErrorManager(error, this.dialog)
      )
  }

  changePassword() {
    this.dialog.open(ChangePasswordComponent);
  }

  private loadInfo() {
    this.loading = true;

    this.accountApi.get()
      .do(login => this.login = login)
      .flatMap(login => this.accountApi.loadProfile(0))
      .map(profile => {
        if (profile.addresses == undefined) {
          profile.addresses = [{}];
        }

        if (profile.phone == undefined) {
          profile.phone = {};
        }

        return profile
      })
      .do(profile => this.profile = profile)
      .subscribe(value => {
        this.loading = false;
      }, reason => {
        console.log(reason);
        new ErrorManager(reason, this.dialog);
      })
  }
}
