import {Component, OnInit} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";

@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.scss']
})
export class AlarmComponent implements OnInit {

  constructor(appService:AppService) {
    appService.setNavTitle("Alarme");
  }

  ngOnInit() {
  }

}
