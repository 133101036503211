import {CollectionResponseProduct, DefaultService, Product} from "app/lib/api";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export class ProductCloud {
  constructor(private apiClient: DefaultService) {

  }

  get(id: any): Observable<Product> {
    return this.apiClient.productEndpointV1Get(id)
  }

  save(feature) {
    return this.apiClient.productEndpointV1Save(feature)
  }

  listAll(limit, cursor): Observable<CollectionResponseProduct> {
    return this.apiClient.productEndpointV1ListAll(limit, cursor)
  }
}
