import {DefaultService} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class CommandApi {
  constructor(private apiClient: DefaultService) {

  }

  unblock(deviceId: number) {
    return this.apiClient.commandEndpointV1Unblock(null, deviceId).toPromise()
  }

  block(deviceId: number) {
    return this.apiClient.commandEndpointV1Block(null, deviceId).toPromise()
  }
}
