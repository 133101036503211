import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FipeService} from "app/view/custom/fipe/fipe.service";
import {FipeMarcas, FipeTipos} from "app/data/data/fipe/fipe";
import {FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-fipe-manufacturer',
  templateUrl: './fipe-manufacturer.component.html',
  styleUrls: ['./fipe-manufacturer.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: FipeManufacturerComponent, multi: true}
  ]
})
export class FipeManufacturerComponent implements OnInit {
  manufacturers: Array<FipeMarcas> = [];
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  @Output() selectedManufacturer: EventEmitter<FipeMarcas> = new EventEmitter();
  //The internal data model
  private innerValue: any = '';
  //by the Control Value Accessor
  private onTouchedCallback: () => void = null;
  //Placeholders for the callbacks which are later provided
  private onChangeCallback: (_: any) => void = null;

  constructor(private fipeService: FipeService) {
  }

  @Input() set type(type: string) {
    this.fipeService.manufacturersSubject.subscribe(value => {
      let fipeTipo = FipeTipos[type];

      if (value.has(fipeTipo)) {
        this.manufacturers = value.get(fipeTipo);
        this.options = this.manufacturers.map(value1 => value1.fipe_name);

        this.myControl.setValue(this.myControl.value, {emitEvent: true});
      }
    });

    this.fipeService.loadManufacturers(type);
  }

  //get accessor
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(startWith(''), map(value => this._filter(value)));

    this.myControl.valueChanges.subscribe(model => {
      let marcas = this.manufacturers.filter(fipeMarcas => {
        if (fipeMarcas != undefined) {
          return fipeMarcas.fipe_name == model
        }
      })[0];

      if (marcas != undefined) {
        this.selectedManufacturer.emit(marcas)
      }
    });
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  private _filter(value: string): string[] {
    if (value != undefined) {
      const filterValue = value.toLowerCase();
      return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }

    return this.options;
  }
}
