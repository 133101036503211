import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-search-status-null',
  templateUrl: './search-status-null.component.html',
  styleUrls: ['./search-status-null.component.scss']
})
export class SearchStatusNullComponent implements OnInit {
  lottieConfig: Object;

  constructor() {
  }

  ngOnInit() {
    this.lottieConfig={
      path: 'assets/empty_box.json'
    }
  }

}
