import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BrandRepository} from "app/data/source/repository/brand-repository";
import {KodakRepository} from "app/data/source/repository/kodak-repository";
import {Brand} from "app/lib/api/model/brand";

@Component({
  selector: 'app-brand-form', templateUrl: './brand-form.component.html', styleUrls: ['./brand-form.component.scss']
})
export class BrandFormComponent implements OnInit {
  brand: Brand = {};
  loading = true;

  constructor(private brandRepository: BrandRepository, private kodakRepository: KodakRepository, private activate: ActivatedRoute) {
  }

  ngOnInit() {
    this.activate.params.subscribe(params => {
      let paramId = params['id'];

      if (paramId != undefined) {
        this.brandRepository.load(paramId)
          .subscribe(brand => {
            this.brand = brand;
            this.loading = false;
          })
      } else {
        this.loading = false;
      }
    })
  }

  changeFile(file) {
    this.kodakRepository.uploadImage(file)
      .subscribe(value => {
        this.brand.image.id = value.id;
        this.brand.image.url = value.url;
        this.save()
      });
  }

  save() {
    this.loading = true;

    this.brandRepository.save(this.brand)
      .subscribe(value => this.loading = false)
  }
}
