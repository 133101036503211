import {LocalData} from "app/data/source/local/local-data";
import {Injectable} from "@angular/core";

@Injectable()
export class UserSaveInteractor {
  constructor(private localData: LocalData) {
  }

  execute(login) {
    this.localData.user = login;
  }
}
