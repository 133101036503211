import {DefaultService, Device, DeviceIcon} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class DeviceApi {
  constructor(private apiClient: DefaultService) {
  }

  list(limit: number, cursor: null) {
    return this.apiClient.deviceEndpointV1List(limit, cursor)
  }

  listDeviceIcons(limit: number, cursor: null) {
    return this.apiClient.deviceEndpointV1ListDeviceIcons(limit, cursor).toPromise()
  }

  basePath() {
    return this.apiClient.configuration.basePath
  }

  updateDeviceIcon(deviceIcon: DeviceIcon) {
    return this.apiClient.deviceEndpointV1SaveDeviceIcon(deviceIcon)
  }

  removeDeviceIcon(deviceIconId: number) {
    return this.apiClient.deviceEndpointV1DeleteDeviceIcon(deviceIconId)
  }

  getDeviceIcon(iconId: any) {
    return this.apiClient.deviceEndpointV1GetDeviceIcon(iconId).toPromise()
  }

  save(device: Device): Promise<Device> {
    return this.apiClient.deviceEndpointV1Save(device.hostId, device).toPromise()
  }

  delete(deviceId): Promise<any> {
    return this.apiClient.deviceEndpointV1Remove(deviceId).toPromise()
  }

  tempLinkDeviceChip(deviceId, chipId) {
    return this.apiClient.deviceEndpointV1LinkDeviceChipContract(deviceId, chipId)
  }
}
