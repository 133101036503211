import {Component, OnInit} from '@angular/core';
import {MatDialog, MatSnackBar} from "@angular/material";
import {SelectIconDialogComponent} from "app/view/devices/form/select-icon-dialog/select-icon-dialog.component";
import {ActivatedRoute, Router} from "@angular/router";
import {AppService} from "app/infrastructure/services/app.service";
import {YesNoData, YesNoDialogComponent} from "app/view/custom/dialog/yes-no-dialog/yes-no-dialog.component";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {FipeMarcas, FipeTipos} from "app/data/data/fipe/fipe";
import {DeviceApi} from "app/data/source/cloud/device-api";
import {Device, SIMCard, Vehicle} from "app/lib/api";
import {DeviceRepository} from "app/data/source/repository/device-repository";

@Component({
  selector: 'app-device-form', templateUrl: './device-form.component.html', styleUrls: ['./device-form.component.css'],
})
export class DeviceFormComponent implements OnInit {
  loading = true;
  public device: Device;
  keys = Object.keys(FipeTipos).filter(k => typeof FipeTipos[k as any] === "string"); // ["A", "B"]

  private selectedId: number;
  private manufacturer: FipeMarcas = {} as FipeMarcas;

  constructor(
    private deviceRepository: DeviceRepository,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private deviceApi: DeviceApi,
    private snack: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.device = this.initVehicle(this.device);
  }

  ngOnInit() {
    this.activatedRoute.params
      .subscribe(params => {
        this.selectedId = params['id'];

        if (this.selectedId != null) {
          this.loadDevice();
          this.appService.setNavSubtitle("Editar")
        } else {
          this.loading = false;
          this.appService.setNavSubtitle("Cadastrar")
        }
      });
  }

  save() {
    this.deviceApi.save(this.device).then(value => {
      this.success();
    }, reason => {
      this.loading = false;
    });
  }

  delete() {
    let yesNoData = new YesNoData("Excluir equipamento", "Deseja excluir esse equipamento?");

    let matDialogRef = this.dialog.open(YesNoDialogComponent, {
      data: yesNoData, hasBackdrop: true
    });

    matDialogRef.afterClosed().subscribe(value => {
      if (value == true) {
        this.loading = true;

        this.deviceApi.delete(this.device.id).then(value => {
            this.deviceRepository.list(false)
              .subscribe(
                value2 => {
                  this.routeToList();
                });
          }
          , reason => {
            new ErrorManager(reason, this.dialog)
          })
      }
    });
  }

  onSubmit() {
    this.loading = true;

    this.save();
  }

  showIconsCars() {
    let matDialogRef = this.dialog.open(SelectIconDialogComponent);

    matDialogRef.afterClosed().subscribe(value => {
      if (value != null) {
        this.device.deviceIcon = value;
      }
    })
  }

  onSelectManufacturer(event) {
    this.manufacturer = event;
  }

  private initVehicle(device: Device) {
    if (device == null) {
      device = {} as Device
    }

    if (device.simCard == null) {
      device.simCard = {} as SIMCard;
    }

    if (device.vehicle == null) {
      device.vehicle = {} as Vehicle;
    }

    return device;
  }

  private loadDevice() {
    this.deviceRepository.list()
      .subscribe(value => {
        value.forEach(value => {
          if (value.id == this.selectedId) {
            this.device = this.initVehicle(value);
            this.loading = false;
          }
        });

        if (this.device.id == null) {
          this.routeToList();
        }
      });
  }

  private success() {
    this.deviceRepository.list(false)
      .subscribe(
        value2 => {
          this.snack.open('As alterações foram salvas', "", {
            duration: 5000, horizontalPosition: "right"
          });

          this.routeToList();
        },
        reason => {
          this.loading = false;
        })
  }

  private routeToList() {
    this.router.navigateByUrl('/device/list');
  }
}

