/// <reference types="markerwithlabel" />
/// <reference types="@types/googlemaps" />

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";
import {GoogleMapsService} from "app/infrastructure/services/google-maps.service";
import {GPS} from "app/lib/api";
import {MapBase} from "app/view/custom/map-base/map-base";
import {DeviceGPS} from "app/domain/model/gps-device";
import {DeviceRepository} from "app/data/source/repository/device-repository";

declare var loadedGoogleMaps: boolean;

@Component({
  selector: 'map-marker', templateUrl: './map-marker.component.html', styleUrls: ['./map-marker.component.scss']
})
export class MapMarkerComponent extends MapBase implements OnInit {
  @Input() loading: boolean;

  @Output() details: EventEmitter<DeviceGPS> = new EventEmitter<DeviceGPS>();

  @Input() autoCenter: boolean;

  @Output() drag: EventEmitter<void> = new EventEmitter();

  constructor(private appService: AppService,
              private googleMapsService: GoogleMapsService,
              private deviceRepository: DeviceRepository) {
    super()
  }

  @Input() set gps(gpsList: GPS[]) {
    this.clearMarkers();
    this.clearBounds();

    this.checkLoadedMap().then(value => {
      if (gpsList != null && gpsList.length > 0) {
        gpsList.forEach(gps => this.addMarker(gps));

        if (this.autoCenter) {
          this.center()
        }

        this.googleMaps.addListener('drag', () => {
          this.drag.emit();
        });
      }
    })
  }

  ngOnInit() {
    super.ngOnInit();
  }

  private addMarker(gps: GPS) {
    let latLng = new google.maps.LatLng(gps.latitude, gps.longitude);

    this.deviceRepository.findIMEI(gps.imei)
      .filter(device => device != null)
      .map(value => new DeviceGPS(value, gps))
      .subscribe(deviceGPS => {
        let marker = this.googleMapsService.createMarker(this.googleMaps, deviceGPS, () => {
          this.details.emit(deviceGPS)
        });

        this.markersList.push(marker);

        this.latLngBounds.extend(latLng);
      });
  }
}
