import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Device, GPS} from "app/lib/api";
import {DeviceGPS} from "app/domain/model/gps-device";

@Component({
  selector: 'list-devices', templateUrl: './list-devices.component.html', styleUrls: ['./list-devices.component.scss']
})
export class ListDevicesComponent implements OnInit {
  gpsDevice: DeviceGPS;

  @Input() gpsList: GPS[];

  @Input() showInvoiceAlert: boolean;

  @Input() deviceList: Device[];

  @Input() loading: boolean;

  @Output() info = new EventEmitter<DeviceGPS>();

  @Output() center = new EventEmitter<DeviceGPS>();

  @Output() centerAll = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  getInfo(event) {
    this.info.emit(event);
  }

  getCenterAll() {
    this.centerAll.emit();
  }

  getCenter(event) {
    this.center.emit(event);
  }

  getGPS(device: Device) {
    if (device == undefined || this.gpsList == undefined) {
      return;
    }

    return this.gpsList.find(gps => {
      return gps.imei == device.imei;
    })
  }
}
