import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-invalid-login-dialog',
  templateUrl: './invalid-login-dialog.component.html',
  styleUrls: ['./invalid-login-dialog.component.css']
})
export class InvalidLoginDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
