import {Component, Input, OnInit} from '@angular/core';
import {Invoice} from "app/lib/api";

@Component({
  selector: 'app-invoice-list-adapter',
  templateUrl: './invoice-list-adapter.component.html',
  styleUrls: ['./invoice-list-adapter.component.scss']
})
export class InvoiceListAdapterComponent implements OnInit {

  @Input() invoice: Invoice;

  constructor() {
  }

  ngOnInit() {
  }

  colorValue() {
    switch (this.invoice.status) {
      case "open":
        return "app-color-grey-slot-2";
      case "done" :
        return "app-color-grey-slot-2 & app-txt-line_through";
      case "charge" :
        return "app-color-feedback-slot-2";
      case "postponed":
        return "app-color-grey-slot-2";
    }
  }

  color() {
    switch (this.invoice.status) {
      case "open":
        return "app-color-feedback-slot-1";
      case "done" :
        return "app-color-feedback-slot-3";
      case "charge" :
        return "app-color-feedback-slot-2";
      case "postponed":
        return "app-color-grey-slot-2";
    }
  }

  colorIndicator() {
    switch (this.invoice.status) {
      case "open":
        return "app-color-feedback-slot-1-bg";
      case "done" :
        return "app-color-feedback-slot-3-bg";
      case "charge" :
        return "app-color-feedback-slot-2-bg";
      case "postponed":
        return "app-color-grey-slot-2-bg";
    }
  }
}
