import {DeviceApi} from "app/data/source/cloud/device-api";
import {LocalData} from "app/data/source/local/local-data";
import {Observable} from "rxjs";
import {Device} from "app/lib/api";
import {Injectable} from "@angular/core";
import "rxjs-compat/add/operator/mergeMap";
import "rxjs-compat/add/operator/find";

@Injectable()
export class DeviceRepository {
  constructor(private deviceCloud: DeviceApi, private localData: LocalData) {
  }

  list(cache = true) {
    return new Observable<Device[]>(subscribeOn => {
      if (cache) {
        let devices: Device[] = this.localData.devices;

        if (devices != null && devices.length > 0) {
          subscribeOn.next(devices);
          subscribeOn.complete();
          return;
        }
      }

      this.deviceCloud.list(100, null)
        .map(payload => payload.items || [])
        .map(devices => this.sortDeviceList(devices))
        .do(devices => this.localData.devices = devices)
        .subscribe(devices => {
          subscribeOn.next(devices);
          subscribeOn.complete();
        }, error => {
          console.log(error);
          subscribeOn.error(error);
        })
    })
  }

  sortDeviceList(devices) {
    return devices.sort((a, b) => {
      return this.naturalSorter(a, b);
    });
  }

  naturalSorter(as, bs) {
    let a, b, a1, b1, i = 0, n, L, rx = /(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;
    if (as === bs) return 0;
    a = as.name.toLowerCase().match(rx);
    b = bs.name.toLowerCase().match(rx);
    L = a.length;
    while (i < L) {
      if (!b[i]) return 1;
      a1 = a[i], b1 = b[i++];
      if (a1 !== b1) {
        n = a1 - b1;
        if (!isNaN(n)) return n;
        return a1 > b1 ? 1 : -1;
      }
    }
    return b[i] ? -1 : 0;
  }

  findIMEI(imei) {
    return this.list()
      .flatMap(value1 => value1)
      .find(value1 => value1.imei == imei)
  }

  findID(id): Observable<Device | undefined> {
    return this.list()
      .flatMap(devices => devices)
      .find(device => device.id == id)
  }

  linkDeviceChip(deviceId, chipM2MId): Observable<Device> {
    return this.deviceCloud.tempLinkDeviceChip(deviceId, chipM2MId);
  }
}
