import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Category, Feature, Plain, Signature} from "app/lib/api";
import {PlainRepository} from "app/data/source/repository/plain-repository";
import {CategoryRepository} from "app/data/source/repository/category-repository";
import {FeatureRepository} from "app/data/source/repository/feature-repository";
import {SignatureRepository} from "app/data/source/repository/signature-repository.service";

@Component({
  selector: 'app-plain-form', templateUrl: './plain-form.component.html', styleUrls: ['./plain-form.component.scss']
})
export class PlainFormComponent implements OnInit {
  plain: Plain = {};
  categories: Category[] = [];
  features: Feature[] = [];
  signatures: Signature[] = [];
  loading = true;

  constructor(private plainRepository: PlainRepository, private categoryRepository: CategoryRepository, private featureRepository: FeatureRepository, private signatureRepository: SignatureRepository, private activate: ActivatedRoute) {
  }

  ngOnInit() {
    this.activate.params.subscribe(value => {
      let paramId = value['id'];

      if (paramId != undefined) {
        this.plainRepository.load(paramId)
          .subscribe(value1 => {
            this.plain = value1;
            this.loading = false;
          })
      } else {
        this.loading = false;
      }
    });

    this.loadCategories();
    this.loadFeatures();
    this.loadSignatures();
  }

  save() {
    this.loading = true;

    this.plainRepository.save(this.plain)
      .subscribe(value => this.loading = false)
  }

  private loadFeatures() {
    this.featureRepository.listAll(100, null)
      .subscribe(value => {
        this.features = value
      })
  }

  private loadCategories() {
    this.categoryRepository.list(100, null)
      .subscribe(value => {
        this.categories = value
      })
  }

  private loadSignatures() {
    this.signatureRepository.list(100, null, true)
      .subscribe(value => this.signatures = value)
  }
}
