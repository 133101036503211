import {Component, OnInit} from '@angular/core';
import {PlainListComponent} from "app/view/admin/plains/plain-list/plain-list.component";
import {PlainFormComponent} from "app/view/admin/plains/plain-form/plain-form.component";

@Component({
  selector: 'app-plain',
  templateUrl: './plain.component.html',
  styleUrls: ['./plain.component.scss']
})
export class PlainComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

export const plainComponents = [
  PlainComponent,
  PlainListComponent,
  PlainFormComponent
];

export const plainRoutes = {
  path: 'plains', component: PlainComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: PlainListComponent
    }, {
      path: 'form/:id', component: PlainFormComponent
    }, {
      path: 'form', component: PlainFormComponent
    }
  ]
};
