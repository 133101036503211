import {Injectable} from '@angular/core';
import {environment} from "environments/environment";

@Injectable()
export class LogService {
  public debugMode: boolean = true;

  constructor() {
  }

  static debug(object: any) {
    if (!environment.production) {
      console.log(JSON.stringify(object, null, 4));
    }
  }
}
