import {Injectable} from "@angular/core";
import {SignatureCloud} from "app/data/source/cloud/signature-cloud";

@Injectable()
export class SignatureApi {
  constructor(private cloud: SignatureCloud) {

  }

  list(limit: number, cursor: null) {
    return this.cloud.list(limit, cursor)
  }
}
