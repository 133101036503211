/**
 * apureh-web.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Category { 
    description?: string;
    enable?: boolean;
    id?: number;
    mode?: Category.ModeEnum;
    name?: string;
}
export namespace Category {
    export type ModeEnum = 'SERVICE' | 'PRODUCT';
    export const ModeEnum = {
        SERVICE: 'SERVICE' as ModeEnum,
        PRODUCT: 'PRODUCT' as ModeEnum
    };
}
