import {Component, OnInit} from '@angular/core';
import {DeviceIcon} from "app/lib/api";
import {ActivatedRoute, Router} from "@angular/router";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {MatDialog} from "@angular/material";
import {HttpClient} from "@angular/common/http";
import {DeviceApi} from "app/data/source/cloud/device-api";
import {KodakRepository} from "app/data/source/repository/kodak-repository";

@Component({
  selector: 'app-form-icon',
  templateUrl: './icon-form.component.html',
  styleUrls: ['./icon-form.component.scss']
})
export class IconFormComponent implements OnInit {
  private deviceIcon: DeviceIcon = {} as DeviceIcon;
  private loading: boolean;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private deviceApi: DeviceApi,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private kodakRepository: KodakRepository
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(value => {

      let iconId = value["id"];

      if (iconId != undefined) {
        this.loading = true;

        this.deviceApi.getDeviceIcon(iconId)
          .then(value1 => {
            this.loading = false;
            this.deviceIcon = value1;
          })
      }
    })
  }

  url() {
    return this.deviceApi.basePath();
  }

  save() {
    this.update();
  }

  remove() {
    this.loading = true;
    this.deviceApi.removeDeviceIcon(this.deviceIcon.id)
      .subscribe(
        value => this.routeTo(),
        reason => {
          this.loading = false;
          new ErrorManager(reason, this.dialog);
        }
      )
  }

  update() {
    this.loading = true;
    this.deviceApi.updateDeviceIcon(this.deviceIcon)
      .subscribe(
        value => {
          this.loading = false
        },
        reason => {
          this.loading = false;
          new ErrorManager(reason, this.dialog);
        }
      );
  }

  deleteImage(id) {
    this.kodakRepository.deleteImage(id)
      .subscribe(
        value => {
          this.deviceIcon.icon = null;
          this.save()
        }
      );
  }

  changeFile(file) {
    this.kodakRepository.uploadImage(file)
      .subscribe(
        value => {
          this.deviceIcon.icon = value.id;
          this.deviceIcon.urlIcon = value.url;
          this.save();
        }
      );
  }

  private routeTo() {
    this.route.navigateByUrl("/admin/icons");
  }
}
