import {DefaultService} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class LogApi {
  constructor(private apiDefault: DefaultService) {
  }

  list(limit, cursor) {
    return this.apiDefault.logEndpointV1List(limit, cursor).toPromise()
  }
}
