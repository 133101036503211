// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  token: "APUREH 0BRtvK6+c/375OImTcN2ZCc6WZGrtUFIxBQhAhUnwTv+fhfZipHb4QuO2VKkFO20RT5P6iNxH4/zrtWQ8HJGymcxm6SpD3PwJOrHE+w6MBU5TjF5g4UwwOWTzc8ExMLPSqXodMMDO3ENFd1aX2XB7LAy5NLf0Q2r"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
