import {Component, Input, OnInit} from '@angular/core';
import {DeviceApi} from "app/data/source/cloud/device-api";
import {Device} from "app/lib/api";

@Component({
  selector: 'device-image', templateUrl: './device-image.component.html', styleUrls: ['./device-image.component.scss']
})
export class DeviceImageComponent implements OnInit {
  imageUrl: string;
  sizePX: string = "56px";

  constructor(private deviceApi: DeviceApi) {
  }

  _size: number = 56;

  @Input() set size(size) {
    this._size = size;
    this.sizePX = size + "px"
  }

  @Input() set imageId(imageId: string) {
    this.imageUrl = DeviceImageComponent.getImageUrl(this.deviceApi, imageId, this._size);
  }

  @Input() set iconId(iconId: number) {
    this.imageUrl = DeviceImageComponent.getIconId(this.deviceApi, iconId, this._size);
  }

  @Input() set device(device: Device) {
    if (device != null) {
      this.imageUrl = DeviceImageComponent.getIconId(this.deviceApi, device.deviceIcon, this._size,)
    }
  }

  static getImageUrl(deviceApi: DeviceApi, imageId: String, size: number,): string {
    return `${deviceApi.basePath()}/kodak?size=${size}&image_id=${imageId}`;
  }

  static getIconId(deviceApi: DeviceApi, iconId: number, size: number): string {
    return `${deviceApi.basePath()}/kodak?size=${size}&icon_id=${iconId}`;
  }

  ngOnInit() {
  }
}
