import {Category, DefaultService} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class ProviderCloud {
  constructor(private apiClient: DefaultService) {

  }

  get(categoryId) {
    return this.apiClient.providerEndpointV1Get(categoryId)
  }

  list(limit, cursor) {
    return this.apiClient.providerEndpointV1List(limit, cursor)
  }

  save(category: Category) {
    return this.apiClient.providerEndpointV1Save(category)
  }
}
