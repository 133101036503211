import {Component, OnInit} from '@angular/core';
import {Device} from "app/lib/api";
import {AppService} from "app/infrastructure/services/app.service";
import {MatDialogRef} from "@angular/material";
import {DeviceRepository} from "app/data/source/repository/device-repository";

@Component({
  selector: 'app-pendent', templateUrl: './pendent.component.html', styleUrls: ['./pendent.component.scss']
})
export class PendentComponent implements OnInit {
  devices: Device[] = [];

  constructor(private appService: AppService,
              private dialogRef: MatDialogRef<PendentComponent>,
              private deviceRepository: DeviceRepository) {
  }

  ngOnInit() {
    this.deviceRepository.list()
      .flatMap(value => value)
      .subscribe(value => {
        if (value.deviceIcon == undefined) {
          this.devices.push(value);
        }
      });
  }

  close() {
    this.dialogRef.close();
  }
}
