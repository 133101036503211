import {Component, Inject, OnInit} from '@angular/core';
import {YesNoDialogComponent} from "app/view/custom/dialog/yes-no-dialog/yes-no-dialog.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-error-generic',
  templateUrl: './error-generic.component.html',
  styleUrls: ['./error-generic.component.css']
})
export class ErrorGenericComponent implements OnInit {
  public dialog: CustomizeDialog;

  constructor(private dialogRef: MatDialogRef<YesNoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data?: CustomizeDialog) {
    this.dialog = data || new CustomizeDialog(0);
  }

  ngOnInit() {
  }
}

export class CustomizeDialog {
  public errorCode: string;
  public context: string = "Verifique as informações ou tente novamente mais tarde.";
  public title: string = "Ops, houve um erro!";

  constructor(errorCode: number) {
    if (errorCode > 0) {
      this.errorCode = `Código de erro ${errorCode}`
    }
  }
}
