import {Component, OnInit} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";

@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.scss']
})
export class CommandsComponent implements OnInit {

  constructor(appService: AppService) {
    appService.setNavTitle("Comando");
  }

  ngOnInit() {

  }
}
