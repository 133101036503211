import {Alarm, Device} from "app/lib/api";

export class AlarmDevice {
  alarm: Alarm;
  device: Device;

  constructor(alarm: Alarm, device: Device) {
    this.alarm = alarm;
    this.device = device;
  }

  private _speed;

  get speed() {
    if ((this._speed == null) && (this.alarm.params != null && this.alarm.params.indexOf("speed") >= 0)) {
      let parse = JSON.parse(this.alarm.params);
      this._speed = parse["speed"] | 0;
    }

    return this._speed;
  }

  set speed(value) {
    this._speed = value;
  }

  private _day;

  get day() {
    if ((this._day == null) && (this.alarm.params != null && this.alarm.params.indexOf("day") >= 0)) {
      let parse = JSON.parse(this.alarm.params);
      this._day = parse["day"] | 0;
    }

    return this._day;
  }

  set day(value) {
    this._day = value;
  }
}
