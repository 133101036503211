/// <reference types="markerwithlabel" />
/// <reference types="@types/googlemaps" />

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";
import {GoogleMapsService} from "app/infrastructure/services/google-maps.service";
import {GPS} from "app/lib/api";
import {MapBase} from "app/view/custom/map-base/map-base";
import {DeviceGPS} from "app/domain/model/gps-device";
import {DeviceRepository} from "app/data/source/repository/device-repository";
import {Subscriber} from "rxjs";

@Component({
  selector: 'map-history', templateUrl: './map-history.component.html', styleUrls: ['./map-history.component.scss']
})
export class MapHistoryComponent extends MapBase implements OnInit {
  @Input() loading: boolean;
  @Output() details: EventEmitter<DeviceGPS> = new EventEmitter<DeviceGPS>();

  constructor(private appService: AppService,
              private googleMapsService: GoogleMapsService,
              private deviceRepository: DeviceRepository) {
    super()
  }

  @Input() set gps(gpsList: GPS[]) {
    this.clearMarkers();
    this.clearBounds();
    this.clearPolylines();

    this.checkLoadedMap().then(value => {
      if (gpsList != null && gpsList.length > 1) {
        for (let i = 1; i <= gpsList.length; i++) {
          let gp = gpsList[i - 1];
          let gp1 = gpsList[i];

          this.addHistoryPoint(gp, gp1);
        }
        this.center();
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  private addHistoryPoint(gpsPrevious: GPS, gps: GPS) {
    if (gpsPrevious == null || gps == null) {
      return;
    }

    let latLng = new google.maps.LatLng(gps.latitude, gps.longitude);

    this.polylines.push(this.googleMapsService.addMapPoint(this.googleMaps, gpsPrevious, gps, () => {
      this.deviceRepository.findIMEI(gps.imei)
        .map(value => new DeviceGPS(value, gps))
        .subscribe(value => {
            return this.details.emit(value);
          },
          error => {

          }
        );
    }));

    this.latLngBounds.extend(latLng);
  }
}
