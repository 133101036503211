import {Observable} from "rxjs";
import {Product} from "app/lib/api";
import {LocalData} from "app/data/source/local/local-data";
import {Injectable} from "@angular/core";
import "rxjs-compat/add/operator/first";
import {ProductCloud} from "app/data/source/cloud/product-cloud.service";

@Injectable()
export class ProductRepository {
  static PRODUCTS = "products";

  constructor(private productCloud: ProductCloud, private localData: LocalData) {

  }

  listAll(limit, cursor, cache = true): Observable<Product[]> {
    return new Observable<Product[]>(subscribe => {
      if (cursor == null && cache) {
        let categories = this.localData.load(ProductRepository.PRODUCTS);

        if (categories != undefined) {
          subscribe.next(categories);
          return
        }
      }

      this.productCloud.listAll(limit, cursor)
        .do(x => this.localData.save(ProductRepository.PRODUCTS, x.items || []))
        .subscribe(value => subscribe.next(value.items), error => subscribe.error(error))
    })
  }

  save(product) {
    return this.productCloud.save(product)
  }

  load(product): Observable<Product> {
    return this.listAll(100, null)
      .flatMap(value => {
        return value.filter(value1 => value1.id == product);
      })
  }
}
