import {DefaultService, Feature} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class FeatureCloud {
  constructor(private apiClient: DefaultService) {

  }

  get(id: any) {
    return this.apiClient.featureEndpointV1Get(id)
  }

  save(feature) {
    return this.apiClient.featureEndpointV1Save(feature)
  }

  listAll(limit, cursor) {
    return this.apiClient.featureEndpointV1ListAll(limit, cursor)
  }
}
