import {Component, Input, OnInit} from '@angular/core';
import * as data from 'assets/json/string.json';

@Component({
  selector: 'app-alarm-header-base',
  templateUrl: './alarm-header-base.component.html',
  styleUrls: ['./alarm-header-base.component.css']
})
export class AlarmHeaderBaseComponent implements OnInit {

  title: string;
  content: string;

  y: Map<string, any> = new Map<string, any>();

  constructor() {

  }

  _type: string;

  @Input()
  set type(type: string) {
    this._type = type;

    let alarms = data;
    //
    // let content;
    //
    // switch (Number(type)) {
    //   case 0:
    //     content = alarms.type_0;
    //     break;
    //
    // }
    //
    // if (content) {
    //   this.title = content.title;
    //   this.content = content.content;
    // }
  }

  //Alarm 0 - Speed

  ngOnInit() {
  }

}
