import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Device, GPS} from "app/lib/api";
import {DeviceGPS} from "app/domain/model/gps-device";

@Component({
  selector: 'app-dialog-diagnostic', templateUrl: './dialog-diagnostic.component.html',
  styleUrls: ['./dialog-diagnostic.component.scss']
})
export class DialogDiagnosticComponent implements OnInit {
  public gps: GPS;
  public device: Device;

  constructor(public dialogRef: MatDialogRef<DialogDiagnosticComponent>, @Inject(MAT_DIALOG_DATA) public data: DeviceGPS) {
    this.gps = data.gps;
    this.device = data.device;
  }

  ngOnInit() {

  }
}
