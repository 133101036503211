import {Component, OnInit} from '@angular/core';
import {MatSlideToggleChange} from "@angular/material";
import {FindResult} from "app/view/custom/find/find.component";

@Component({
  selector: 'app-dev', templateUrl: './dev.component.html', styleUrls: ['./dev.component.css']
})
export class DevComponent implements OnInit {
  devserver = false;
  apiDebugUrl = "assets/data/dev_server";
  findComponentResult: FindResult;

  constructor() {
  }

  ngOnInit() {

  }

  onChangeDevServer(event: MatSlideToggleChange) {

  }

  result(findResult: FindResult) {
    this.findComponentResult = findResult
  }
}
