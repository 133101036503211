import {Component, Input, OnInit} from '@angular/core';
import {AlarmDevice} from "app/data/data/alarm-device";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {MatDialog, MatSnackBar} from "@angular/material";
import {AlarmApi} from "app/data/source/cloud/alarm-api";

@Component({
  selector: 'app-alarm-adapter', templateUrl: './alarm-adapter.component.html',
  styleUrls: ['./alarm-adapter.component.scss']
})
export class AlarmAdapterComponent implements OnInit {
  saving: boolean = false;

  @Input() alarmDevice: AlarmDevice;

  constructor(private alarmApi: AlarmApi, private snackBar: MatSnackBar, private dialog: MatDialog) {

  }

  ngOnInit() {

  }

  save() {
    this.saving = true;

    if (this.alarmDevice.alarm.type == 0) {
      this.alarmDevice.alarm.params = JSON.stringify({speed: this.alarmDevice.speed});

      if (this.alarmDevice.speed == undefined || this.alarmDevice.speed < 50) {
        this.alarmDevice.speed = 50
      }
    } else if (this.alarmDevice.alarm.type == 18) {
      if (this.alarmDevice.day > 30) {
        this.alarmDevice.day = 30
      }
      this.alarmDevice.alarm.params = JSON.stringify({day: this.alarmDevice.day});
    }

    this.alarmApi.save(this.alarmDevice.alarm)
      .then(value => {
        this.saving = false;
        this.snackBar.open(`Alerta de ${this.alarmDevice.device.name} salvo com sucesso!`, "", {
          duration: 2000, horizontalPosition: "end"
        });
      }, reason => {
        this.saving = false;
        new ErrorManager(reason, this.dialog)
      })
  }
}
