import {DefaultService} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class GpsApi {
  constructor(private apiClient: DefaultService) {

  }

  getAll() {
    return this.apiClient.gpsEndpointV1GetAll().toPromise()
  }

  getGPS(id: number) {
    return this.apiClient.gpsEndpointV1GetGPS(id).toPromise()
  }

  getDistance(deviceId: number, dateStart: Date, dateEnd: Date) {
    return this.apiClient.gpsEndpointV1GetDistance(deviceId, dateStart, dateEnd).toPromise()
  }

  getHistoryAcc(deviceId: number, dateStart: Date, dateEnd: Date, limit: number, cursor: string) {
    return this.apiClient.gpsEndpointV1GetHistoryAcc(deviceId, dateStart, dateEnd, limit, cursor).toPromise()
  }

  getHistory(id: number, dateStart: Date, dateEnd: Date, limit: number, valid: boolean, cursor) {
    return this.apiClient.gpsEndpointV1GetHistory(id, dateStart, dateEnd, limit, valid, cursor).toPromise()
  }
}
