import {GPS} from "app/lib/api";
import {MaterialColorsService} from "../services/material-colors.service";
import {Injectable} from "@angular/core";

@Injectable()
export class Utils {
  constructor(private materialColors: MaterialColorsService) {

  }

  static padLeft(value) {
    if (value < 10) {
      value = "0" + value;
    }

    return value;
  }

  static getNormalizeDate(gps: GPS): Date {
    let date = new Date(gps.gpsTime);

    date.setHours(date.getHours() - 8);

    return date;
  }

  static getGSMIcon(gps: GPS): string {
    return "signal_cellular_4_bar";

    if (gps.gsm == 100) {
      return "signal_cellular_4_bar"
    } else if (gps.gsm > 80) {
      return "signal_cellular_3_bar"
    } else if (gps.gsm > 60) {
      return "signal_cellular_2_bar"
    } else if (gps.gsm > 30) {
      return "signal_cellular_1_bar"
    } else if (gps.gsm > 0) {
      return "signal_cellular_0_bar"
    }
    return "person";
  }

  static toDegreesMinutesAndSeconds(coordinate) {
    let absolute = Math.abs(coordinate);
    let degrees = Math.floor(absolute);
    let minutesNotTruncated = (absolute - degrees) * 60;
    let minutes = Math.floor(minutesNotTruncated);
    let seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return degrees + " " + minutes + " " + seconds;
  }

  public static convertLatDMS(lat: number) {
    let latitude = Utils.toDegreesMinutesAndSeconds(lat);
    let latitudeCardinal = Math.sign(lat) >= 0 ? "N" : "S";

    return `${latitude} ${latitudeCardinal}`
  }

  public static convertLngDMS(lng: number) {
    let longitude = Utils.toDegreesMinutesAndSeconds(lng);
    let longitudeCardinal = Math.sign(lng) >= 0 ? "E" : "W";

    return `${longitude} ${longitudeCardinal}`
  }

  getStatusColor(condition: boolean) {
    if (condition == undefined || condition == false) {
      return this.getColor("Red", 500);
    } else {
      return this.getColor("Green", 500);
    }
  }

  getBatteryIcon(gps: GPS) {
    if (gps != undefined) {
      if (gps.charge) {
        return "battery_charging_full";
      } else if (gps.power < 90 && gps.voltage == undefined) {
        return "battery_alert"
      } else {
        return "battery_full";
      }
    }

    return "battery_unknown"
  }

  getSpeedColor(gps: GPS) {
    if (gps == undefined || gps.speed == 0) {
      return this.getColor("Red", 500);
    } else {
      return this.getColor("Green", 500);
    }
  }

  getBatteryColor(gps: GPS) {
    let color;

    if (gps == undefined || gps.power < 60 && gps.voltage == undefined) {
      color = this.getColor("Red", 500);
    } else if (gps.power == 100 || gps.voltage != undefined) {
      color = this.getColor("Green", 500);
    } else {
      color = this.getColor("Orange", 500);
    }

    return color;
  }

  getAccColor(gps: GPS) {
    if (gps == undefined || gps.acc == false) {
      return this.getColor("Red", 500);
    } else {
      return this.getColor("Green", 500);
    }
  }

  getLockColor(gps: GPS) {
    if (gps == undefined || gps.oil == true) {
      return this.getColor("Red", 500);
    } else {
      return this.getColor("Green", 500);
    }
  }

  getLockIcon(gps: GPS) {
    if (gps != undefined) {
      if (gps.oil) {
        return "lock_outline"
      }
    }

    return "lock_open";
  }

  getColor(color: string, shade) {
    return this.materialColors.getColorShade(color, shade);
  }

  getGCMColor(gps: GPS) {
    if (gps == undefined) {
      return this.getColor("Grey", 500)
    } else if (gps.gsm > 90) {
      return this.getColor("Green", 500)
    } else if (gps.gsm > 50) {
      return this.getColor("Amber", 500)
    } else {
      return this.getColor("Red", 500)
    }
  }

}
