import {Observable} from "rxjs";
import {Category, Provider} from "app/lib/api";
import {LocalData} from "app/data/source/local/local-data";
import {Injectable} from "@angular/core";
import {ProviderCloud} from "app/data/source/cloud/provider-cloud";

@Injectable()
export class ProviderRepository {
  static PROVIDERS = "providers";

  constructor(
    private providerCloud: ProviderCloud,
    private localData: LocalData
  ) {

  }

  list(limit, cursor, cache = true) {
    return new Observable<Provider[]>(subscribe => {
        if (cursor == null && cache) {
          let categories = this.localData.load(ProviderRepository.PROVIDERS);

          if (categories != undefined) {
            subscribe.next(categories);
            return
          }
        }

        this.providerCloud.list(limit, cursor)
          .do(x => this.localData.save(ProviderRepository.PROVIDERS, x.items))
          .subscribe(
            value => subscribe.next(value.items),
            error => subscribe.error(error)
          )
      }
    )
  }

  save(provider) {
    return this.providerCloud.save(provider)
  }

  load(providerId) {
    return this.list(100, null)
      .flatMap(value => {
          return value.filter(value1 => value1.id == providerId);
        }
      )
  }
}
