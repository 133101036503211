import {Component, OnInit} from '@angular/core';
import {ReportBase} from "app/view/reports/report-base/report-base";
import {MatDialog} from "@angular/material";
import {AppService} from "app/infrastructure/services/app.service";
import {MapRequestAddressProvider} from "app/view/custom/provider_address/maprequest-provider-address/maprequest-address-provider.service";
import {GpsApi} from "app/data/source/cloud/gps-api";

@Component({
  selector: 'app-report-acc',
  templateUrl: './report-acc.component.html',
  styleUrls: ['../report-base/report-base.component.css', './report-acc.component.css']
})
export class ReportAccComponent extends ReportBase implements OnInit {
  displayedColumns = ['gps_date', 'address', 'acc', 'speed', 'distance'];

  constructor(appService: AppService, providerRequest: MapRequestAddressProvider, gpsApi: GpsApi, dialog: MatDialog) {
    super(gpsApi, providerRequest, dialog);
    appService.setNavSubtitle("Ignição");
  }

  ngOnInit() {
  }

  public loadMore() {
    this.search();
  }

  protected search() {
    this.loading = true;

    let deviceId = this.result.device.id;
    let dateStart = this.result.startDate;
    let dateEnd = this.result.endDate;

    this.gpsApi.getHistoryAcc(deviceId, dateStart, dateEnd, 20, this.cursor)
      .then(value => {
        this.received(value.items, value.nextPageToken);
      }, reason => {
        this.error(reason);
      })
  }
}
