import {DefaultService, Plain} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class PlainCloud {
  constructor(private apiClient: DefaultService) {

  }

  get(categoryId) {
    return this.apiClient.plainEndpointV1Get(categoryId)
  }

  list(category, limit, cursor) {
    return this.apiClient.plainEndpointV1List(limit, category, true, cursor)
  }

  listFeatures(featureId, limit, cursor) {
    return this.apiClient.plainEndpointV1ListFeature(featureId, limit, cursor)
  }

  listCategories() {
    return this.apiClient.plainEndpointV1ListCategories()
  }

  save(category: Plain) {
    return this.apiClient.plainEndpointV1Save(category)
  }
}
