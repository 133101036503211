import {CookieService} from "ngx-cookie-service";
import {Injectable} from "@angular/core";
import {Device} from "app/lib/api/model/device";
import {AddressModelProvider} from "app/view/custom/provider_address/address_model_provider";

@Injectable()
export class LocalData {
  static DEVICES = "devices";
  static DEVICES_CACHE = 'devices_cache';
  static DEVICES_CACHE_TIME = 600000;
  static USER = "user";
  static GEOCODE = "geocode";

  constructor(private cookieService: CookieService) {
  }

  clearAll() {
    let geocode = this.geocode;
    this.cookieService.deleteAll();
    localStorage.clear();
    this.geocode = geocode;
  }

  load(key) {
    let deviceJSON = localStorage.getItem(key);

    if (deviceJSON != null && deviceJSON.length > 0) {
      return JSON.parse(deviceJSON);
    }
  }

  save(key, object) {
    localStorage.setItem(key, JSON.stringify(object));
  }

  get token(): string {
    return localStorage.getItem("token");
  }

  set token(token: string) {
    localStorage.setItem("token", token);
  }

  set devices(devices: Device[]) {
    this.save(LocalData.DEVICES, devices);
    this.save(LocalData.DEVICES_CACHE, Date.now().valueOf());
  }

  get devices(): Device[] {
    let cache = this.load(LocalData.DEVICES_CACHE);

    if (Date.now().valueOf() - cache > LocalData.DEVICES_CACHE_TIME) {
      return [];
    }

    return this.load(LocalData.DEVICES);
  }

  set user(login) {
    this.save(LocalData.USER, login)
  }

  get user() {
    let item = localStorage.getItem(LocalData.USER);

    if (item != null && item.length > 0) {
      return JSON.parse(item)
    }
  }

  set geocode(address: Map<string, { lat: number, lng: number, address: string }>) {
    const mapAsArray = Array.from(address.entries());
    this.save(LocalData.GEOCODE, mapAsArray);
  }

  get geocode(): Map<string, { lat: number, lng: number, address: string }> {
    let json = this.load(LocalData.GEOCODE);

    let retrievedMap = new Map<string, { lat: number, lng: number, address: string }>();

    if (json) {
      try{
        retrievedMap = new Map<string, { lat: number, lng: number, address: string }>(json);
      }catch (e){
        this.save(LocalData.GEOCODE, [])
      }
    }
    return retrievedMap
  }
}
