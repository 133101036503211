import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material";
import {ActivatedRoute} from "@angular/router";
import {Device, Plain} from "app/lib/api";
import {AppService} from "app/infrastructure/services/app.service";
import {ErrorGenericComponent} from "app/view/custom/dialog/error-generic/error-generic.component";
import {PaymentCloud} from "app/data/source/cloud/payment-cloud.service";
import {PlainApi} from "app/data/source/cloud/plain-api";
import {DeviceRepository} from "app/data/source/repository/device-repository";

@Component({
  selector: 'app-payment-create.component', templateUrl: './payment-create.component.html',
  styleUrls: ['./payment-create.component.css']
})
export class PaymentCreateComponent implements OnInit {
  device: Device;
  plains: Plain[];
  selectedPlain: Plain;
  loading = false;

  constructor(private activatedRoute: ActivatedRoute,
              private appService: AppService,
              private plainApi: PlainApi,
              private paymentApi: PaymentCloud,
              private dialog: MatDialog,
              private deviceRepository: DeviceRepository) {
    this.appService.setNavSubtitle("Renovar")
  }

  ngOnInit() {
    this.activatedRoute.params
      .map(params => params['deviceId'])
      .map(value => this.deviceRepository.findID(value)
      )
      .flatMap(value => value)
      .subscribe(value => {
          this.device = value;
        }
      );

    this.plainApi.list(100, 1, true, null)
      .subscribe(value => {
        this.plains = value.items;
        this.plains.sort((a, b) => {
          return a.value > b.value ? 1 : 0;
        });

        this.selectedPlain = this.plains.find(plains => {
          return plains.value == 99.9;
        });

        if (this.device != undefined) {
          this.loading = false;
        }
      });
  }

  create() {
    this.loading = true;

    this.paymentApi.create(this.device.id, this.selectedPlain.id).then(value => {
      window.open(value.url, "_self")
    }, reason => {
      this.dialog.open(ErrorGenericComponent)
    })
  }
}
