import {Observable} from "rxjs";
import {Chip, ChipM2M, ChipM2MFindPayload} from "app/lib/api";
import {LocalData} from "app/data/source/local/local-data";
import {Injectable} from "@angular/core";
import {ChipCloud} from "app/data/source/cloud/chip-cloud";

@Injectable()
export class ChipRepository {
  static CHIPS = "chips";
  static CHIPS_NOTFOUND = "not_found";

  constructor(private chipCloud: ChipCloud, private localData: LocalData) {

  }

  list(limit, cursor, cache = true) {
    return new Observable<Chip[]>(subscribe => {
      if (cursor == null && cache) {
        let chips = this.localData.load(ChipRepository.CHIPS);

        if (chips != undefined) {
          subscribe.next(chips);
          subscribe.complete();
        }
      }

      this.chipCloud.list(limit, cursor)
        .do(response => this.localData.save(ChipRepository.CHIPS, response.items))
        .subscribe(value => {
          subscribe.next(value.items);
          subscribe.complete();
        }, error => subscribe.error(error))
    })
  }

  save(chipM2M: ChipM2M) {
    return this.chipCloud.save(chipM2M)
  }

  load(chipM2MId) {
    return this.chipCloud.get(chipM2MId)
  }

  find(serialNumber): Observable<ChipM2MFindPayload> {
    return this.chipCloud.find(serialNumber)
      .do(chipSearch => {
        let notFound = this.localData.load(ChipRepository.CHIPS_NOTFOUND) || [];

        if (chipSearch == null) {
          notFound.push(serialNumber);
        } else {
          let index = notFound.indexOf(serialNumber);
          if (index > -1) {
            notFound.splice(index, 1)
          }
        }
        this.localData.save(ChipRepository.CHIPS_NOTFOUND, notFound)
      })
  }

  findCache() {
    return new Observable<String[]>(subscriber => {
      subscriber.next(this.localData.load(ChipRepository.CHIPS_NOTFOUND) || []);
      subscriber.complete()
    })
  }
}
