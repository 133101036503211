import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  data: String;
  route: ActivatedRoute;
  http: HttpClient;

  constructor(http: HttpClient, activate: ActivatedRoute) {
    this.http = http;
    this.route = activate
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let param = params["doc"];

      this.http.get(`assets/md/${param}.md`, {
        responseType: "text"
      }).subscribe(value => {
        this.data = value;
      })
    })
  }

}
