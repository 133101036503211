import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ChipRepository} from "app/data/source/repository/chip-repository";
import "rxjs-compat/add/operator/concat";
import "rxjs-compat/add/operator/mergeMap";
import {
  ChipM2M,
  Contract,
  ContractReviewPayload,
  Device,
  Feature,
  Login,
  Plain,
  Provider,
  SignaturePlainsPayload
} from "app/lib/api";
import {FeatureRepository} from "app/data/source/repository/feature-repository";
import {ProviderRepository} from "app/data/source/repository/provider-repository";
import {PlainRepository} from "app/data/source/repository/plain-repository";
import {HostRepository} from "app/data/source/repository/host-repository";
import {ContractRepository} from "app/data/source/repository/contract-repository";
import {SignatureRepository} from "app/data/source/repository/signature-repository.service";
import {Observable} from "rxjs";
import {DeviceRepository} from "app/data/source/repository/device-repository";
import {DashRepository} from 'app/data/source/repository/dash-repository';

@Component({
  selector: 'app-chip-search', templateUrl: './chip-search.component.html', styleUrls: ['./chip-search.component.scss']
})
export class ChipSearchComponent implements OnInit {
  loading = true;
  device: Device = {};
  provider: Provider = {};
  feature: Feature = {};
  chip: ChipM2M = {};
  login: Login = {};
  signatures: SignaturePlainsPayload[] = [];
  plains: Plain[] = [];
  selectedPlain: Plain;
  contractDate: Date;
  dueDate: Date;
  nextDueDate: Date;
  discountExpiredAt: Date;
  discountAmount: string;
  discountDescription: string;
  reviewPayload: ContractReviewPayload;
  contractCreated: Contract;
  contract: Contract;

  constructor(private activatedRoute: ActivatedRoute,
              private chipRepository: ChipRepository,
              private contractRepository: ContractRepository,
              private dashRepository: DashRepository,
              private deviceRepository: DeviceRepository,
              private featureRepository: FeatureRepository,
              private hostRepository: HostRepository,
              private providerRepository: ProviderRepository,
              private plainRepository: PlainRepository,
              private signatureRepository: SignatureRepository) {
  }

  ngOnInit() {
    this.loadInfos()
      .subscribe(value => {
        this.loading = false;
      }, error => this.loading = false);
  }

  private loadInfos() {
    return this.activatedRoute.params
      .flatMap(value => this.chipRepository.load(value["id"]))
      .do(value => this.chip = value)
      .flatMap(value => this.loadFeature())
      .flatMap(value => this.loadProvider())
      .flatMap(value => this.searchDevice())
      .flatMap(value => this.loadPlains())
      .do(value => this.loadContracts()
        .subscribe())
  }

  loadFeature() {
    return this.featureRepository.load(this.chip.featureId)
      .do(feature => this.feature = feature)
  }

  loadProvider() {
    return this.providerRepository.load(this.chip.providerId)
      .do(value => this.provider = value)
  }

  makeHostId(deviceId) {
    this.hostRepository.createDevice(deviceId)
      .subscribe(value => {
        this.searchDevice()
      })
  }

  review() {
    this.contractDate = new Date(this.contractDate);
    this.dueDate = new Date(this.dueDate);
    this.nextDueDate = new Date(this.nextDueDate);

    this.contractRepository.review(this.login.id,
      this.device.hostId,
      this.selectedPlain.id,
      this.contractDate,
      this.dueDate,
      this.nextDueDate,
      this.discountExpiredAt,
      this.discountAmount)
      .subscribe(value => {
        this.reviewPayload = value
      })
  }

  makeContract() {
    this.loading = true;

    const createDate = new Date(this.reviewPayload.contract.createDate);
    const dueDate = new Date(this.reviewPayload.contract.dueDate);
    const nextDueDate = new Date(this.reviewPayload.contract.nextDueDate);

    this.contractRepository.register(this.reviewPayload.loginId,
      this.reviewPayload.host.id,
      this.reviewPayload.plain.id,
      createDate,
      dueDate,
      nextDueDate,
      this.chip.id,
      this.device.id,
      this.discountExpiredAt,
      this.discountAmount,
      this.discountDescription)
      .do(value => this.contractCreated = value)
      .flatMap(() => this.deviceRepository.linkDeviceChip(this.device.id, this.chip.id))
      .flatMap(() => this.contractRepository.listAll(500, null, false))
      .flatMap(() => this.loadInfos())
      .subscribe(() => {
        this.loading = false;
      })
  }

  upgradeContract() {
    this.dashRepository.contractUpgrade(this.contract.id,
      this.selectedPlain.id,
      this.chip.id,
      this.device.id,
      this.discountExpiredAt,
      this.discountAmount)
      .flatMap(() => this.deviceRepository.linkDeviceChip(this.device.id, this.chip.id))
      .flatMap(() => this.loadInfos())
      .subscribe(() => {
        this.loading = false;
      })
  }

  private searchDevice() {
    return this.chipRepository.find(this.chip.serialNumber)
      .do(value => {
        if (value) {
          this.device = value.device;
          this.login = value.login;
          if (value.device != undefined) {
            this.contractDate = value.device.registerDate;
            this.nextDueDate = value.device.registerDate;
            this.dueDate = value.device.registerDate;
          }
        }
      })
  }

  private loadPlains(): Observable<SignaturePlainsPayload[]> {
    return this.signatureRepository.listPlain(5680252610150400, this.device.hostId, 200, null)
      .do(value => this.signatures = value)
  }

  dateToday() {
    this.dueDate = new Date();
    this.nextDueDate = new Date();
  }

  linkChip() {
    this.deviceRepository.linkDeviceChip(this.device.id, this.chip.id)
      .do(() => this.searchDevice())
      .subscribe(value => {
        this.device = value;
      })
  }

  copyDeviceDueDate() {
    this.discountExpiredAt = new Date(this.device.dueDate);
    this.discountAmount = "10.00";
  }

  private loadContracts() {
    return this.contractRepository.get(this.chip.contractId ? this.chip.contractId : this.device.contractId)
      .do(value => {
        if (value.canceled == null) {
          this.contract = value
        }
      });
  }

  saveChip() {
    this.loading = true;
    this.chipRepository.save(this.chip)
      .subscribe(value => {
        this.loading = false;
        this.loadInfos();
      });
  }

  cancelContract() {
    this.loading = true;
    this.dashRepository.contractCancel(this.contract.id)
      .subscribe(value => {
        this.loading = false;
        this.contract = value;
      }, error => this.loading = false)
  }

  cancelChip() {
    this.loading = true;
    this.dashRepository.chipCancel(this.chip.id)
      .subscribe(value => {
        this.loading = false;
        this.chip = value;
      }, error => {
        this.loading = false
      })
  }
}
