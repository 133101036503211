import {DefaultService} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class AlarmApi {
  constructor(private apiClient: DefaultService) {

  }

  getType(deviceId: number, type: number) {
    return this.apiClient.alarmEndpointV1GetType(deviceId, type).toPromise()
  }

  createAnchor(deviceId: number, latitude: number, longitude: number, radius: number) {
    return this.apiClient.alarmEndpointV1SaveAnchor(deviceId, latitude, longitude, radius).toPromise()
  }

  listType(type: number) {
    return this.apiClient.alarmEndpointV1ListType(type)
  }

  save(alarm) {
    return this.apiClient.alarmEndpointV1Save(alarm).toPromise()
  }
}
