import {Component} from '@angular/core';
import {ReportBase} from "app/view/reports/report-base/report-base";
import {MatDialog} from "@angular/material";
import {AppService} from "app/infrastructure/services/app.service";
import {GpsApi} from "app/data/source/cloud/gps-api";
import {GoogleAddressProvider} from "app/view/custom/provider_address/google-provides-address/google-address-provider";

@Component({
  selector: 'app-report-distance', templateUrl: './report-distance.component.html',
  styleUrls: ['../report-base/report-base.component.css', './report-distance.component.css']
})
export class ReportDistanceComponent extends ReportBase {
  displayedColumns = ['start', 'end', 'distance'];

  constructor(
    appService: AppService,
    providerRequest: GoogleAddressProvider,
    gpsApi: GpsApi,
    dialog: MatDialog
  ) {
    super(gpsApi, providerRequest, dialog);

    appService.setNavSubtitle("Distância");
  }

  ngOnInit() {

  }

  protected search() {
    this.loading = true;

    this.dateEnd = this.result.endDate;
    this.dateStart = this.result.startDate;
    let deviceId = this.result.device.id;

    this.gpsApi.getDistance(deviceId, this.dateStart, this.dateEnd)
      .then(value => {
        this.received(value, null);
      }, reason => {
        this.error(reason);
      });
  }
}
