import {Component, OnInit} from '@angular/core';
import {IconListComponent} from "app/view/admin/icons/list/icon-list.component";
import {IconFormComponent} from "app/view/admin/icons/icon-form/icon-form.component";

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

export const iconsComponents = [
  IconsComponent,
  IconListComponent,
  IconFormComponent,
];

export const iconsRoutes = {
  path: 'icons', component: IconsComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: IconListComponent
    }, {
      path: 'form/:id', component: IconFormComponent
    }, {
      path: 'form', component: IconFormComponent
    }
  ]
};
