import {Chip, Contract} from "app/lib/api";

export class ChipContract {
  chip: Chip;
  contract: Contract;
  notFound:boolean;

  constructor(chip: Chip, contract: Contract) {
    this.chip = chip;
    this.contract = contract;
  }
}
