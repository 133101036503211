import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

@Injectable()
export class AppRoute {
  redirect: string = null;

  constructor(private router: Router) {
  }

  routeLogout(redirect) {
    if (redirect == true) {
      this.redirect = this.router.url;
    } else {
      this.redirect = null;
    }

    this.router.navigateByUrl(`/login`)
  }

  routeLogin() {
    this.redirect = this.router.url;
    this.router.navigateByUrl(`/login`)
  }

  routeSetup(redirect?) {
    if (this.redirect == null && redirect == true) {
      this.redirect = this.router.url;
    }

    this.router.navigateByUrl(`/setup`)
  }

  routeRedirect(url) {
    if (this.redirect != null) {
      this.router.navigateByUrl(this.redirect);
      this.redirect = null
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
