import {Component, OnInit, ViewChild} from '@angular/core';
import {Alarm, Device, GPS} from "app/lib/api";
import {MapAnchorComponent} from "./map-anchor/map-anchor.component";
import {AppService} from "app/infrastructure/services/app.service";
import {MatDialog, MatSnackBar} from "@angular/material";
import {ErrorManager} from "app/view/custom/error/ErrorManager";
import {AlarmApi} from "app/data/source/cloud/alarm-api";
import {GpsApi} from "app/data/source/cloud/gps-api";

@Component({
  selector: 'app-anchor', templateUrl: './anchor.component.html', styleUrls: ['./anchor.component.scss']
})
export class AnchorComponent implements OnInit {
  gps: GPS;
  device: Device;
  alarm: Alarm = {} as Alarm;
  loading: boolean = false;
  circle: google.maps.Circle;

  @ViewChild("mapAnchor", {static: true}) mapAchor: MapAnchorComponent;

  constructor(appService: AppService, private alarmApi: AlarmApi, private gpsApi: GpsApi, public snackBar: MatSnackBar, private dialog: MatDialog) {
    appService.setNavSubtitle("Âncora");
  }

  ngOnInit() {

  }

  selectDevice(device: Device) {
    this.loading = true;

    this.device = device;

    this.gpsApi.getGPS(device.id)
      .then(value => {
        this.gps = value;

        this.alarmApi.getType(device.id, 9)
          .then(value => {
            this.alarm = value || {} as Alarm;
            this.loading = false;

            this.checkCircle();
          }, reason => {
            this.loading = false;
            new ErrorManager(reason, this.dialog)
          })
      }, reason => {
        this.loading = false;
        new ErrorManager(reason, this.dialog);
      });
  }

  save() {
    this.loading = true;

    if (this.alarm.enable) {
      this.alarmApi.createAnchor(this.device.id, this.gps.latitude, this.gps.longitude, 500)
        .then(value => {
          this.loading = false;
          this.selectDevice(this.device);
          this.snackBar.open("Âncora criada", null, {
            duration: 5000
          })
        }, reason => {
          this.loading = false;
          new ErrorManager(reason, this.dialog);
        });
    } else if (this.alarm.id != undefined) {
      this.alarm.enable = false;
      this.alarmApi.save(this.alarm)
        .then(value => {
          this.loading = false;
          this.selectDevice(this.device);

          this.snackBar.open("Âncora desativada", null, {
            duration: 5000
          })
        }, reason => {
          this.loading = false;
          new ErrorManager(reason, this.dialog);
        })
    } else {
      this.loading = false;
    }
  }

  checkCircle() {
    if (this.alarm.enable && this.gps != undefined) {
      this.drawCircle()
    } else {
      this.clearCircle()
    }
  }

  changeEnable() {
    new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 0)
    }).then(value => {
      this.checkCircle();
      this.save();
    });
  }

  clearCircle() {
    if (this.circle != undefined) {
      this.circle.setMap(null);
    }
  }

  drawCircle() {
    this.clearCircle();

    let params = this.alarm.params;

    let paramsJSON = JSON.parse(params);

    this.circle = new google.maps.Circle({
      strokeColor: '#3f51b5',
      strokeWeight: 4,
      strokeOpacity: 1,
      fillColor: '#ff9100',
      fillOpacity: 0.4,
      center: {lat: this.gps.latitude, lng: this.gps.longitude},
      radius: paramsJSON.radius,
      map: this.mapAchor.googleMaps
    })
  }
}
