import {Injectable} from '@angular/core';
import {DeviceImageComponent} from "app/view/custom/device-image/device-image.component";
import {MatDialog} from "@angular/material";
import {GPS} from "app/lib/api";
import {DeviceApi} from "app/data/source/cloud/device-api";
import {DeviceGPS} from "app/domain/model/gps-device";

@Injectable()
export class GoogleMapsService {

  constructor(private deviceApi: DeviceApi,
              private dialog: MatDialog) {
  }

  public createMarker(map: google.maps.Map, deviceGPS: DeviceGPS, click: () => void): google.maps.Marker {
    let latLng = new google.maps.LatLng(deviceGPS.gps.latitude, deviceGPS.gps.longitude);

    let deviceIcon = DeviceImageComponent.getIconId(this.deviceApi, deviceGPS.device.deviceIcon, 62);

    let deviceName = deviceGPS.device.name;
    let nameLength = deviceName.length * 3;

    let classes = 'marker ';

    if (!deviceGPS.gps.acc) {
      if (deviceGPS.gps.speed > 10) {
        classes += 'marker-acc-off-move';
      } else {
        classes += 'marker-acc-off';
      }
    } else {
      classes += 'marker-acc-on';
    }

    let markerClass = 'marker-labels ';

    var received = deviceGPS.gps.receivedDate;
    var current = new Date();

    // if (current
    //     .difference(received)
    //     .inHours > 3) {
    //   markerClass += 'marker-off ';
    // }

    var marker;

    var markerWithLabelOptions = {
      icon: deviceIcon,
      position: latLng,
      map: map,
      labelContent: deviceName,
      labelAnchor: new google.maps.Point(nameLength, 10),
      labelClass: markerClass + classes,
      labelStyle: "opacity: 0.75"
    } as MarkerWithLabelOptions;

    marker = new MarkerWithLabel(markerWithLabelOptions);

    google.maps.event.addListener(marker, 'click', () => {
      click()
    });

    return marker;
  }

  public addMapPoint(map: google.maps.Map, gpsPrevious: GPS, gps: GPS, click: () => void) {
    let latLngPrevious = new google.maps.LatLng(gpsPrevious.latitude, gpsPrevious.longitude);
    let latLng = new google.maps.LatLng(gps.latitude, gps.longitude);

    const path = [
      {
        lat: latLngPrevious.lat(), lng: latLngPrevious.lng()
      }, {
        lat: latLng.lat(), lng: latLng.lng()
      }
    ];

    var strokeColor;

    if (gps.acc == true) {
      strokeColor = '#082567';
    } else {
      strokeColor = '#E8410A';
    }

    var icon = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      scale: 4,
      fillColor: 'blue',
      fillOpacity: 0.8,
      strokeColor: 'blue',
      strokeWeight: 2,
      rotation: gpsPrevious.course,
    };

    var icon2 = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      scale: 4,
      fillColor: 'blue',
      fillOpacity: 0.8,
      strokeColor: 'blue',
      strokeWeight: 2,
      offset: '50%',
      rotation: gps.course
    };

    let polygon = new google.maps.Polyline({
      path: path, geodesic: true, strokeColor: strokeColor, strokeOpacity: 1.0, strokeWeight: 8, icons: [
        {
          icon: icon, offset: "0%", fixedRotation: true
        }, {
          icon: icon2, offset: '50%', fixedRotation: true
        }
      ]
    });

    google.maps.event.addListener(polygon, 'click', () => {
      click()
    });

    polygon.setMap(map);

    return polygon;
  }
}
