import {Component, OnInit} from '@angular/core';
import {ChipRepository} from "app/data/source/repository/chip-repository";
import {ChipContract} from "app/domain/model/chip-contract";
import {ContractRepository} from "app/data/source/repository/contract-repository";
import "rxjs-compat/add/operator/zip";
import "rxjs-compat/add/operator/zipAll";
import {Observable} from "rxjs";
import "rxjs-compat/add/observable/of";

@Component({
  selector: 'app-chip-list', templateUrl: './chip-list.component.html', styleUrls: ['./chip-list.component.scss']
})
export class ChipListComponent implements OnInit {

  loading: boolean = true;
  chipsFiltered: ChipContract[] = [];
  chips: ChipContract[] = [];
  searchTerm: "";

  constructor(private chipRepository: ChipRepository, private contractRepository: ContractRepository,) {
  }

  ngOnInit() {
    this.loadChips()
  }

  search() {
    let chipsFiltered = this.chips.filter(value => {
      const x = `${value.chip.ddd}${value.chip.number}`;
      return x.indexOf(this.searchTerm) > -1 || value.chip.serialNumber.indexOf(this.searchTerm) > -1;
    });

    this.sortItems(chipsFiltered)
  }

  filterNoContract() {
    let filter = this.chips.filter(value => {
      return value.contract == null && value.chip.contractId == null
    })

    this.sortItems(filter);
  }

  showV1() {
    let filter = this.chips.filter(value => {
      return value.contract != null && value.chip.contractId == null && value.chip.canceled == null && value.contract.canceled == null
    })

    this.sortItems(filter);
  }

  showV2() {
    let filter = this.chips.filter(value => {
      return value.contract != null && value.chip.contractId && value.chip.canceled == null
    })

    this.sortItems(filter);
  }

  private sortItems(sort: ChipContract[]) {
    this.chipsFiltered = sort

    this.chipsFiltered = this.chipsFiltered.sort((a, b) => {
      if (a.chip.number > b.chip.number) {
        return 1;
      }

      if (a.chip.number < b.chip.number) {
        return -1;
      }

      return 0;
    });

    this.chipsFiltered = this.chipsFiltered.sort((a, b) => {
      if (a.contract != null && b.contract == null) {
        return 1;
      }

      if (a.contract == null && b.contract != null) {
        return -1;
      }

      return 0;
    });

    this.chipsFiltered.sort((a, b) => {
      if (a.chip.contractId != null && b.chip.contractId == null) {
        return 1;
      }
      if (a.chip.contractId == null && b.chip.contractId != null) {
        return -1;
      }

      return 0;
    });
  }

  loadChips(cache = true, research = false) {
    this.loading = true;

    this.chips = [];
    this.chipRepository.list(500, null, cache)
      .flatMap(value => value)
      .flatMap(chip => this.contractRepository.listAll(500, null, true)
        .flatMap(value => value)
        .find(value => value.productId == chip.id)
        .map(value => {
          return new ChipContract(chip, value)
        }))
      .flatMap(value => {
        if (research) {
          return this.chipRepository.find(value.chip.serialNumber).map(value1 => value);
        } else {
          return Observable.of(value);
        }
      })
      .map(chipContract => this.chipRepository.findCache()
        .flatMap(notFoundSerials => notFoundSerials)
        .find(notFoundSerial => chipContract.chip.serialNumber == notFoundSerial)
        .map(chipNotFound => {
          if (chipNotFound) {
            if (research) this.chipRepository.find(chipNotFound).subscribe(data => console.log(data));
            chipContract.notFound = true
          }
          return chipContract;
        }))
      .flatMap(chipContract => chipContract)
      .subscribe(value => {
        this.chips.push(value);
        this.sortItems(this.chips);
      }, error => {
      }, () => {
        this.loading = false;
      })
  }

  notFound() {
    return this.chips.filter(value => value.notFound).length
  }

  problem() {
    return this.chips.filter(value => value.contract == null && value.chip.contractId == null)
  }

  chipsV1() {
    return this.chips.filter(value => value.chip.contractId == null && value.contract != null && value.contract.canceled == null)
  }

  chipsV2() {
    return this.chips.filter(value => value.chip.contractId != null)
  }

  canceled() {
    return this.chips.filter(value => value.contract != null && value.contract.canceled != null)
  }

  showCanceled() {
    this.sortItems(this.canceled())
  }
}
