import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {FipeMarcas, FipeModels, FipeTipos} from "app/data/data/fipe/fipe";

@Injectable({
  providedIn: 'root'
})
export class FipeService {
  manufacturers: Map<FipeTipos, Array<FipeMarcas>> = new Map();
  manufacturersSubject: Subject<Map<FipeTipos, Array<FipeMarcas>>> = new Subject();

  models: Map<number, Array<FipeModels>> = new Map();
  modelSubject: Subject<Map<number, Array<FipeModels>>> = new Subject();

  constructor(private http: HttpClient) {

  }

  loadModels(code: number) {
    if (!this.models.has(code)) {
      this.models.set(code, []);

      this.http.get(`https://fipeapi.appspot.com/api/1/carros/veiculos/${code}.json`).toPromise().then((fipeModels: FipeModels[]) => {
        this.models.set(code, fipeModels);
        this.modelSubject.next(this.models);
      })
    } else {
      this.modelSubject.next(this.models)
    }
  }

  updateModels(code: string) {
    this.modelSubject.next(this.models);
  }

  updateManufacturers(type: string) {
    this.modelSubject.next(this.models);
  }

  loadManufacturers(type: string) {
    let fipeTipo: FipeTipos = FipeTipos[type];

    if (fipeTipo != undefined) {
      if (!this.manufacturers.has(fipeTipo)) {
        this.manufacturers.set(fipeTipo, []);

        this.http.get(`https://fipeapi.appspot.com/api/1/${fipeTipo}/marcas.json`).toPromise().then((fipeMarcas: FipeMarcas[]) => {
          this.manufacturers.set(fipeTipo, fipeMarcas);
          this.manufacturersSubject.next(this.manufacturers);
        });
      } else {
        this.manufacturersSubject.next(this.manufacturers)
      }
    } else {
      this.manufacturers.set(fipeTipo, []);
    }
  }
}
