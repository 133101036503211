/**
 * apureh-web.appspot.com
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {Discount} from './discount';

export interface Contract {
  categoryId?: number;
  createDate?: Date;
  createdAt?: Date;
  discount?: Discount;
  dueDate?: Date;
  featureId?: number;
  hostId?: number;
  id?: number;
  loginId?: number;
  nextDueDate?: Date;
  plainId?: number;
  productId?: number;
  status?: Contract.StatusEnum;
  canceled?: any;
}

export namespace Contract {
  export type StatusEnum = 'ACTIVE' | 'CHANGE' | 'PENDING' | 'CANCELED';
  export const StatusEnum = {
    ACTIVE: 'ACTIVE' as StatusEnum,
    CHANGE: 'CHANGE' as StatusEnum,
    PENDING: 'PENDING' as StatusEnum,
    CANCELED: 'CANCELED' as StatusEnum
  };
}
