import {OnInit} from "@angular/core";
import {GPS} from "app/lib/api";

declare var loadedGoogleMaps: boolean;

export class MapBase implements OnInit {
  googleMaps: google.maps.Map;
  markersList: google.maps.Marker[] = [];
  latLngBounds: google.maps.LatLngBounds = {} as google.maps.LatLngBounds;
  polylines: google.maps.Polyline[] = [];

  center() {
    if (this.latLngBounds != undefined && this.googleMaps != undefined) {
      this.googleMaps.fitBounds(this.latLngBounds);
    }

    if (this.googleMaps.getZoom() > 18) {
      this.googleMaps.setZoom(18);
    }
  }

  clearMarkers() {
    this.markersList.forEach(value => {
      value.setMap(null);
    })
  }

  async checkLoadedMap(): Promise<void> {
    new Promise(resolve => {
      setTimeout(() => {
        if (this.googleMaps != undefined) {
          resolve();
        }
      }, 500, this.googleMaps == undefined)
    });
  }

  clearBounds() {
    this.latLngBounds = new google.maps.LatLngBounds();
  }

  centerGPS(gps: GPS, zoom: number) {
    this.googleMaps.setCenter(new google.maps.LatLng(gps.latitude, gps.longitude));
    this.googleMaps.setZoom(zoom);
  }

  clearPolylines() {
    this.polylines.forEach(value => {
      value.setMap(null);
    })
  }

  loadMap() {
    console.log("Initing maps");

    this.googleMaps = new google.maps.Map(document.getElementById("map-canvas"), {
      center: {lat: -34.397, lng: 150.644}, zoom: 8, mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR, position: google.maps.ControlPosition.TOP_CENTER
      }, streetViewControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT
      }, zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER
      }
    });
  }

  ngOnInit(): void {
    if (loadedGoogleMaps) {
      this.loadMap()
    } else {
      document.addEventListener("loadedGoogleMaps", evt => {
        this.loadMap();
      });
    }
  }
}
