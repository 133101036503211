import {Component, Input, OnInit} from '@angular/core';
import {Order, OrderPackage} from "app/lib/api";

@Component({
  selector: 'app-invoice-overdues-adapter',
  templateUrl: './invoice-overdues-adapter.component.html',
  styleUrls: ['./invoice-overdues-adapter.component.scss']
})
export class InvoiceOverduesAdapterComponent implements OnInit {

  @Input() order: Order;

  constructor() {
  }

  ngOnInit() {
  }

}
