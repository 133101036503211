import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AppService} from "app/infrastructure/services/app.service";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material";
import {PendentComponent} from "app/view/pendent/pendent.component";
import {TokenLoadInteractor} from "app/domain/interactor/TokenLoadInteractor";
import {UserLoadInteractor} from "app/domain/interactor/UserLoadInteractor";
import {DeviceRepository} from "app/data/source/repository/device-repository";
import {TokenSaveInteractor} from "app/domain/interactor/TokenSaveInteractor";
import {AppRoute} from "app/infrastructure/services/app_route";

@Component({
  selector: 'app-home', templateUrl: './home.component.html', styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  sideNav = false;
  toolbar = true;
  nav_title = "";
  nav_subtitle = "";
  showPendent: boolean = false;
  private subscriptionTitle: Subscription;
  private subscriptionSubtitle: Subscription;

  constructor(
    private router: Router,
    private appRoute: AppRoute,
    private tokenSaveInteractor: TokenSaveInteractor,
    private tokenLoadInteractor: TokenLoadInteractor,
    private userLoadInteractor: UserLoadInteractor,
    private appService: AppService,
    private deviceRepository: DeviceRepository,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    let token = tokenLoadInteractor.execute();
    let user = userLoadInteractor.execute();

    if (token == null || token.length == 0) {
      this.appService.logout(true)
    } else if (user == null) {
      this.navToSetup();
    } else {
      deviceRepository.list().subscribe(value => {
          if (value == undefined) {
            this.navToSetup();
          } else {
            this.checkPendent();
          }
        }, error => {
        }
      );
    }

    this.toolbar = appService.toolbar;

    this.router.events.forEach(value => {
      if (value instanceof NavigationEnd) {
        this.sideNav = false;
      }
    });

    this.subscriptionTitle = this.appService.navTitleObserve.subscribe(title => this.nav_title = title);
    this.subscriptionSubtitle = this.appService.navSubtitleObserve.subscribe(subtitle => this.nav_subtitle = subtitle);
  }

  ngOnInit() {

  }

  logout(redirect) {
    this.appService.logout(redirect)
  }

  ngOnDestroy() {
    this.subscriptionTitle.unsubscribe();
    this.subscriptionSubtitle.unsubscribe();
  }

  checkPendent() {
    this.deviceRepository
      .list()
      .flatMap(value => value)
      .subscribe(device => {
        if (device.deviceIcon == undefined) {
          this.showPendent = true;
        }
      });

    if (this.showPendent) {
      this.dialog.open(PendentComponent)
    }
  }

  private navToSetup() {
    this.appRoute.routeSetup(true);
  }
}
