import {Component, OnInit} from '@angular/core';
import {BrandListComponent} from "app/view/admin/brands/brand-list/brand-list.component";
import {BrandFormComponent} from "app/view/admin/brands/brand-form/brand-form.component";

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

export const brandComponents = [
  BrandComponent,
  BrandListComponent,
  BrandFormComponent
];

export const brandRoutes = {
  path: 'brands', component: BrandComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: BrandListComponent
    }, {
      path: 'form/:id', component: BrandFormComponent
    }, {
      path: 'form', component: BrandFormComponent
    }
  ]
};
