import {Component, Input, OnInit} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {FipeMarcas, FipeModels} from "app/data/data/fipe/fipe";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {FipeService} from "app/view/custom/fipe/fipe.service";

@Component({
  selector: 'app-fipe-model',
  templateUrl: './fipe-model.component.html',
  styleUrls: ['./fipe-model.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: FipeModelComponent, multi: true}
  ]
})
export class FipeModelComponent implements OnInit {
  models: Array<FipeModels> = [];
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  //The internal data model
  private innerValue: any = '';
  //by the Control Value Accessor
  private onTouchedCallback: () => void = null;
  //Placeholders for the callbacks which are later provided
  private onChangeCallback: (_: any) => void = null;

  constructor(private fipeService: FipeService) {
  }

  @Input() set fipeMarca(marca: FipeMarcas) {
    if (marca != undefined && marca.id != undefined) {
      this.fipeService.modelSubject.subscribe(models => {
        let fipeName = marca.fipe_name;

        if (models.has(marca.id)) {
          this.models = models.get(marca.id);
          this.options = this.models.map(map => map.fipe_name);
          this.myControl.setValue(this.myControl.value, {emitEvent: true})
        }
      });

      this.fipeService.loadModels(marca.id)
    }
  }

  //get accessor
  get value(): any {
    return this.innerValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  //Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(startWith(''), map(value => this._filter(value)));
  }

  private _filter(value: string): string[] {
    if (value != undefined) {
      const filterValue = value.toLowerCase();

      return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }

    return this.options;
  }

}
