import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "app/view/login/login.component";
import {RegisterComponent} from "app/view/register/register.component";
import {ForgotComponent} from "app/view/forgot/forgot.component";
import {DevComponent} from "app/dev/dev.component";
import {TermsComponent} from "app/view/terms/terms.component";
import {RegisterSuccessComponent} from "app/view/register/register-success/register-success.component";
import {FindComponent} from "app/view/custom/find/find.component";
import {SetupComponent} from "app/view/setup/setup.component";
import {HomeComponent} from "app/view/home/home.component";
import {AccountComponent} from "app/view/account/account.component";
import {PaymentComponent} from "app/view/payments/payment.component";
import {PaymentListComponent} from "app/view/payments/list/payment-list.component";
import {DeviceFormComponent} from "app/view/devices/form/device-form.component";
import {DeviceListComponent} from "app/view/devices/list/device-list.component";
import {DeviceComponent} from "app/view/devices/device.component";
import {PaymentCreateComponent} from "app/view/payments/create/payment-create.component";
import {AlarmComponent} from "app/view/alarm/alarm.component";
import {AlarmListComponent} from "app/view/alarm/alarm-list/alarm-list.component";
import {ReportsComponent} from "app/view/reports/reports.component";
import {ReportHistoryComponent} from "app/view/reports/report-history/report-history.component";
import {CommandsComponent} from "app/view/commands/commands.component";
import {BlockCommandComponent} from "app/view/commands/block-command/block-command.component";
import {MapsComponent} from "app/view/maps/maps/maps.component";
import {LocationComponent} from "app/view/maps/location/location.component";
import {GraphicsComponent} from "./view/graphics/graphics.component";
import {GraphicsResume} from "./view/graphics/resume/graphics-resume.component";
import {ReportDistanceComponent} from "app/view/reports/report-distance/report-distance.component";
import {ReportAccComponent} from "app/view/reports/report-acc/report-acc.component";
import {HistoryMapComponent} from "./view/maps/history-map/history-map.component";
import {AnchorComponent} from "./view/alarm/anchor/anchor.component";
import {HelpComponent} from "./view/help/help/help.component";
import {LogListComponent} from "./view/logs/log-list/log-list.component";
import {AdminComponent} from "./view/admin/admin/admin.component";
import {StatusComponent} from "./view/admin/status/status.component";
import {CredentialsComponent} from "app/view/admin/credentials/credentials.component";
import {iconsRoutes} from "app/view/admin/icons/icons.component";
import {featureRoutes} from "app/view/admin/features/feature.component";
import {brandRoutes} from "app/view/admin/brands/brand.component";
import {categoryRoutes} from "app/view/admin/categories/category.component";
import {plainRoutes} from "app/view/admin/plains/plain.component";
import {chipRoutes} from "app/view/admin/chips/chip.component";
import {contractRoutes} from "app/view/admin/contracts/contract.component";
import {invoiceRoutes} from "app/view/invoice/invoice.component";
import {signatureRoutes} from "app/view/admin/signatures/signature.component";
import {productRoutes} from "app/view/admin/products/product.component";

const adminRoutes = {
  path: 'admin', component: AdminComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'setup'
    }, {
      path: 'status', component: StatusComponent
    }, {
      path: "setup", component: CredentialsComponent
    }, brandRoutes, categoryRoutes, chipRoutes, contractRoutes, featureRoutes, productRoutes, iconsRoutes, plainRoutes, signatureRoutes
  ]
};

const deviceRoutes = {
  path: 'device', component: DeviceComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: DeviceListComponent
    }, {
      path: 'form/:id', component: DeviceFormComponent
    }, {
      path: 'form', component: DeviceFormComponent
    }
  ]
};

const paymentRoutes = {
  path: 'payment', component: PaymentComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'list', component: PaymentListComponent
    }, {
      path: 'create/:deviceId', component: PaymentCreateComponent
    }
  ]
};

let alarmRoutes = {
  path: 'alarm', component: AlarmComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: '18'
    }, {
      path: 'anchor', component: AnchorComponent
    }, {
      path: ':type', component: AlarmListComponent
    },
  ]
};
let reportRoutes = {
  path: 'reports', component: ReportsComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'list'
    }, {
      path: 'history', component: ReportHistoryComponent
    }, {
      path: 'distance', component: ReportDistanceComponent
    }, {
      path: 'acc', component: ReportAccComponent
    },
  ]
};

const appRoutes = {
  path: 'app', component: HomeComponent, children: [
    {
      path: '', pathMatch: 'prefix', redirectTo: 'maps'
    }, {
      path: 'account', component: AccountComponent
    }, deviceRoutes, paymentRoutes, alarmRoutes, reportRoutes, invoiceRoutes, {
      path: 'commands', component: CommandsComponent, children: [
        {
          path: '', pathMatch: 'prefix', redirectTo: 'block'
        }, {
          path: 'block', component: BlockCommandComponent
        }
      ]
    }, {
      path: 'maps', component: MapsComponent, children: [
        {
          path: '', pathMatch: 'prefix', redirectTo: 'location'
        }, {
          path: 'location', component: LocationComponent
        }, {
          path: 'history', component: HistoryMapComponent
        }
      ]
    }, {
      path: 'graphics', component: GraphicsComponent, children: [
        {
          path: '', pathMatch: 'prefix', redirectTo: 'resume'
        }, {
          path: 'resume', component: GraphicsResume
        }
      ]
    }, {
      path: 'help', component: HelpComponent
    }, {
      path: 'notifications', component: LogListComponent
    }
  ]
};

const routes: Routes = [
  {
    path: '', pathMatch: "prefix", redirectTo: 'app'
  }, {
    path: 'dev', component: DevComponent, children: [
      {
        path: 'find', component: FindComponent
      }
    ]
  }, adminRoutes, {
    path: 'login', component: LoginComponent
  }, {
    path: 'register', component: RegisterComponent
  }, {
    path: 'register_success', component: RegisterSuccessComponent
  }, {
    path: 'forgot', component: ForgotComponent
  }, {
    path: 'setup', component: SetupComponent
  }, {
    path: 'policies/:doc', component: TermsComponent
  }, appRoutes
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], exports: [RouterModule], declarations: []
})

export class AppRoutingModule {
}
