import {MatDialog} from "@angular/material";
import {HttpErrorResponse} from "@angular/common/http";
import {CustomizeDialog, ErrorGenericComponent} from "app/view/custom/dialog/error-generic/error-generic.component";
import {LogService} from "app/infrastructure/services/log.service";

export class ErrorManager {

  constructor(error: HttpErrorResponse, dialog: MatDialog) {
    LogService.debug(error);

    let customizeDialog = new CustomizeDialog(error.status);

    switch (error.status) {
      case 403:
        customizeDialog.title = "Plano vencido";
        customizeDialog.context = "Este equipamento está com o plano vencido, renove para ter acesso a essa função";
        break;

      default:

        break
    }

    dialog.open(ErrorGenericComponent, {
      data: customizeDialog
    });
  }
}
