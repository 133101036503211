import {Observable} from "rxjs";
import {Category, Feature} from "app/lib/api";
import {LocalData} from "app/data/source/local/local-data";
import {Injectable} from "@angular/core";
import {FeatureCloud} from "app/data/source/cloud/feature-cloud.service";
import "rxjs-compat/add/operator/first";

@Injectable()
export class FeatureRepository {
  static FEATURES = "features";

  constructor(
    private featureCloud: FeatureCloud,
    private localData: LocalData
  ) {

  }

  listAll(limit, cursor, cache = true) {
    return new Observable<Feature[]>(subscribe => {
        if (cursor == null && cache) {
          let categories = this.localData.load(FeatureRepository.FEATURES);

          if (categories != undefined) {
            subscribe.next(categories);
            return
          }
        }

        this.featureCloud.listAll(limit, cursor)
          .do(x => this.localData.save(FeatureRepository.FEATURES, x.items))
          .subscribe(
            value => subscribe.next(value.items),
            error => subscribe.error(error)
          )
      }
    )
  }

  save(feature) {
    return this.featureCloud.save(feature)
  }

  load(feature) {
    return this.listAll(100, null)
      .flatMap(value => {
        return value.filter(value1 => value1.id == feature);
      })
  }
}
