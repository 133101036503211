import {Injectable} from "@angular/core";
import {ContractCloud} from "app/data/source/cloud/contract-cloud";
import {Observable} from "rxjs";
import {Contract} from "app/lib/api";
import {LocalData} from "app/data/source/local/local-data";

@Injectable()
export class ContractRepository {
  static CONTRACT = "contracts";

  constructor(private localData: LocalData, private contractCloud: ContractCloud) {
  }

  get(contractId) {
    return this.contractCloud.get(contractId)
  }

  listAll(limit, cursor, cache = true): Observable<Contract[]> {
    return new Observable<Contract[]>(subscribe => {
      if (cursor == null && cache) {
        let categories = this.localData.load(ContractRepository.CONTRACT);

        if (categories != undefined) {
          subscribe.next(categories || []);
          subscribe.complete();
          return
        }
      }

      this.contractCloud.listAll(limit, cursor)
        .do(x => this.localData.save(ContractRepository.CONTRACT, x.items || []))
        .subscribe(value => {
          subscribe.next(value.items);
          subscribe.complete();
        }, error => subscribe.error(error))
    })
  }

  review(loginId, hostId, plainId, contractDate, dueDate, nextDueDate, discountExpiredAt, discountAmount) {
    return this.contractCloud.review(loginId,
      hostId,
      plainId,
      contractDate,
      dueDate,
      nextDueDate,
      discountExpiredAt,
      discountAmount)
  }

  register(loginId,
           hostId,
           plainId,
           contractDate,
           dueDate,
           nextDueDate,
           m2mId,
           deviceId,
           discountExpiredAt,
           discountAmount,
           discountDescription) {
    return this.contractCloud.register(loginId,
      hostId,
      plainId,
      contractDate,
      dueDate,
      nextDueDate,
      m2mId,
      deviceId,
      discountExpiredAt,
      discountAmount,
      discountDescription)
  }

  findProductId(id: number) {
    return this.listAll(500, null)
      .flatMap(value => value)
      .filter(value => value.productId == id)
  }
}
