import {Component, OnInit} from '@angular/core';
import {Contract} from "app/lib/api";
import {ActivatedRoute} from "@angular/router";
import {ContractRepository} from "app/data/source/repository/contract-repository";

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.scss']
})
export class ContractDetailComponent implements OnInit {

  contract: Contract = {};

  constructor(private route: ActivatedRoute,
              private contractRepository: ContractRepository) {
  }

  ngOnInit() {
    this.loadContract();
  }

  loadContract() {
    this.route.params
      .map(params => params["id"])
      .flatMap(id => this.contractRepository.get(id))
      .subscribe(contract =>
        this.contract = contract
      )
  }
}
