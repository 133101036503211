import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {Observable} from "rxjs";

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: "./yes-no-dialog.component.html",
  styleUrls: ['./yes-no-dialog.component.css']
})
export class YesNoDialogComponent implements OnInit {

  constructor(private  dialogRef: MatDialogRef<YesNoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: YesNoData) {
  }

  static create(dialog: MatDialog, title: string, message: string): Observable<boolean> {
    return dialog.open(YesNoDialogComponent, {
      data: new YesNoData(title, message), hasBackdrop: true
    }).afterClosed();
  }

  ngOnInit() {
  }

  yes() {
    this.dialogRef.close(true);
  }

  no() {
    this.dialogRef.close(false);
  }
}

export class YesNoData {
  title: string;
  message: string;
  yes: string = "SIM";
  no: string = "CANCELAR";

  constructor(title: string, message: string) {
    this.title = title;
    this.message = message;
  }
}
