import {Component, OnInit} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";

@Component({
  selector: 'app-graphics',
  templateUrl: './graphics.component.html',
  styleUrls: ['./graphics.component.css']
})
export class GraphicsComponent implements OnInit {

  constructor(private appService: AppService) {
    appService.setNavTitle("Gráficos");
  }

  ngOnInit() {
  }

}
