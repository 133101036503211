import {Component} from '@angular/core';
import {environment} from "environments/environment";
import {AppService} from "app/infrastructure/services/app.service";
import {Router} from "@angular/router";
import {Angulartics2GoogleAnalytics} from "angulartics2/ga";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: []
})

export class AppComponent {
  title = 'app';

  constructor(
    private appService: AppService,
    private router: Router,
    analytics: Angulartics2GoogleAnalytics,
  ) {
    console.log('App Init');
    this.init();
    analytics.startTracking();
  }

  init(): void {
    this.title = environment.production == true ? "Apureh" : "Dev";
    AppService.setTitle(this.title);
  }
}
