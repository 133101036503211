import {DefaultService} from "app/lib/api";
import {Injectable} from "@angular/core";

@Injectable()
export class HostCloud {
  constructor(private apiClient: DefaultService) {

  }

  get(categoryId) {
    return this.apiClient.hostEndpointV1Get(categoryId)
  }

  list(limit, cursor) {
    return this.apiClient.hostEndpointV1List(limit, cursor)
  }

  createDevice(deviceId) {
    return this.apiClient.hostEndpointV1Device(deviceId)
  }
}
