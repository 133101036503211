import {Component, OnInit} from '@angular/core';
import {AppService} from "app/infrastructure/services/app.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LogService} from "app/infrastructure/services/log.service";
import {AccountRepository} from "app/data/source/repository/account-repository";
import {DeviceRepository} from "app/data/source/repository/device-repository";
import {AppRoute} from "app/infrastructure/services/app_route";
import {TokenSaveInteractor} from "app/domain/interactor/TokenSaveInteractor";

@Component({
  selector: 'app-setup', templateUrl: './setup.component.html', styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {
  step = 2;
  lottieConfig: Object;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appRoute: AppRoute,
    private router: Router,
    private appService: AppService,
    private tokenSaveInteractor: TokenSaveInteractor,
    private deviceRepository: DeviceRepository,
    private accountRepository: AccountRepository) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(value => {
      let token: String = value["token"];
      let toolbar = value["toolbar"];
      let redirect = value["redirect"];

      if (token) {
        token = token.split(" ").join("+");
        this.tokenSaveInteractor.execute(token);
      }

      if (toolbar) {
        this.appService.toolbar = toolbar == "true";
      }

      if (redirect) {
        this.appRoute.redirect = redirect;
      }

      this.accountRepository.getUser()
        .subscribe(
          value => this.checkStep(),
          error => {
            this.appService.logout(true);
          }
        );

      this.deviceRepository.list(false)
        .subscribe(
          value => {
            if (value.length == 0) {
              this.router.navigateByUrl("/app/device/form")
            } else {
              this.checkStep();
            }
          },
          reason => {
            this.appService.logout(true);
          });
    });

    this.lottieConfig = {
      path: "assets/pulse_loader.json"
    }
  }

  private checkStep() {
    this.step--;

    if (this.step === 0) {
      this.appRoute.routeRedirect("/app");
    }
  }
}
